import React, { useContext, useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { CandidatePageContext } from "../../../../lib/context";
import { ScaleLoader } from "react-spinners";
import InitialsLogo from "../../../all/InitialsLogo";
import {
  GoogleMeet,
  ZoomIcon,
  calendar,
  Outlook,
  TeamsLogo,
} from "../icons/Icons";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import "moment-timezone";
import InterviewScheduledCard from "./InterviewScheduledCard";
import SvgIcon2 from "../icons/SvgIcon2";
import TextAreaBox from "../../../../layouts/TextAreaBox";
const core = require("../../../../lib/core");
import DatePicker from "react-datepicker";
import SelectBox from "../../../../layouts/SelectBox";
import "react-datepicker/dist/react-datepicker.css";
import GoogleCalenderView from "./GoogleCalenderView";
import InviteModal from "../../job/assessment/InviteModal";
import AvailabilityModal from "../../job/assessment/AvailabilityModal";
import { data } from "jquery";
import { convertToHTML } from "draft-convert";
import InputBox from "../../../../layouts/InputBox";
import TimezoneSelect from "./TimezoneSelect";
import CustomSelect from "../../../../layouts/CustomSelect";
const now = moment();
const minutes = now.minutes();
const hour = now.hour();
let nearestTimeSlot, endTimeSlot;
if (hour === 23 && minutes >= 30) {
  nearestTimeSlot = "11:30 PM";
  endTimeSlot = "12:00 AM";
} else {
  const nearestMinutes = minutes < 30 ? 30 : 0;
  const nearestHour = minutes < 30 ? hour : hour + 1;
  const formattedHour = nearestHour.toString().padStart(2, "0");
  const formattedMinute = nearestMinutes.toString().padStart(2, "0");
  const ampm = nearestHour < 12 ? "AM" : "PM";
  const formattedHour12 = nearestHour % 12 || 12;
  nearestTimeSlot = `${formattedHour12}:${formattedMinute} ${ampm}`;
  const endTime = moment(nearestTimeSlot, "hh:mm A").add(30, "minutes");
  const formattedEndHour = endTime.format("hh");
  const formattedEndMinute = endTime.format("mm");
  const endAmpm = endTime.format("A");
  endTimeSlot = `${formattedEndHour}:${formattedEndMinute} ${endAmpm}`;
}
const scheduleData = {
  title: "",
  date: new Date(),
  link: "",
  candidate: "",
  recruiter: "",
  nameRecruiter: "",
  time: nearestTimeSlot,
  endtime: endTimeSlot,
  description: "",
  interviewStage: "",
};
const ToggleButton = ({ options, value, onChange }) => {
  return (
    <div className="flex border border-gray-400 w-fit rounded-lg shadow-[0px_2px_0px_rgba(0,0,0,0.04)]">
      {options.map((option, index) => (
        <div
          key={index}
          onClick={() => onChange(option.value)}
          className={`px-2 py-1 text-sm rounded-md ${
            value === option.value
              ? "bg-gray-300 text-gray-900"
              : "text-gray-800"
          } ${index === 0 ? "" : ""} ${index === options.length - 1 ? "" : ""}`}
        >
          {option.label}
        </div>
      ))}
    </div>
  );
};

const Combobox = ({ frameworks, selectInterview, setSelectInterview }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(frameworks);
  const wrapperRef = useRef(null);

  const handleInputChange = (e) => {
    setSelectInterview(e.target.value);
    setIsOpen(true);
  };

  const handleOptionClick = (option) => {
    setSelectedValue(option);
    setSelectInterview(option);
    setIsOpen(false);
  };
  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative " ref={wrapperRef}>
      <InputBox
        type="text"
        value={selectInterview}
        onChange={handleInputChange}
        onClick={() => setIsOpen(true)}
        placeholder="Select interview stage"
      />
      <div
        className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
        onClick={() => setIsOpen(!isOpen)}
      >
        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </div>
      {isOpen && (
        <ul className="absolute z-10 w-full mt-1 bg-white border rounded-md shadow-lg max-h-60 overflow-auto">
          {frameworks
            ?.filter((item) => item != "Hired" && item != "Rejected")
            ?.map((option) => (
              <li
                key={option}
                className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                onClick={() => handleOptionClick(option)}
              >
                {option}
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};
const ErrorMessage = ({ failedInterviewers, Text, isExternalError }) => {
  if (failedInterviewers.length === 0) return null;

  const uniqueFailedInterviewers = [...new Set(failedInterviewers)];
  const splitInterviewers = uniqueFailedInterviewers.map(
    (email) => email?.split("@")[0],
  );

  const icon = isExternalError ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="text-red-500 font-semibold mr-3"
      width="20"
      height="20"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2M12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8s8 3.58 8 8s-3.58 8-8 8"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="text-orange-500 font-semibold mr-3"
      width="15"
      height="15"
      viewBox="0 0 16 16"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m7.493.015l-.386.04c-1.873.187-3.76 1.153-5.036 2.579C.66 4.211-.057 6.168.009 8.253c.115 3.601 2.59 6.65 6.101 7.518a8.03 8.03 0 0 0 6.117-.98a8 8 0 0 0 3.544-4.904c.172-.701.212-1.058.212-1.887s-.04-1.186-.212-1.887C14.979 2.878 12.315.498 9 .064C8.716.027 7.683-.006 7.493.015m1.36 1.548a6.3 6.3 0 0 1 1.987.597c.698.34 1.18.686 1.747 1.253A6 6 0 0 1 13.84 5.16c.445.915.646 1.798.646 2.84a6.2 6.2 0 0 1-.66 2.867c-.172.351-.519.914-.681 1.105l-.055.065l-4.563-4.564L3.963 2.91l.065-.055c.191-.162.754-.509 1.105-.681a6.44 6.44 0 0 1 3.72-.611M7.48 8.534l4.56 4.561l-.067.053a7.7 7.7 0 0 1-1.106.68a6.8 6.8 0 0 1-1.987.616c-.424.065-1.336.065-1.76 0c-1.948-.296-3.592-1.359-4.627-2.993a7.5 7.5 0 0 1-.634-1.332a6.6 6.6 0 0 1-.189-3.584a6.8 6.8 0 0 1 1.096-2.388c.07-.095.133-.173.141-.173s2.065 2.052 4.573 4.56"
      />
    </svg>
  );

  return (
    <div
      data-tooltip-id="tooltip"
      data-tooltip-content={Text}
      className={`flex items-center ${isExternalError ? "bg-red-50" : "bg-orange-50"} border-l-4 ${isExternalError ? "border-red-500" : "border-orange-500"}  py-2  px-4 rounded-md my-2 w-auto h-auto shadow-md`}
    >
      <div>{icon}</div>
      <span className="block sm:inline font-medium">
        <span className="font-normal">{splitInterviewers.join(", ")}</span>
      </span>
    </div>
  );
};
const customCalendarStyles = `
  .react-calendar {
    width: 100%;
    max-width: none;
    background: white;
    border: none;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    border-radius: 0.5rem;
    padding: 1rem;
  }
  .react-calendar__navigation {
    height: 44px;
    margin-bottom: 1em;
  }
  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
    font-size: 1rem;
    font-weight: bold;
    color: #4A5568;
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #F7FAFC;
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
    color: #4A5568;
  }
  .react-calendar__month-view__days__day--weekend {
    color: #E53E3E;
  }
  .react-calendar__tile {
    max-width: 100%;
    padding: 0.75em 0.5em;
    background: none;
    text-align: center;
    line-height: 16px;
    font-size: 0.875rem;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #EDF2F7;
    border-radius: 0.25rem;
  }
  .react-calendar__tile--now {
    background: #E6FFFA;
    border-radius: 0.25rem;
    font-weight: bold;
    color: #319795;
  }
  .react-calendar__tile--active {
    background: #4299E1;
    border-radius: 0.25rem;
    font-weight: bold;
    color: white;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #3182CE;
  }
`;

const colorArray = [
  "#61A3BA",
  "#A9A9A9",
  "#0C356A",
  "#04364A",
  "#99A98F",
  "#245953",
  "#8785A2",
  "#8D7B68",
];
const SchedulingInterview = () => {
  const [availabilities, setAvailabilities] = useState([]);
  const [Candavailabilities, setCandAvailabilities] = useState([]);
  const [isLoading, setIsloading] = useState(true);
  const [isMeetLinkLoading, setisMeetLoading] = useState(false);
  const [formdetails, setFormdetails] = useState(scheduleData);
  const candidate = useSelector((state) => state?.jobCandidateMatch?.profile);
  const data = useSelector((state) => console.log(state));
  const [message, setMessage] = useState({});
  const [checked, setChecked] = useState(false);
  const [isZoomLinkLoading, setIsZoomloading] = useState(false);
  const applicant = useSelector((state) => state?.jobCandidateMatch?.profile);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [inputValue, setInputValue] = useState("");
  const [selectedemailOptions, setSelectedOptions] = useState([]);
  const [recommendedEmails, setRecommendedEmails] = useState([]);
  const [timeZone, settimeZone] = useState("");
  const [eventsForSelectedDay, setEventsForSelectedDay] = useState([]);
  const [warningMessage, setWarningMessage] = useState("");
  const [InterviewertimeZone, setInterviewerTimeZone] = useState(
    moment.tz.guess(),
  );
  const [candidateMessage, setCandidateMessage] = useState("");
  const [candidateStatus, setCandidateStatus] = useState("Invite not sent");
  const [EventsLoading, setEventsLoading] = useState(false);
  const [isOutLookLoading, setIsOutlookLoading] = useState(false);
  const [teamsMeetId, setteamsMeetId] = useState("");
  const [failedFetchInterviewers, setFailedFetchInterviewers] = useState([]);
  const [service, setService] = useState("");
  const [interviewerMessage, setInterviewerMessage] = useState("");
  const [ZeroEventsInterviewers, setZeroEventsInterviewers] = useState([]);
  const { user } = useContext(core.UserContext);
  const [meetingLinkError, setmeetingLinkError] = useState("");
  const [authmessage, setauthmessage] = useState("");
  const [formValidMsg, setformValidmsg] = useState("");
  // console.log(user);
  const [showInvite, setShowInvite] = useState("");
  const [showSteps, setShowSteps] = useState(false);
  const [CalendarStatus, setCalendarStatus] = useState("");
  const instructionsRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [MeetPlatformStatus, setMeetPlatformStatus] = useState({
    google: false,
    Microsoft: false,
    Zoom: false,
  });
  const [InterviewerEmails, setInterviewerEmails] = useState([]);
  const { jobId, jobDetails, jobPosition, OrgName } = useSelector((state) => {
    return {
      jobDetails: state?.JobExpandSlice?.assessment,
      jobId: state?.JobExpandSlice?.profile?.id,
      jobPosition: state?.JobExpandSlice?.profile?.position,
      OrgName: state?.JobExpandSlice?.profile?.company?.name,
    };
  });
  const pipeline = useSelector(
    (state) => state?.JobExpandSlice?.profile?.pipeline,
  );

  const [pipelineData, setPipelineData] = useState(() =>
    pipeline?.flatMap((stage) =>
      stage?.subStage?.length > 0 ? stage.subStage : [stage.stage],
    ),
  );
  const Organization = OrgName
    ? OrgName
    : user?.organization?.name
      ? user.organization.name
      : "Org";
  const InviteTemplate = {
    subject: `Interview Availability for ${jobPosition} at ${Organization}`,
    body: `Dear ${applicant?.matchProfile?.first_name} ,
I hope this email finds you well.

We were impressed by your application for the ${jobPosition}  position at ${Organization} and would like to schedule an interview with you. Could you please provide your availability over the next few days so we can arrange a convenient time for the interview?

{{availability_link}}

We look forward to discussing your qualifications and the opportunity further.

Thank you, and we hope to hear from you soon.


Best regards,
${Organization}
    `,
  };
  const initialTemplate = `Dear {{first_name}},
I hope this email finds you well.

We were impressed by your application for the {{job_name}}  position at {{org_name}} and would like to schedule an interview with you. Could you please provide your availability over the next few days so we can arrange a convenient time for the interview?

{{availability_link}}

We look forward to discussing your qualifications and the opportunity further.

Thank you, and we hope to hear from you soon.

Best regards,
{{org_name}}`;
  const convertTemplateToHTML = (template) => {
    let html = template.replace(/\n/g, "<br>");
    html = `<p>${html}</p>`;

    return html;
  };
  const initialSubject =
    "Interview Availability for {{job_name}} at {{org_name}}";
  const [inviteloading, setInviteLoading] = useState(false);
  const AvailabilityInvite = async () => {
    const bodyContent = convertTemplateToHTML(initialTemplate);
    const requestData = {
      subject: initialSubject,
      body: bodyContent,
      matchProfiles: [applicant?.matchProfile?._id],
      job: jobId,
      org_name: Organization,
      job_name: jobPosition,
    };

    setInviteLoading(true);
    try {
      const response = await core.API(
        core.API_METHODS.POST,
        core.SCHEDULING_API.POST_AVAILABILITY_INVITE,
        1,
        requestData,
      );

      if (response.code == 201) {
        setInviteLoading(false);
        setCandidateStatus("Pending");
        toast.success("Invite Sent Successfully");
      } else {
        toast.error("Error Sending Invite");
        setInviteLoading(false);
      }
    } catch (error) {
      setInviteLoading(false);
      toast.error("Something went wrong!");
    }
  };
  const tooltipRef = useRef(null);

  useEffect(() => {
    function handleClickOutsideInvite(event) {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setShowTooltip(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutsideInvite);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideInvite);
    };
  }, [tooltipRef]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        instructionsRef.current &&
        !instructionsRef.current.contains(event.target)
      ) {
        setShowSteps(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    get_user_list();
  }, []);
  const get_user_list = async () => {
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.USER_API.GET_USER_LIST_ORG,
        1,
      );
      if (json?.data) {
        const extractedEmails = json?.data.map((item) => item.email);
        setInterviewerEmails(extractedEmails);
      }
    } catch (error) {}
  };
  const isFormValid = () => {
    // console.log(selectedemailOptions);
    // console.log(formdetails.date.length);
    return (
      formdetails.link.length > 0 &&
      formdetails.candidate.length > 0 &&
      formdetails.title.length > 0 &&
      formdetails.date &&
      formdetails.time.length > 0 &&
      formdetails.endtime.length > 0 &&
      selectedemailOptions.length > 0 &&
      formdetails.interviewStage.length > 0
    );
  };
  const handleCheckClick = () => {
    if (!isFormValid()) {
      setChecked(false);
      setformValidmsg("Please fill the required fields");
    } else {
      setChecked(true);
      setformValidmsg("");
    }
  };
  useEffect(() => {
    // console.log(isFormValid());
    if (!isFormValid()) {
      setChecked(false);
    } else {
      setformValidmsg("");
    }
  }, [formdetails, selectedemailOptions]);
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    // const emailList = [
    //   "Sourav@sproutsai.com",
    //   "Niraj@sproutsai.com",
    //   "Rohit@sproutsai.com",
    // ];
    if (e.target.value.length > 0) {
      const filteredEmails = InterviewerEmails.filter((email) =>
        email.toLowerCase().includes(e.target.value.toLowerCase()),
      );
      if (filteredEmails.length > 0) setRecommendedEmails(filteredEmails);
      else {
        setRecommendedEmails([`${e.target.value}`]);
      }
    } else {
      setRecommendedEmails([]);
      setWarningMessage("");
    }
  };

  const handleOptionClick = (option) => {
    if (option.includes("@") && option.includes(".")) {
      if (option === formdetails.candidate) {
        setWarningMessage("Candidate and interviewer cannot be the same.");
        return;
      }
    } else {
      setWarningMessage("Please enter a valid email");
      setTimeout(() => setWarningMessage(""), 2000);
      return;
    }
    if (!selectedemailOptions.includes(option)) {
      setSelectedOptions([...selectedemailOptions, option]);
      setInputValue("");
      setRecommendedEmails([]);
      setWarningMessage("");
    } else {
      setWarningMessage("Email already added");
      setTimeout(() => setWarningMessage(""), 2000);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (inputValue.includes("@") && inputValue.includes(".")) {
        handleOptionClick(inputValue);
      } else {
        setWarningMessage("Please enter a valid email");
        setTimeout(() => setWarningMessage(""), 2000);
      }
    }
  };
  const handleRemoveOption = (index) => {
    const updatedOptions = [...selectedemailOptions];
    updatedOptions.splice(index, 1);
    setSelectedOptions(updatedOptions);
    if (updatedOptions.length === 0) {
      setFailedFetchInterviewers([]);
      setZeroEventsInterviewers([]);
      setEventsForSelectedDay([]);
      setauthmessage("");
    }
    setChecked((prev) => (isFormValid() ? prev : false));
  };

  useEffect(() => {
    fetchAvailability();
  }, [InterviewertimeZone]);
  const fetchAvailability = async () => {
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.SCHEDULING_API.GET_CAN_AVAILABILITY + applicant?.matchProfile?._id,
        1,
      );
      if (json?.data) {
        // console.log("json data", json.data);
        settimeZone(json?.data?.timezone);
        // console.log(timeZone);

        const avail = json?.data.availabilityData;

        setCandAvailabilities(avail);
        if (avail.length === 0) {
          setCandidateMessage("Availability expired invite again");
        }
        const formattedData = await avail?.flatMap((item) => {
          return item.slots.map((slot) => {
            return {
              title: "Candidate Availability",
              start: new Date(
                moment
                  .utc(slot.start_time)
                  .tz(InterviewertimeZone)
                  .format("M/D/YYYY, h:mm:ss A"),
              ),
              end: new Date(
                moment
                  .utc(slot.end_time)
                  .tz(InterviewertimeZone)
                  .format("M/D/YYYY, h:mm:ss A"),
              ),
              colorEvento: "orange",
              color: "white",
              interviewer: json?.data?.email,
              originalTimezone: json?.data?.timezone,
            };
          });
        });

        // setEventsForSelectedDay([...formattedData]);
        // console.log("Formatted Avail", formattedData)
        setAvailabilities(formattedData);
        setIsloading(false);
      } else {
        setIsloading(false);
        setCandidateMessage("No availabilities found; send an invite");
        console.error("Error fetching data:", json?.message);
      }
    } catch (error) {
      console.error(error);
      setIsloading(false);
    }
  };

  const handleGetOutlookLink = async () => {
    setIsOutlookLoading(true);
    try {
      const response = await core.API(
        core.API_METHODS.GET,
        core.SCHEDULING_API.GET_TEAMS_LINK + user.email,
        1,
      );
      // console.log("TEAMS", response);
      if (response?.data) {
        setInput("link", response?.data?.link?.join_link);
        setteamsMeetId(response?.data?.link?.meet_id);
      } else {
        setmeetingLinkError(response?.message);
        setTimeout(() => setmeetingLinkError(""), 2000);
        console.log("error fetching outlook meet link", response);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsOutlookLoading(false);
    }
  };
  const getOutlookCalenderStatus = async () => {
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.SCHEDULING_API.GET_OUTLOOK_AUTH_STATUS +
          `${user.email}&service=microsoft`,
        1,
        {},
      );
      if (json?.data) {
        if (json.data.status) {
          setService("Microsoft");
          setCalendarStatus("Microsoft");
          setMeetPlatformStatus((prev) => ({ ...prev, ["Microsoft"]: true }));
        }
        return json.data.status;
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const getGooglecalenderStatus = async () => {
    try {
      const response = await core.API(
        core.API_METHODS.GET,
        core.SCHEDULING_API.GET_MEET_AUTH_STATUS +
          `${user.email}&service=google-meet`,
        1,
        {},
      );
      if (response?.data) {
        if (response.data.status) {
          setService("google");
          setMeetPlatformStatus((prev) => ({ ...prev, ["google"]: true }));
        }
        return response.data.status;
      }
    } catch (error) {
      console.log("error", error);
      return false;
    }
  };
  const getCalStatus = async () => {
    try {
      const response = await core.API(
        core.API_METHODS.GET,
        core.SCHEDULING_API.GET_MEET_AUTH_STATUS +
          `${user.email}&service=google-calendar`,
        1,
        {},
      );
      if (response?.data) {
        if (response.data.status) {
          setCalendarStatus("google");
        }
        return response.data.status;
      }
    } catch (error) {
      console.log("error", error);
      return false;
    }
  };

  const getStatus = async (service) => {
    try {
      const response = await core.API(
        core.API_METHODS.GET,
        core.SCHEDULING_API.GET_MEET_AUTH_STATUS + `${user.email}&service=zoom`,
        1,
        {},
      );
      if (response?.data) {
        if (response.data.status) {
          setService("zoom");
          setMeetPlatformStatus((prev) => ({ ...prev, ["Zoom"]: true }));
        }
        return response.data.status;
      }
    } catch (error) {
      console.log("error", error);
      return false;
    }
  };
  useEffect(() => {
    getOutlookCalenderStatus();
    getGooglecalenderStatus();
    getStatus();
    getCalStatus();
  }, []);
  const handleGetMeetLink = async () => {
    setisMeetLoading(true);
    const res = await getGooglecalenderStatus();
    if (!res) {
      setmeetingLinkError("Authorize google calender and google meet");
      setTimeout(() => setmeetingLinkError(""), 2000);
      setisMeetLoading(false);
      return;
    }
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.SCHEDULING_API.GET_MEET_LINK +
          // user?.email,
          user.email,
        1,
      );
      if (json?.data) {
        setInput("link", json?.data?.link);
        setisMeetLoading(false);
        setteamsMeetId("");
      } else {
        setmeetingLinkError(json?.message);
        setTimeout(() => setmeetingLinkError(""), 2000);
        setisMeetLoading(false);
        console.error("Error fetching data:", json?.message);
      }
    } catch (error) {
      console.error(error);
      setisMeetLoading(false);
    }
  };

  const handleGetZoomLink = async () => {
    setIsZoomloading(true);
    try {
      const response = await core.API(
        core.API_METHODS.GET,
        core.SCHEDULING_API.GET_ZOOM_LINK + user.email,
        1,
      );

      if (response?.data) {
        setInput("link", response.data.link);
        setIsZoomloading(false);
      } else if (response?.code == 401) {
        setmeetingLinkError(response?.message);
        setTimeout(() => setmeetingLinkError(""), 2000);
        setIsZoomloading(false);
        console.log("error fetching the Zoom Link");
      }
    } catch (error) {
      setIsZoomloading(false);
      setmeetingLinkError("Internal error");
      setTimeout(() => setmeetingLinkError(""), 2000);
      console.log("Error", error);
    }
  };
  useEffect(() => {
    setFailedFetchInterviewers([]);
    setZeroEventsInterviewers([]);
  }, [selectedemailOptions]);
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop().split(";").shift();
    }
    return null;
  }
  const getRandomColor = (event) => {
    if (!formdetails.date || !formdetails.time || !formdetails.endtime) {
      return "#4CAF50";
    }

    const formStartTime = moment(
      `${formdetails.date} ${formdetails.time}`,
      "YYYY-MM-DD hh:mm A",
    );
    const formEndTime = moment(
      `${formdetails.date} ${formdetails.endtime}`,
      "YYYY-MM-DD hh:mm A",
    );
    const eventStartTime = moment(event.startTime);
    const eventEndTime = moment(event.endTime);

    if (
      isTimeConflict(formStartTime, formEndTime, eventStartTime, eventEndTime)
    ) {
      return "#F44336";
    } else {
      return "#4CAF50";
    }
  };
  const isTimeConflict = (start1, end1, start2, end2) => {
    return start1 < end2 && end1 > start2;
  };

  const [loading, setLoading] = useState(false);
  // const jobId = useSelector((state) => state?.JobExpandSlice?.profile?.id);
  const { setShowModal, setReload } = useContext(CandidatePageContext);
  const [showScheduleForm, setShowScheduleForm] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const bodyData = {
      matchProfile: candidate?.matchProfile?._id,
      title: formdetails.title,
      meeting_link: formdetails.link,
      start_time: moment
        .tz(
          `${moment(formdetails.date).format("YYYY-MM-DD")} ${formdetails.time}`,
          "YYYY-MM-DD h:mm A",
          InterviewertimeZone,
        )
        .utc()
        .toDate(),
      end_time: moment
        .tz(
          `${moment(formdetails.date).format("YYYY-MM-DD")} ${formdetails.endtime}`,
          "YYYY-MM-DD h:mm A",
          InterviewertimeZone,
        )
        .utc()
        .toDate(),
      // interviewer: ["65ce07f3edc4533e64f7ddee"],
      guest: selectedemailOptions,
      job: jobId,
      description: formdetails?.description,
      service:
        service === "Microsoft"
          ? "teams"
          : service == "google"
            ? "google-calendar"
            : service == "zoom"
              ? "google-calendar"
              : "",
      candidate_email: formdetails?.candidate,
      timezone: InterviewertimeZone,
      candidate_timezone: timeZone,
      stage: formdetails?.interviewStage,
    };
    if (teamsMeetId.length > 0) {
      bodyData.teams_id = teamsMeetId;
    }

    const accessToken = getCookie("access_token");
    // console.log("Here In SUbmit", accessToken);
    try {
      const json = await core.API(
        core.API_METHODS.POST,
        core.SCHEDULING_API.POST_SCHEDULE_INTERVIEW,
        1,
        bodyData,
      );
      if (json?.code == 201) {
        setFormdetails(scheduleData);
        setSelectedOptions([]);

        toast.success("Meeting scheduled successfully");
        localStorage.removeItem(
          `meetingFormData+${applicant?.matchProfile?._id}`,
        );
        getInterviewData();
        if (edit)
          setMessage({
            type: "success",
            message: "Meeting re-scheduled successfully",
          });
        else
          setMessage({
            type: "success",
            message: "Meeting scheduled successfully",
          });
        setReload((prev) => prev + 1);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("An Error occurred");
        setMessage({
          type: "error",
          message: json?.error?.message,
        });
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      setMessage({
        type: "error",
        message:
          "An error occurred while scheduling the interview. Please try again.",
      });
    } finally {
      setChecked(false);
    }
  };

  const setInput = async (key, value) => {
    if (key === "date") {
      setFormdetails((prevState) => ({ ...prevState, [key]: value }));
      setSelectedDate(value);
      saveFormToLocalStorage(formdetails);
      // console.log("Date Changes", value);
    } else if (key === "time") {
      setFormdetails((prevState) => {
        const newState = { ...prevState, [key]: value };
        if (value >= prevState.endtime) {
          newState.endtime = "";
        }
        return newState;
      });
      saveFormToLocalStorage(formdetails);
    } else {
      setFormdetails((prevState) => {
        const newState = { ...prevState, [key]: value };
        saveFormToLocalStorage(newState);
        return newState;
      });
    }
  };

  // console.log(applicant);
  useEffect(() => {
    // setInput("date", new Date());
    setSelectedDate(new Date());
  }, []);

  useEffect(() => {
    setZeroEventsInterviewers([]);
    setFailedFetchInterviewers([]);
  }, [selectedDate]);
  useEffect(() => {
    setInput("candidate", applicant?.matchProfile?.email[0]);
    setCandidateStatus(applicant?.matchProfile?.availablity_status);
  }, [applicant]);

  useEffect(() => {
    if (selectedDate && selectedemailOptions.length > 0) {
      const fetchData = async () => {
        const availability = await fetchInterviewerAvailability(selectedDate);
        setEventsForSelectedDay(availability);
      };
      fetchData();
      if (selectedemailOptions.length === 0) {
        setFailedFetchInterviewers([]);
        setauthmessage("");
      }
    }
  }, [selectedDate, selectedemailOptions, InterviewertimeZone]);

  function stringToColor(string) {
    if (!colorArray || colorArray.length === 0) {
      return null; // Handle the case when the colorArray is empty or undefined
    }

    let hash = 0;
    for (let i = 0; i < string?.length; i += 1) {
      hash = string?.charCodeAt(i) + ((hash << 5) - hash);
    }

    const index = Math.abs(hash) % colorArray.length; // Calculate the index based on the hash
    return colorArray[index];
  }
  const handleCancel = () => {
    setFormdetails(scheduleData);
    setInput("candidate", applicant?.matchProfile?.email[0]);
    setSelectedOptions([]);
    setauthmessage("");
    setWarningMessage("");
    setformValidmsg("");
    setmeetingLinkError("");
    setShowScheduleForm(false);
  };
  useEffect(() => {
    console.log("formdetails", formdetails);
  }, [formdetails]);

  const fetchInterviewerAvailability = async (date) => {
    setEventsLoading(true);
    setFailedFetchInterviewers([]);
    setZeroEventsInterviewers([]);
    try {
      const allInterviewerEvents = await Promise.all(
        selectedemailOptions.map(async (interviewerEmail) => {
          try {
            const userDomain = user.email?.split("@")[1];
            const interviewerDomain = interviewerEmail?.split("@")[1];

            if (userDomain !== interviewerDomain) {
              setFailedFetchInterviewers((prev) => [...prev, interviewerEmail]);
              return [];
            }
            const requestBody = {
              coordinatorEmail: user.email,
              interviewerEmail: interviewerEmail,
              timezone: InterviewertimeZone,
              date: date.toISOString().split("T")[0],
              service: service == "Microsoft" ? "Microsoft" : "google",
            };

            const response = await core.API(
              core.API_METHODS.POST,
              core.SCHEDULING_API.GET_CALENDER_EVENTS,
              1,
              requestBody,
            );

            if (response?.data && response?.data.length > 0) {
              setZeroEventsInterviewers((prev) =>
                prev.filter((email) => email !== interviewerEmail),
              );
              setFailedFetchInterviewers((prev) =>
                prev.filter((email) => email !== interviewerEmail),
              );
              return response.data.map((event) => ({
                title: event?.summary,
                start: new Date(event.startTime),
                end: new Date(event.endTime),
                colorEvento: stringToColor(interviewerEmail),
                color: "white",
                interviewer: interviewerEmail,
              }));
            } else if (response?.data.length == 0) {
              setZeroEventsInterviewers((prev) => [...prev, interviewerEmail]);
              return [];
            }

            setFailedFetchInterviewers((prev) => [...prev, interviewerEmail]);
            setauthmessage("");
            return [];
          } catch (error) {
            setauthmessage("Authenticate calendar to fetch events");
            // setTimeout(() => setWarningMessage(""), 2000);
            console.error(
              `Error fetching events for ${interviewerEmail}:`,
              error,
            );
            return [];
          }
        }),
      );

      const Events = allInterviewerEvents.flat();
      setEventsLoading(false);
      return Events;
    } catch (error) {
      console.error("Error in fetchInterviewerAvailability:", error);
      setEventsLoading(false);
      if (error.message.includes("401")) {
        toast.error(`User unauthorized !`);
      } else {
        toast.error(
          `Failed to fetch events for ${interviewerEmail}. Please try again.`,
        );
      }
      return [];
    } finally {
      setEventsLoading(false);
    }
  };
  // const eventsForSelectedDay = selectedemailOptions.flatMap((email) => {
  //   return (interviewerMeetings[email] || []).filter((event) => {
  //     const eventDate = new Date(event.start);
  //     const selectedDateWithoutTime = new Date(
  //       selectedDate.getFullYear(),
  //       selectedDate.getMonth(),
  //       selectedDate.getDate()
  //     );

  //     return (
  //       eventDate.getFullYear() === selectedDateWithoutTime.getFullYear() &&
  //       eventDate.getMonth() === selectedDateWithoutTime.getMonth() &&
  //       eventDate.getDate() === selectedDateWithoutTime.getDate()
  //     );
  //   });
  // });

  // function setMeetingTime(timeSlot) {
  //   console.log(timeSlot);

  //   const startTime = moment(timeSlot.start_time).format("hh:mm A");
  //   const endTime = moment(timeSlot.end_time).format("hh:mm A");
  //   const date = moment(timeSlot.start_time).format("YYYY-MM-DD");

  //   setInput("date", date);
  //   setInput("time", startTime);Fe
  //   setInput("endtime", endTime);
  // }

  const [scheduledTab, setScheduledTab] = useState(<></>);
  const { reload } = useContext(CandidatePageContext);

  const getInterviewData = async () => {
    // let json = await core.API(
    //   core.API_METHODS.POST,
    //   core.RECRUITER_API.GET_INTERVIEWS,
    //   1,
    //   {
    //     match_profile_id: candidate?.matchProfile?._id,
    //   },
    // );

    try {
      const requestBody = {
        match_profile_id: candidate?.matchProfile?._id,
      };
      const json = await core.API(
        core.API_METHODS.POST,
        core.RECRUITER_API.GET_INTERVIEWS,
        1,
        requestBody,
      );

      if (json?.data) {
        if (json?.code === 200 && Array.isArray(json?.data)) {
          setScheduledTab(
            <InterviewScheduledCard
              // dataList={json.data.filter((iter) => iter?.status === "active")}
              dataList={json.data}
            />,
          );
          setLoading(false);
          setShowScheduleForm(false);
        }
      } else {
        console.error("Error fetching interview data:", json.message);
      }
    } catch (error) {
      console.error("Error fetching interview data:", error);
    }
  };

  const [tabName, setTabName] = useState("interview");
  useEffect(() => {
    getInterviewData();
  }, [tabName, reload, candidate?.matchProfile?._id]);

  //time related functions
  const generateTimeOptions = (is24HourFormat) => {
    return Array.from({ length: 96 }, (_, index) => {
      const hour = Math.floor(index / 4);
      const minute = (index % 4) * 15;
      const formattedMinute = minute.toString().padStart(2, "0");

      if (is24HourFormat) {
        // 24-hour format: 00:00 to 23:45
        const formattedHour = hour.toString().padStart(2, "0");
        return {
          value: `${formattedHour}:${formattedMinute}`,
          label: `${formattedHour}:${formattedMinute}`,
        };
      } else {
        // 12-hour format: 12:00 AM to 11:45 PM
        const adjustedHour = hour % 12 || 12;
        const ampm = hour < 12 ? "AM" : "PM";
        return {
          value: `${adjustedHour}:${formattedMinute} ${ampm}`,
          label: `${adjustedHour}:${formattedMinute} ${ampm}`,
        };
      }
    });
  };
  const [is24HourFormat, setIs24HourFormat] = useState(false);
  const [timeOptions, setTimeOptions] = useState(
    generateTimeOptions(is24HourFormat),
  );

  const convertTo24HourFormat = (time12h) => {
    if (!time12h.includes("AM") && !time12h.includes("PM")) {
      return time12h; // Already in 24-hour format
    }

    const [time, modifier] = time12h.split(" ");
    let [hours, minutes] = time.split(":");

    if (hours === "12") {
      hours = "00";
    }

    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }

    return `${String(hours).padStart(2, "0")}:${minutes}`;
  };

  const convertTo12HourFormat = (time24h) => {
    if (time24h.includes("AM") || time24h.includes("PM")) {
      return time24h; // Already in 12-hour format
    }

    let [hours, minutes] = time24h.split(":");
    const suffix = hours >= 12 ? "PM" : "AM";

    hours = hours % 12 || 12; // Convert "00" to "12"
    return `${hours}:${minutes} ${suffix}`;
  };

  // const timeOptions = Array.from({ length: 96 }, (_, index) => {
  //   const hour = Math.floor(index / 4);
  //   const minute = (index % 4) * 15;
  //   const formattedHour = hour.toString().padStart(2, "0");
  //   const formattedMinute = minute.toString().padStart(2, "0");
  //   const ampm = hour < 12 ? "AM" : "PM";
  //   const formattedHour12 = hour % 12 || 12;

  //   const time = `${formattedHour12}:${formattedMinute} ${ampm}`;
  //   return {
  //     value: time,
  //     label: time,
  //   };
  // });
  const getFilteredTimeOptions = (startTime, isStartTimeSelected) => {
    const formatTime = (time) => {
      return is24HourFormat
        ? convertTo24HourFormat(time)
        : convertTo12HourFormat(time);
    };

    const parseTime = (time) => {
      return moment(time, is24HourFormat ? "HH:mm" : "hh:mm A");
    };

    if (!isStartTimeSelected) {
      return timeOptions;
    } else {
      const formattedStartTime = formatTime(startTime);
      const startTimeMoment = parseTime(formattedStartTime);

      return timeOptions.filter((option) => {
        const optionTimeMoment = parseTime(option.value);
        return optionTimeMoment.isAfter(startTimeMoment);
      });
    }
  };

  const handleTimeFormatToggle = (value) => {
    setIs24HourFormat(value);

    const convertedStartTime = formdetails.time
      ? value
        ? convertTo24HourFormat(formdetails.time)
        : convertTo12HourFormat(formdetails.time)
      : "";

    const convertedEndTime = formdetails.endtime
      ? value
        ? convertTo24HourFormat(formdetails.endtime)
        : convertTo12HourFormat(formdetails.endtime)
      : "";

    setInput("time", convertedStartTime);
    setInput("endtime", convertedEndTime);
    setTimeOptions(generateTimeOptions(value));
  };

  const handleTimeChange = (time, isStartTime) => {
    const formattedTime = is24HourFormat
      ? convertTo24HourFormat(time)
      : convertTo12HourFormat(time);

    if (isStartTime) {
      setInput("time", formattedTime);

      if (
        formdetails.endtime &&
        moment(formattedTime, "HH:mm").isAfter(
          moment(formdetails.endtime, "HH:mm"),
        )
      ) {
        setInput("endtime", "");
        // setMessage({
        //   type: "error",
        //   message: "Start time should be earlier than end time.",
        // });
      } else {
        setMessage({});
      }
    } else {
      setInput("endtime", formattedTime);

      if (
        formdetails.time &&
        moment(formattedTime, "HH:mm").isBefore(
          moment(formdetails.time, "HH:mm"),
        )
      ) {
        // setMessage({
        //   type: "error",
        //   message: "End time should be later than start time.",
        // });
        setInput("endtime", "");
      } else {
        setMessage({});
      }
    }
  };
  const eventsByInterviewer = eventsForSelectedDay.reduce((acc, event) => {
    const interviewer = event.interviewer || "Unknown"; // Assume each event has an interviewer field
    if (!acc[interviewer]) {
      acc[interviewer] = [];
    }
    acc[interviewer].push(event);
    return acc;
  }, {});

  const [openDropdown, setOpenDropdown] = useState(null);

  const toggleDropdown = (interviewer) => {
    setOpenDropdown(openDropdown === interviewer ? null : interviewer);
  };

  function convertToTimeZone(date, timeZone) {
    return new Date(
      new Intl.DateTimeFormat("en-US", {
        timeZone,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }).format(date),
    );
  }

  // useEffect(() => {
  //   setAvailabilities(
  //     availabilities.map((item) => {
  //       return {
  //         ...item,
  //         start: convertToTimeZone(item.start, InterviewertimeZone),
  //         end: convertToTimeZone(item.end, InterviewertimeZone),
  //       };
  //     }),
  //   );
  // }, [InterviewertimeZone]);

  // console.log(availabilities, InterviewertimeZone);
  const saveFormToLocalStorage = (formData) => {
    localStorage.setItem(
      `meetingFormData+${applicant?.matchProfile?._id}`,
      JSON.stringify(formData),
    );
  };
  const loadFormFromLocalStorage = () => {
    const savedData = localStorage.getItem(
      `meetingFormData+${applicant?.matchProfile?._id}`,
    );
    if (savedData) {
      return JSON.parse(savedData);
    }
    return null;
  };
  useEffect(() => {
    const savedFormData = loadFormFromLocalStorage();
    if (savedFormData) {
      setFormdetails(savedFormData);
      if (savedFormData.date) {
        setInput("date", new Date(savedFormData.date));
      }
      handleTimeFormatToggle(is24HourFormat);
      setInput("candidate", applicant?.matchProfile?.email[0]);
    } else {
      setFormdetails(scheduleData);
      setInput("candidate", applicant?.matchProfile?.email[0]);
      setInput("date", new Date());
    }
  }, []);

  return (
    <div className="bg-defaultBg p-4 h-full overflow-y-auto">
      {!showScheduleForm && (
        <button
          className="rounded-lg border border-[#dadae4] border-button bg-white h-[40px] text-[#252e4b] text-[16px] px-4  mr-4 flex items-center gap-2"
          onClick={() => setShowScheduleForm(true)}
        >
          {/* <SvgIcon2 className="w-5 h-5 text-[#252e4b] flex" /> */}
          <span>+ Schedule Interview</span>
        </button>
      )}

      {!showScheduleForm && scheduledTab && (
        <>
          <h2 className="text-gray-700 text-sm not-italic font-normal mt-4">
            Scheduled
          </h2>
          <div className="w-full  rounded-lg border-b border-gray-100  mb-4 mt-2">
            {scheduledTab}
          </div>
        </>
      )}
      {showScheduleForm && (
        <div className="flex flex-row gap-4 relative">
          <div className="w-[20%] px-4 py-4 bg-white rounded-lg">
            <h2 className="text-gray-800 text-sm not-italic mb-2 ">
              Candidate availability
            </h2>
            <div className="flex items-center gap-4">
              <span
                className={`text-sm font-medium px-2 py-1 my-2 rounded-full ${
                  candidateStatus === "Completed"
                    ? "bg-green-100 text-green-800"
                    : "bg-yellow-100 text-yellow-800"
                }`}
              >
                {candidateStatus}
              </span>
            </div>
            <div className="flex items-center justify-start mt-2 gap-4">
              <button
                className="flex gap-2 items-center rounded-lg py-1 px-3 border border-[#DADAE4] btn-primary shadow-jobPostButtonShadow"
                onClick={() => {
                  AvailabilityInvite();
                }}
              >
                {inviteloading ? (
                  <div className="flex justify-center items-center">
                    <ScaleLoader height={12} color="#FFF" loading={true} />
                  </div>
                ) : (
                  <>Invite</>
                )}
              </button>
              <div ref={tooltipRef}>
                <button
                  className="w-6 h-6 flex items-center justify-center rounded-full text-gray-200 relative overflow-hidden"
                  onClick={() => setShowTooltip(!showTooltip)}
                >
                  <div
                    className={`transition-transform duration-300 ease-in-out text-gray-600 ${showTooltip ? "translate-y-full" : ""}`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="2em"
                      height="2em"
                      viewBox="0 0 48 48"
                      className="w-6 h-6"
                    >
                      <path
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="4"
                        d="M9.858 18C6.238 21 4 24 4 24s8.954 12 20 12c1.37 0 2.708-.185 4-.508M20.032 12.5c1.282-.318 2.61-.5 3.968-.5c11.046 0 20 12 20 12s-2.239 3-5.858 6m-17.828-9.379a5 5 0 0 0 7.186 6.95M42 42L6 6"
                      />
                    </svg>
                  </div>
                  <div
                    className={`absolute top-0 left-0 text-gray-600 transition-transform duration-300 ease-in-out ${showTooltip ? "" : "-translate-y-full"}`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="2em"
                      height="2em"
                      viewBox="0 0 48 48"
                      className="w-6 h-6"
                    >
                      <g
                        fill="none"
                        stroke="currentColor"
                        strokeLinejoin="round"
                        strokeWidth="4"
                      >
                        <path d="M24 36c11.046 0 20-12 20-12s-8.954-12-20-12S4 24 4 24s8.954 12 20 12Z" />
                        <path d="M24 29a5 5 0 1 0 0-10a5 5 0 0 0 0 10Z" />
                      </g>
                    </svg>
                  </div>
                </button>
                {showTooltip && (
                  <div className="absolute z-10 w-[60%] top-[-20px] left-[200px] p-4 mt-2 bg-white rounded-lg shadow-lg border border-gray-200">
                    <div className="flex items-center justify-between my-2">
                      <p> {InviteTemplate.subject}</p>
                      <button
                        onClick={() => {
                          setShowTooltip(!showTooltip);
                          setShowInvite("InviteCandidate");
                        }}
                        className="px-3 py-1 bg-gray-400 text-gray-800 rounded-lg hover:bg-gray-500 hover:text-gray-900"
                      >
                        Edit
                      </button>
                    </div>
                    <p>
                      <pre className="whitespace-pre-wrap text-sm">
                        {InviteTemplate.body}
                      </pre>
                    </p>
                  </div>
                )}
              </div>
              {showInvite === "InviteCandidate" ? (
                <>
                  <AvailabilityModal
                    modalDismiss={() => setShowInvite("")}
                    matchprofileIds={[applicant?.matchProfile?._id]}
                  />
                </>
              ) : (
                <></>
              )}
            </div>

            {/* <h6 className={timeZone === "" ? "hidden" : " text-sm py-2 "}>
              Candidate Timezone : {timeZone}{" "}
            </h6> */}
            <div className="max-h-[700px] overflow-y-auto">
              {isLoading ? (
                <div className="flex justify-center items-center">
                  <ScaleLoader height={14} color="#1569EB" loading={true} />
                </div>
              ) : (
                <>
                  {Candavailabilities?.length > 0 ? (
                    (() => {
                      const groupedSlots = Candavailabilities?.flatMap(
                        (item) => item.slots,
                      ).reduce((acc, slot) => {
                        const startDate = moment
                          .utc(slot.start_time)
                          .tz(InterviewertimeZone)
                          .format("YYYY-MM-DD");
                        if (!acc[startDate]) acc[startDate] = [];
                        acc[startDate].push(slot);
                        return acc;
                      }, {});

                      const sortedDates = Object.keys(groupedSlots).sort();

                      return sortedDates.map((date, dateIndex) => {
                        const slotsForDate = groupedSlots[date].sort((a, b) =>
                          moment
                            .utc(a.start_time)
                            .tz(InterviewertimeZone)
                            .diff(
                              moment.utc(b.start_time).tz(InterviewertimeZone),
                            ),
                        );

                        return (
                          <div key={dateIndex} className="mb-4">
                            <div className="w-full flex flex-col gap-1 my-1">
                              <div className="w-full">
                                {slotsForDate?.map((timeSlot, slotIndex) => {
                                  const startTime = moment
                                    .utc(timeSlot.start_time)
                                    .tz(InterviewertimeZone);
                                  const endTime = moment
                                    .utc(timeSlot.end_time)
                                    .tz(InterviewertimeZone);
                                  const spansDays = !startTime.isSame(
                                    endTime,
                                    "day",
                                  );

                                  return (
                                    <div
                                      key={slotIndex}
                                      className="w-full bg-defaultBg p-4 rounded-md mt-2 border-l-4 border-green-500"
                                    >
                                      <div className="w-full">
                                        <div className="text-xs sm:text-sm text-gray-700 font-semibold py-2">
                                          {spansDays
                                            ? `${startTime.format("DD-MM-YYYY")} - ${endTime.format("DD-MM-YYYY")}`
                                            : startTime.format("DD-MM-YYYY")}
                                        </div>
                                        <div className="flex flex-col sm:flex-row items-start sm:items-center gap-2">
                                          <div className="flex items-center gap-2">
                                            <span>
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="15"
                                                height="15"
                                                viewBox="0 0 22 22"
                                                fill="none"
                                                className="flex-shrink-0"
                                              >
                                                <path
                                                  d="M11 11H10.25C10.25 11.3228 10.4566 11.6094 10.7628 11.7115L11 11ZM11.75 6C11.75 5.58579 11.4142 5.25 11 5.25C10.5858 5.25 10.25 5.58579 10.25 6H11.75ZM13.7628 12.7115C14.1558 12.8425 14.5805 12.6301 14.7115 12.2372C14.8425 11.8442 14.6301 11.4195 14.2372 11.2885L13.7628 12.7115ZM11.75 11V6H10.25V11H11.75ZM10.7628 11.7115L13.7628 12.7115L14.2372 11.2885L11.2372 10.2885L10.7628 11.7115ZM20.25 11C20.25 16.1086 16.1086 20.25 11 20.25V21.75C16.9371 21.75 21.75 16.9371 21.75 11H20.25ZM11 20.25C5.89137 20.25 1.75 16.1086 1.75 11H0.25C0.25 16.9371 5.06294 21.75 11 21.75V20.25ZM1.75 11C1.75 5.89137 5.89137 1.75 11 1.75V0.25C5.06294 0.25 0.25 5.06294 0.25 11H1.75ZM11 1.75C16.1086 1.75 20.25 5.89137 20.25 11H21.75C21.75 5.06294 16.9371 0.25 11 0.25V1.75Z"
                                                  fill="#252E4B"
                                                />
                                              </svg>
                                            </span>
                                            <span className="text-xs sm:text-sm whitespace-nowrap">
                                              {startTime.format("hh:mm A")}
                                            </span>
                                          </div>
                                          <span className="hidden sm:inline">
                                            to
                                          </span>
                                          <span className="text-xs sm:text-sm whitespace-nowrap">
                                            {endTime.format("hh:mm A")}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        );
                      });
                    })()
                  ) : (
                    <div className="text-gray-800 text-sm text-center my-10 font-normal">
                      {candidateMessage}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="w-[30%] bg-white rounded-lg">
            <form
              onSubmit={handleSubmit}
              className="flex flex-col gap-4 px-2 py-4"
            >
              <div className="w-full">
                <label
                  htmlFor="title"
                  className="w-full text-gray-800 text-sm not-italic font-normal after:content-['*'] after:text-red-900 after:ml-0.5"
                >
                  Title
                </label>
                <input
                  className="w-full border border-gray-400 p-2 rounded-lg text-base shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder-gray-600"
                  type="text"
                  name="title"
                  value={formdetails.title}
                  required="required"
                  placeholder="SproutsAI interview"
                  onChange={(event) => {
                    setInput("title", event.target.value);
                  }}
                />
              </div>
              <div className="w-full relative ">
                <label
                  htmlFor="candidate"
                  className="w-full text-gray-800 text-sm not-italic font-normal after:content-['*'] after:text-red-900 after:ml-0.5"
                >
                  Candidate email
                </label>
                <input
                  className=" w-full placeholder-gray-600  border border-gray-300 p-2 rounded-lg text-base shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder-gray-400 bg-gray-100 text-gray-700 "
                  type="email"
                  required
                  name="candidate"
                  readOnly={true}
                  placeholder="Candidate Email"
                  defaultValue={applicant?.applicant?.email[0] ?? ""}
                  value={formdetails?.candidate}
                  onChange={(event) => {
                    setInput("candidate", event.target.value);
                  }}
                />
                {!formdetails?.candidate && (
                  <span className="absolute top-6 right-0 flex h-3 w-3">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-orange-500 opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-3 w-3 bg-orange-800"></span>
                  </span>
                )}
              </div>
              <div className="w-full">
                <label className="w-full text-gray-800 text-sm not-italic font-normal after:content-['*'] after:text-red-900 after:ml-0.5">
                  Select interview
                </label>
                <Combobox
                  frameworks={pipelineData}
                  selectInterview={formdetails.interviewStage}
                  setSelectInterview={(value) => {
                    setInput("interviewStage", value);
                  }}
                />
              </div>
              <div className="w-full">
                <label
                  htmlFor="candidate"
                  className="w-full text-gray-800 text-sm not-italic font-normal after:content-['*'] after:text-red-900 after:ml-0.5"
                >
                  Interviewer email
                </label>
                {warningMessage && (
                  <div className="flex items-center text-yellow-800 bg-yellow-100 border border-yellow-200 rounded-md px-2 py-1 my-2 gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 256 256"
                    >
                      <g fill="currentColor">
                        <path
                          d="M215.46 216H40.54c-12.62 0-20.54-13.21-14.41-23.91l87.46-151.87c6.3-11 22.52-11 28.82 0l87.46 151.87c6.13 10.7-1.79 23.91-14.41 23.91"
                          opacity="0.2"
                        />
                        <path d="M236.8 188.09L149.35 36.22a24.76 24.76 0 0 0-42.7 0L19.2 188.09a23.51 23.51 0 0 0 0 23.72A24.35 24.35 0 0 0 40.55 224h174.9a24.35 24.35 0 0 0 21.33-12.19a23.51 23.51 0 0 0 .02-23.72m-13.87 15.71a8.5 8.5 0 0 1-7.48 4.2H40.55a8.5 8.5 0 0 1-7.48-4.2a7.59 7.59 0 0 1 0-7.72l87.45-151.87a8.75 8.75 0 0 1 15 0l87.45 151.87a7.59 7.59 0 0 1-.04 7.72M120 144v-40a8 8 0 0 1 16 0v40a8 8 0 0 1-16 0m20 36a12 12 0 1 1-12-12a12 12 0 0 1 12 12" />
                      </g>
                    </svg>
                    <p className="text-yellow-800 text-sm m-0">
                      {warningMessage}
                    </p>
                  </div>
                )}
                {authmessage && (
                  <div className="flex items-center text-gray-800 bg-gray-400 border border-gray-300 rounded-md px-2 py-1 my-1 gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M5 22q-.825 0-1.412-.587T3 20V6q0-.825.588-1.412T5 4h1V3q0-.425.288-.712T7 2t.713.288T8 3v1h8V3q0-.425.288-.712T17 2t.713.288T18 3v1h1q.825 0 1.413.588T21 6v14q0 .825-.587 1.413T19 22zm0-2h14V10H5zM5 8h14V6H5zm0 0V6zm7 6q-.425 0-.712-.288T11 13t.288-.712T12 12t.713.288T13 13t-.288.713T12 14m-4 0q-.425 0-.712-.288T7 13t.288-.712T8 12t.713.288T9 13t-.288.713T8 14m8 0q-.425 0-.712-.288T15 13t.288-.712T16 12t.713.288T17 13t-.288.713T16 14m-4 4q-.425 0-.712-.288T11 17t.288-.712T12 16t.713.288T13 17t-.288.713T12 18m-4 0q-.425 0-.712-.288T7 17t.288-.712T8 16t.713.288T9 17t-.288.713T8 18m8 0q-.425 0-.712-.288T15 17t.288-.712T16 16t.713.288T17 17t-.288.713T16 18"
                      />
                    </svg>
                    <p className="text-gray-800 text-sm m-0">{authmessage}</p>
                  </div>
                )}

                <div className="relative">
                  <div className="flex flex-wrap items-center border border-[#E1E1EE] rounded-[8px] p-2 focus-within:ring-2 focus-within:ring-blue-500 focus-within:border-blue-500 bg-[#FFF] shadow-[0px_2px_0px_rgba(0,0,0,0.04)]">
                    {selectedemailOptions?.map((option, index) => (
                      <div
                        key={index}
                        className="flex items-center bg-[#F3F5F9] rounded-full gap-2 pr-2 m-1 border-2 border-gray-300"
                      >
                        <InitialsLogo
                          str={option}
                          fontSize="12px"
                          width="20px"
                          height="20px"
                          borderRadius="50%"
                        />
                        <span className="text-sm">{option}</span>
                        <button
                          onClick={() => handleRemoveOption(index)}
                          className="text-gray-500 hover:text-gray-700 focus:outline-none"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 32 32"
                            fill="none"
                          >
                            <path
                              d="M24 24L8 8M24 8L8 24"
                              stroke="#001B4B"
                              strokeWidth="2"
                              strokeLinecap="round"
                            />
                          </svg>
                        </button>
                      </div>
                    ))}
                    <input
                      id="email-input"
                      type="email"
                      className="flex-grow outline-none text-base p-1 placeholder-gray-600"
                      placeholder={
                        selectedemailOptions.length === 0
                          ? "Enter email addresses"
                          : ""
                      }
                      value={inputValue}
                      onChange={handleInputChange}
                      onKeyPress={handleKeyPress}
                    />
                  </div>

                  {recommendedEmails?.length > 0 && (
                    <ul className="absolute z-10 left-0 right-0 mt-1 border border-gray-200 rounded-md shadow-lg bg-white max-h-48 overflow-y-auto">
                      {recommendedEmails.map((email, index) => (
                        <li
                          key={index}
                          className="px-3 py-2 hover:bg-gray-200 cursor-pointer text-base "
                          onClick={() => handleOptionClick(email)}
                        >
                          {email}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>

              <div className="w-full flex flex-col gap-4">
                <div className="w-full flex flex-row gap-4 mt-1">
                  <div className="w-fit">
                    <ToggleButton
                      options={[
                        { value: true, label: "24-hour " },
                        { value: false, label: "12-hour " },
                      ]}
                      value={is24HourFormat}
                      onChange={handleTimeFormatToggle}
                    />
                  </div>
                </div>
                <div className="w-full flex flex-row gap-4">
                  <div className="w-1/3">
                    <label
                      htmlFor="date"
                      className="w-full text-gray-800 text-sm not-italic font-normal after:content-['*'] after:text-red-900 after:ml-0.5"
                    >
                      Date
                    </label>

                    <DatePicker
                      id="customDatePicker"
                      autoComplete="off"
                      selected={
                        formdetails?.date ? new Date(formdetails.date) : null
                      }
                      dateFormat="MMM d, yyyy"
                      className="w-full h-[36px] border border-gray-400 p-2 rounded-lg text-center stext-sm shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder-gray-600"
                      minDate={new Date()}
                      required
                      onChange={(date) => {
                        setInput("date", date ? date : null);
                      }}
                    />
                  </div>
                  <div className="w-1/3">
                    <label
                      htmlFor="time"
                      className="w-full text-gray-800 text-sm not-italic font-normal after:content-['*'] after:text-red-900 after:ml-0.5"
                    >
                      Start time
                    </label>

                    <div className="relative">
                      {/* <div className="relative w-full h-[36px] border border-gray-400 rounded-lg text-sm shadow-[0px_2px_0px_rgba(0,0,0,0.04)]">
                      <input
                        type="text"
                        className="w-full h-full px-2 pr-6 bg-transparent outline-none rounded-lg"
                        value={
                          formdetails?.time ? formdetails?.time : "Start Time"
                        }
                        readOnly
                        onClick={() =>
                          document.getElementById("startTimeSelect").focus()
                        }
                      />
                      <svg
                        className="absolute right-2 top-1/2 transform -translate-y-1/2 w-4 h-4 pointer-events-none"
                        fill="#4A5568"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M7 10l5 5 5-5H7z"></path>
                      </svg>
                    </div>
                    <select
                      id="startTimeSelect"
                      className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer px-2 py-1"
                      name="time"
                      value={formdetails?.time}
                      onChange={(event) => {
                        setInput("time", event.target.value);
                        if (
                          formdetails?.endtime &&
                          moment(event.target.value, "hh:mm A").isAfter(
                            moment(formdetails.endtime, "HH:mm"),
                          )
                        ) {
                          setInput("endtime", "");
                          setMessage({
                            type: "error",
                            message:
                              "Start time should be earlier than end time.",
                          });
                        } else {
                          setMessage({});
                        }
                      }}
                    >
                      <option value="">Start Time</option>
                      {timeOptions.map((option, optionIndex) => (
                        <option
                          key={optionIndex}
                          value={option.value}
                          className="px-2 py-1"
                        >
                          {option.label}
                        </option>
                      ))}
                    </select> */}
                      <CustomSelect
                        options={timeOptions}
                        value={formdetails.time}
                        onChange={(value) => handleTimeChange(value, true)}
                        placeholder="Start Time"
                        is24HourFormat={is24HourFormat}
                      />
                    </div>
                  </div>

                  <div className="w-1/3">
                    <label
                      htmlFor="endtime"
                      className="w-full text-gray-800 text-sm not-italic font-normal after:content-['*'] after:text-red-900 after:ml-0.5"
                    >
                      End time
                    </label>

                    <div className="relative">
                      {/* <div className="relative w-full h-[36px] border border-gray-400 rounded-lg text-sm shadow-[0px_2px_0px_rgba(0,0,0,0.04)]">
                      <input
                        type="text"
                        className="w-full h-full px-2 pr-6 bg-transparent outline-none rounded-lg"
                        value={formdetails?.endtime || "End Time"}
                        readOnly
                        onClick={() =>
                          document.getElementById("endTimeSelect").focus()
                        }
                      />
                      <svg
                        className="absolute right-2 top-1/2 transform -translate-y-1/2 w-4 h-4 pointer-events-none"
                        fill="#4A5568"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M7 10l5 5 5-5H7z"></path>
                      </svg>
                    </div>
                    <select
                      id="endTimeSelect"
                      className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer px-2 py-1"
                      name="endtime"
                      value={formdetails?.endtime}
                      onChange={(event) => {
                        setInput("endtime", event.target.value);
                        if (
                          formdetails?.time &&
                          event.target.value <= formdetails?.time
                        ) {
                          setMessage({
                            type: "error",
                            message:
                              "End time should be later than start time.",
                          });
                        } else {
                          setMessage({});
                        }
                      }}
                    >
                      <option value="">End Time</option>
                      {getFilteredTimeOptions(
                        formdetails?.time,
                        formdetails?.time !== "",
                      ).map((option, optionIndex) => (
                        <option key={optionIndex} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select> */}
                      <CustomSelect
                        options={getFilteredTimeOptions(
                          formdetails?.time,
                          !!formdetails.time,
                        )}
                        value={formdetails.endtime}
                        onChange={(value) => handleTimeChange(value, false)}
                        placeholder="End Time"
                        is24HourFormat={is24HourFormat}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full">
                <label
                  htmlFor="meetinglink"
                  className="w-full text-gray-800 text-sm not-italic font-normal after:content-['*'] after:text-red-900 after:ml-0.5"
                >
                  Meeting link
                </label>
                {meetingLinkError && (
                  <p className="text-red-900 text-xs my-1 px-1">
                    {meetingLinkError}
                  </p>
                )}
                <div className="flex flex-col gap-4 my-3">
                  <button
                    type="button"
                    onClick={handleGetMeetLink}
                    className={`flex items-center rounded-lg border border-gray-500 text-gray-800 h-10 px-4 py-2 text-[14px] gap-2 ${
                      !MeetPlatformStatus.google
                        ? "opacity-50 cursor-not-allowed"
                        : "hover:border-blue-900 hover:text-blue-900"
                    }`}
                    disabled={!MeetPlatformStatus?.google}
                  >
                    <GoogleMeet width="35" height="35" />
                    {isMeetLinkLoading ? (
                      <ScaleLoader height={10} color="#1569EB" loading={true} />
                    ) : (
                      "Add Google Meet "
                    )}
                  </button>
                  <button
                    type="button"
                    onClick={handleGetOutlookLink}
                    className={`flex items-center rounded-lg border border-gray-500 text-gray-800 h-10 px-4 py-2 text-[14px] gap-2 ${
                      !MeetPlatformStatus.Microsoft
                        ? "opacity-50 cursor-not-allowed"
                        : "hover:border-blue-900 hover:text-blue-900"
                    }`}
                    disabled={!MeetPlatformStatus?.Microsoft}
                  >
                    <TeamsLogo width="35" height="35" />
                    {isOutLookLoading ? (
                      <ScaleLoader height={10} color="#1569EB" loading={true} />
                    ) : (
                      "Add Microsoft Teams "
                    )}
                  </button>
                  <button
                    type="button"
                    onClick={handleGetZoomLink}
                    className={`flex items-center rounded-lg border border-gray-500 text-gray-800 h-10 px-4 py-2 text-[14px] gap-2 ${
                      !MeetPlatformStatus.Zoom
                        ? "opacity-50 cursor-not-allowed"
                        : "hover:border-blue-900 hover:text-blue-900"
                    }`}
                    disabled={!MeetPlatformStatus?.Zoom}
                  >
                    <ZoomIcon width="35" height="30" />{" "}
                    {isZoomLinkLoading ? (
                      <ScaleLoader height={10} color="#1569EB" loading={true} />
                    ) : (
                      "Add Zoom Conference"
                    )}
                  </button>
                </div>
                <input
                  className="w-full border border-gray-400 p-2 rounded-lg text-base shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder-gray-600"
                  type="text"
                  name="meetinglink"
                  placeholder="meeting link"
                  value={formdetails?.link}
                  required="required"
                  onChange={(event) => {
                    setInput("link", event.target.value);
                  }}
                />

                <label
                  htmlFor=""
                  className="w-full mt-3 text-gray-800 text-sm not-italic font-normal  after:text-red-900 after:ml-2"
                >
                  Description
                </label>
                <TextAreaBox
                  onChange={(e) => setInput("description", e.target.value)}
                  value={formdetails?.description}
                />
              </div>
              <div>
                {CalendarStatus === "" ? (
                  <div className="relative">
                    {showSteps && (
                      <div
                        ref={instructionsRef}
                        className="absolute bottom-full mb-2 bg-white border border-gray-200 shadow-lg rounded-md p-4 z-10 w-72"
                      >
                        <p className="font-semibold mb-2">
                          To set up calendar integration:
                        </p>
                        <ol className="list-decimal list-inside text-sm">
                          <li>
                            Go to{" "}
                            <a
                              href="/settings"
                              className="underline underline-offset-2 text-blue"
                            >
                              Settings
                            </a>
                          </li>
                          <li>Select the Integration tab</li>
                          <li>
                            Authenticate the service used by your organization
                          </li>
                        </ol>
                      </div>
                    )}
                    <p className="text-red-800 text-sm my-2 px-2 font-medium ">
                      Calendar integration not set up. Event cannot be added.
                      <span
                        className="ml-2 text-red-600 hover:text-red-800 focus:outline-none py-1"
                        onMouseEnter={() => setShowSteps(!showSteps)}
                        onClick={() => setShowSteps(!showSteps)}
                        aria-label="Show setup instructions"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 inline-block align-text-bottom"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </span>
                    </p>
                  </div>
                ) : (
                  <></>
                )}
              </div>

              <div className="w-full flex items-start gap-4">
                <input
                  type="checkbox"
                  className="w-5 h-5"
                  id="confirm"
                  checked={checked}
                  onClick={handleCheckClick}
                />
                <label
                  htmlFor="confirm"
                  className="grow text-gray-800 text-sm not-italic font-normal"
                >
                  Please confirm that the meeting details are accurate before
                  sending the invite.
                </label>
              </div>
              {formValidMsg && (
                <div className="w-fit flex items-center text-red-800 bg-red-100 border border-red-300 rounded-md p-2 my-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="text-red-500 font-semibold mr-3"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2M12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8s8 3.58 8 8s-3.58 8-8 8"
                    />
                  </svg>
                  <p className="text-sm m-0">{formValidMsg}</p>
                </div>
              )}

              {/* {Object.entries(message)?.length > 0 && (
                <span
                  className={
                    message.type == "success"
                      ? "text-green-800 font-normal text-sm"
                      : "text-red-800 font-normal text-sm"
                  }
                >
                  {message.message}
                </span>
              )} */}
              <div className="flex flex-row justify-end items-center">
                <button
                  className="rounded-lg border border-[#dadae4] border-button bg-white h-[40px] text-[#252e4b] text-[16px] px-4  mr-4 flex items-center gap-2"
                  onClick={handleCancel}
                  type="button"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className={`bg-blue-800 border border-blue-800 text-white btn-md ${
                    !checked || !isFormValid()
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                  }`}
                  disabled={!checked || !isFormValid() || loading}
                >
                  {loading ? (
                    <ScaleLoader height={14} color="#ffffff" loading={true} />
                  ) : (
                    "Invite"
                  )}
                </button>
              </div>
            </form>
          </div>
          <div className="w-[50%] flex flex-col  bg-white rounded-lg p-4">
            <div className="flex fex-row items-start h-20">
              <div className="flex flex-wrap gap-4 items-start w-[40%]">
                <div className="flex gap-2 items-center px-4 py-3">
                  {!formdetails?.candidate ? (
                    <></>
                  ) : (
                    <>
                      <span className="w-[15px] h-[15px] rounded-lg bg-[orange]"></span>
                      <span className="break-all">
                        {formdetails?.candidate?.split("@")[0] + "(Candidate)"}
                      </span>
                    </>
                  )}
                </div>
              </div>
              <div className="w-[60%]">
                <div className="flex flex-col gap-2">
                  <h3>Timezone</h3>
                  <TimezoneSelect
                    Timezone={InterviewertimeZone}
                    setTimezone={setInterviewerTimeZone}
                  />
                </div>
              </div>
            </div>
            <ErrorMessage
              failedInterviewers={ZeroEventsInterviewers}
              Text={"No events found "}
            />
            <ErrorMessage
              failedInterviewers={failedFetchInterviewers}
              Text={"Events details are not available for external users"}
              isExternalError={true}
            />
            <GoogleCalenderView
              eventsForSelectedDay={eventsForSelectedDay}
              selectedEmails={selectedemailOptions}
              CandidateAvailability={availabilities}
              selectedDate={selectedDate}
              onDateChange={setSelectedDate}
              InterviewertimeZone={InterviewertimeZone}
            />
            {/* <div className="flex flex-col gap-4">
              <div className="w-full px-4 py-4">
                <h2 className="text-gray-800 text-sm not-italic mb-4 ">
                  Interviewer availability
                </h2>
                <style>{customCalendarStyles}</style>
                <div className="flex flex-row gap-4">
                  <div className="flex flex-col gap-2">
                  
                    <h3>Date </h3>
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      dateFormat="MMMM d, yyyy"
                      minDate={new Date()}
                      className="w-full h-full p-2 border border-gray-400 rounded-lg shadow-[0px_2px_0px_rgba(0,0,0,0.04)]"
                      placeholderText="Select date"
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <h3>Timezone</h3>

                    <SelectBox
                      className=""
                      height="full"
                      value={InterviewertimeZone}
                      onChange={(e) => setInterviewerTimeZone(e.target.value)}
                      options={timeZones}
                      size={"small"}
                    />
                  </div>
                </div>
              </div>

              <div className="w-full px-4 py-2">
                <h2 className="text-gray-800 text-sm font-semibold mb-2">
                  Scheduled Events for {selectedDate.toDateString()}
                </h2>
                <div className="overflow-y-auto ">
                  <div className="space-y-2 max-h-[90vh]">
                    {selectedemailOptions.length > 0 ? (
                      selectedemailOptions.map((interviewer) => (
                        <div
                          key={interviewer}
                          className="border rounded-lg overflow-hidden"
                        >
                          <button
                            onClick={() => toggleDropdown(interviewer)}
                            className="w-full text-left px-4 py-3 border border-gray-200 bg-gray-100 hover:bg-gray-200 flex justify-between items-center rounded-lg shadow-md transition duration-150 ease-in-out"
                          >
                            <span>{interviewer.split("@")[0]} </span>
                            <span>
                              ({eventsByInterviewer[interviewer]?.length || 0}{" "}
                              events) {openDropdown === interviewer ? "▲" : "▼"}
                            </span>
                          </button>
                          {openDropdown === interviewer && (
                            <div className="max-h-[50vh] overflow-y-auto bg-white">
                              {EventsLoading ? (
                                <div className="flex justify-center items-center h-20">
                                  <ScaleLoader
                                    height={20}
                                    color="#1569EB"
                                    loading={true}
                                  />
                                </div>
                              ) : eventsByInterviewer[interviewer] &&
                                eventsByInterviewer[interviewer].length > 0 ? (
                                <ul className="divide-y divide-gray-200">
                                  {eventsByInterviewer[interviewer].map(
                                    (event, index) => {
                                      const eventColor = getRandomColor(event);
                                      return (
                                        <li
                                          key={index}
                                          className="p-4 hover:bg-gray-50"
                                        >
                                          <div className="flex items-center space-x-2">
                                            <div
                                              className="w-3 h-3 rounded-full flex-shrink-0"
                                              style={{
                                                backgroundColor: eventColor,
                                              }}
                                            ></div>
                                            <span className="font-medium">
                                              {event.summary}
                                            </span>
                                          </div>
                                          <div className="mt-1 text-sm text-gray-600">
                                            {moment(event.startTime).format(
                                              "h:mm A",
                                            )}{" "}
                                            -{" "}
                                            {moment(event.endTime).format(
                                              "h:mm A",
                                            )}
                                          </div>
                                        </li>
                                      );
                                    },
                                  )}
                                </ul>
                              ) : (
                                <p className="p-4 text-gray-500 ">
                                  {eventsByInterviewer[interviewer] ===
                                    undefined
                                    ? "Failed to fetch events for this interviewer."
                                    : "No events for this interviewer."}
                                </p>
                              )}
                            </div>
                          )}
                        </div>
                      ))
                    ) : (
                      <div className="text-center py-8 text-gray-500 bg-gray-100 rounded-lg">
                        No interviewers selected
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default SchedulingInterview;
