import React, { useState } from "react";
import InitialsLogo from "../../../all/InitialsLogo";
import { IoMdThumbsDown, IoMdThumbsUp } from "react-icons/io";
import { LuThumbsDown, LuThumbsUp } from "react-icons/lu";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Dropdown } from "antd";

function PreProspectApplicantList({
  checkedItems = [],
  idx,
  iter,
  addtoProspect,
}) {
  const [likeIcon, setLikeIcon] = useState(-1);
  const [iconId, setIconId] = useState(-1);

  function formatLink(link) {
    // Check if the link starts with "https://" or "http://"
    if (!link.startsWith("https://") && !link.startsWith("http://")) {
      // If it doesn't, add "https://"
      link = "https://" + link;
    }

    return link;
  }
  return (
    <>
      <tr
        className={`candidate-detail-row-1 pt-4 pb-4  relative hover:bg-[#f4f8ff] ${checkedItems?.includes(iter?._id) && "bg-[#f4f8ff]"} `}
        onMouseEnter={() => {
          setLikeIcon(idx);
        }}
        onMouseLeave={() => setLikeIcon(-1)}
      >
        <ReactTooltip
          id="my-tooltip-4"
          noArrow={true}
          style={{
            zIndex: 9999,
            background: "#5F6989",
            maxWidth: "300px",
            fontSize: "10px",
            padding: "4px 8px",
            fontWeight: "400",
          }}
          place="top"
        />

        <td className=" px-4 pb-4 pt-8  flex overflow-x-hidden">
          <div
            className="w-[40px] h-[40px] flex justify-center items-center rounded-[6px] hover:bg-[#FFFFFF]"
            onMouseEnter={() => setIconId(idx)}
            onMouseLeave={() => setIconId(null)}
          >
            <span className="">
              <InitialsLogo str={iter?.first_name} bgRounded={"50%"} />
            </span>
            {/* )} */}
          </div>

          <div className="col-span-8 ml-[14px] relative">
            <div className="flex items-center flex-wrap">
              <h4 className=" text-base not-italic cursor-pointer font-medium text-gray-800 ">
                {iter?.first_name}{" "}
                {iter?.last_name?.length > 0 ? iter?.last_name : ""}
              </h4>
              {iter?.current_source && (
                <span className="cursor-pointer ml-[8px] py-[2px] px-[6px] bg-blue-50 rounded-[4px]  text-[10px] not-italic font-normal text-blue-700">
                  {iter?.current_source === "Linkedin"
                    ? "Sprouts"
                    : iter?.current_source}
                </span>
              )}
              {iter?.remote_source && (
                <span className="ml-[8px] py-[2px] px-[6px] bg-blue-50 rounded-[4px]  text-[10px] not-italic font-normal text-blue-700">
                  {iter?.remote_source ?? ""}
                </span>
              )}
            </div>

            <span className="text-sm not-italic font-normal  text-gray-700 ">
              {iter?.title}
            </span>
            <div className=" flex mt-[10px]">
              {iter?.applicant?.resume_link?.length > 0 && (
                <>
                  {(iter?.applicant?.resume_link?.toLowerCase() !=
                    "not specified" ||
                    formatLink(iter?.applicant?.resume_link) !=
                      "https://sproutsai-staging.s3.amazonaws.com/undefined") && (
                    <button className="candidate-email-icon border-button rounded-md border border-solid border-gray-gray-4 bg-gray-gray-1 shadow-md max-h-7 px-2 py-1 mr-2 relative">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <path
                          d="M6.9987 6.99935C8.60953 6.99935 9.91537 5.69351 9.91537 4.08268C9.91537 2.47185 8.60953 1.16602 6.9987 1.16602C5.38787 1.16602 4.08203 2.47185 4.08203 4.08268C4.08203 5.69351 5.38787 6.99935 6.9987 6.99935Z"
                          fill="#5F6989"
                        />
                        <path
                          d="M7.00172 8.45898C4.07922 8.45898 1.69922 10.419 1.69922 12.834C1.69922 12.9973 1.82755 13.1257 1.99089 13.1257H12.0126C12.1759 13.1257 12.3042 12.9973 12.3042 12.834C12.3042 10.419 9.92422 8.45898 7.00172 8.45898Z"
                          fill="#5F6989"
                        />
                      </svg>
                    </button>
                  )}
                </>
              )}

              {iter?.linkedin?.length > 0 && (
                <>
                  {iter?.linkedin?.toLowerCase() != "not specified" && (
                    <a
                      className="candidate-linkedin-icon border-button  rounded-md border border-solid border-gray-gray-4 bg-gray-gray-1 shadow-md px-2 py-1 mr-2"
                      href={formatLink(iter?.linkedin)}
                      target="_blank"
                      rel="noreferrer"
                      data-tooltip-id="my-tooltip-4"
                      data-tooltip-content={"Linkedin"}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M4.35313 3.55556C4.35295 3.89233 4.2138 4.21523 3.96629 4.45324C3.71878 4.69125 3.38318 4.82487 3.03333 4.8247C2.68347 4.82453 2.34801 4.69059 2.10075 4.45234C1.85348 4.21409 1.71467 3.89106 1.71484 3.55429C1.71502 3.21753 1.85417 2.89462 2.10168 2.65661C2.34919 2.41861 2.68479 2.28499 3.03465 2.28516C3.3845 2.28532 3.71996 2.41927 3.96723 2.65751C4.21449 2.89576 4.3533 3.2188 4.35313 3.55556ZM4.3927 5.76496H1.75442V13.7137H4.3927V5.76496ZM8.56119 5.76496H5.9361V13.7137H8.53481V9.54253C8.53481 7.21885 11.681 7.00299 11.681 9.54253V13.7137H14.2863V8.67909C14.2863 4.76184 9.6297 4.90787 8.53481 6.83157L8.56119 5.76496Z"
                          fill="#5F6989"
                        />
                      </svg>
                    </a>
                  )}
                </>
              )}
            </div>
          </div>
        </td>
        <td className="px-4 pb-4 pt-8">
          <div className="flex flex-col gap-1">
            <div className="flex gap-2">
              {iter?.current_company && (
                <div className="flex items-center gap-3">
                  <span
                    data-tooltip-id={`my-tooltip-4`}
                    className="text-[14px] truncate border-gray-300 border px-2 py-0.5 rounded-md"
                    data-tooltip-content={iter?.current_company}
                  >
                    {iter?.current_company}
                  </span>
                </div>
              )}

              {iter?.location ? (
                <div className="flex items-center gap-3">
                  <span
                    data-tooltip-id={`my-tooltip-4`}
                    className="text-[14px] truncate border-gray-300 border px-2 py-0.5 rounded-md flex"
                    data-tooltip-content={`${iter.location ?? ""}`}
                  >
                    {`${iter?.location ?? ""}`}
                  </span>
                </div>
              ) : null}
            </div>
            {iter?.summary ? (
              <div className="flex items-center gap-3 w-[300px]">
                <span
                  data-tooltip-id={`my-tooltip-4`}
                  className="text-[14px] truncate border-gray-300 border  px-2 py-0.5 rounded-md "
                  // data-tooltip-content={`${iter.summary ?? ""}`}
                  data-tooltip-html={`${iter.summary ?? ""}`}
                >
                  {`${iter?.summary ?? ""}`}
                </span>
              </div>
            ) : null}
          </div>
        </td>

        <td
          //    ref={dropdownRef}
          className=" px-4 pb-4 pt-8 relative"
        >
          <div className=" flex items-center justify-end relative">
            <>
              <div className="flex items-center gap-3">
                <span
                  className="btn-sec btn-md p-2 group flex items-center text-blue-800 cursor-pointer"
                  onClick={() => {
                    if (!(iter?.thumbs_up == 1)) {
                      addtoProspect(1, iter?._id);
                    }
                  }}
                >
                  {iter?.thumbs_up === 1 ? (
                    <IoMdThumbsUp
                      color="#5F6989"
                      data-tooltip-id="my-tooltip-4"
                      data-tooltip-content={"like"}
                      className="candidate-like-icon w-6 h-6"
                    />
                  ) : (
                    <LuThumbsUp
                      color="#5F6989"
                      data-tooltip-id="my-tooltip-4"
                      data-tooltip-content={"like"}
                      className="candidate-like-icon w-5 h-5"
                    />
                  )}
                </span>
                <span
                  className="btn-sec btn-md p-2 group flex items-center text-blue-800 cursor-pointer"
                  onClick={() => {
                    if (!(iter?.thumbs_up == 2)) {
                      addtoProspect(2, iter?._id);
                    } else {
                      addtoProspect(0, iter?._id);
                    }
                  }}
                >
                  {iter?.thumbs_up === 2 ? (
                    <IoMdThumbsDown
                      color="#5F6989"
                      data-tooltip-id="my-tooltip-4"
                      data-tooltip-content={"Dislike"}
                      className="candidate-unlinke-icon w-6 h-6"
                    />
                  ) : (
                    <LuThumbsDown
                      color="#5F6989"
                      data-tooltip-id="my-tooltip-4"
                      data-tooltip-content={"Dislike"}
                      className="candidate-unlinke-icon w-5 h-5"
                    />
                  )}
                </span>
              </div>
            </>
          </div>
        </td>
      </tr>
    </>
  );
}

export default PreProspectApplicantList;
