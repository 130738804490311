import React, { useEffect, useState } from "react";
import { convertToHTML } from "draft-convert";
import { convertFromHTML, ContentState, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Modal from "../../modal/Modal";
import { useSelector } from "react-redux";

const core = require("../../../lib/core");

function SendEmail({ chatDetail, companydata, positionData, modalDismiss }) {
  const [editorStateDesc, setEditorStateDesc] = useState(() =>
    EditorState.createEmpty(),
  );
  const [editorValue, setEditorValue] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [from, setFrom] = useState([]);
  const [to, setTo] = useState([]);
  const [subject, setSubject] = useState([]);

  const { position, jobId } = useSelector((state) => {
    return {
      position: state?.JobExpandSlice?.profile?.position,
      jobId: state?.JobExpandSlice?.profile?.id,
    };
  });

  useEffect(() => {
    if (chatDetail?.email?.length > 0) {
      setTo(chatDetail?.email[0]);
    }
  }, [chatDetail]);

  const [emailSuccess, setEmailSuccess] = useState(false);

  // Array of sample options (you can fetch these dynamically)
  const sampleOptions = [];

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleOptionClick = (option) => {
    setSelectedOptions([...selectedOptions, option]);
    setInputValue(""); // Clear input after selecting an option
  };

  const handleRemoveOption = (index) => {
    const updatedOptions = [...selectedOptions];
    updatedOptions.splice(index, 1);
    setSelectedOptions(updatedOptions);
  };
  console.log("chatDetail", chatDetail);
  useEffect(() => {
    setEditorValue(convertToHTML(editorStateDesc?.getCurrentContent()));
  }, [editorStateDesc]);

  const [sendText, setSendingText] = useState("Send email");

  console.log(to, jobId);

  async function sendEmail() {
    setSendingText("Sending...");
    await core
      .API(core.API_METHODS.POST, core.RECRUITER_API.POST_EMAIL_CANDIDATE, 1, {
        to: to,
        cc: selectedOptions,
        body: editorValue,
        subject: subject,
        matchprofile: chatDetail?.matchId,
        applicant: chatDetail?._id,
        job: jobId,
        name: chatDetail?.first_name + " " + chatDetail?.last_name ?? "",
      })
      .then((json) => {
        setEmailSuccess(true);
        setSubject();
        setSendingText("Send email");
      });
  }

  const [user, setUser] = useState();
  async function getUser() {
    try {
      let json = await core.API(
        core.API_METHODS.GET,
        core.USER_API.GET_USER,
        1,
      );
      // If user exists
      if (json?.data?.user) {
        setSelectedOptions([...selectedOptions, json?.data?.user?.email]);
      } else {
      }
    } catch (err) {}
  }

  const getTemplate = async () => {
    setSubject("");
    setEditorStateDesc(
      EditorState?.createWithContent(
        ContentState?.createFromBlockArray(
          convertFromHTML(new String("Generating message...").toString()),
        ),
      ),
    );
    try {
      const json = await core.API(
        core.API_METHODS.POST,
        core.RECRUITER_API.POST_EMAIL_TEMPLATE,
        1,
        {
          company_name: companydata,
          job_position: position,
          candidate_name: chatDetail?.first_name,
          candidate_matching_comment: chatDetail?.summary,
          matchId: chatDetail?._id,
        },
      );
      console.log(chatDetail);
      if (json?.data) {
        setSubject(json?.data?.data?.subject);
        if (typeof json?.data?.data?.body == "string") {
          setEditorStateDesc(
            EditorState?.createWithContent(
              ContentState?.createFromBlockArray(
                convertFromHTML(
                  new String(
                    json?.data?.data?.body?.split("&lt;").join("<"),
                  ).toString(),
                ),
              ),
            ),
          );
        } else {
          setEditorStateDesc(
            EditorState?.createWithContent(
              ContentState?.createFromBlockArray(
                convertFromHTML(new String("Failed to generate").toString()),
              ),
            ),
          );
        }
      }

      if (json?.error) {
        setEditorStateDesc(
          EditorState?.createWithContent(
            ContentState?.createFromBlockArray(
              convertFromHTML(new String("Failed to generate").toString()),
            ),
          ),
        );
      }
    } catch (error) {
      setEditorStateDesc(
        EditorState?.createWithContent(
          ContentState?.createFromBlockArray(
            convertFromHTML(new String("Failed to generate").toString()),
          ),
        ),
      );
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    getTemplate();
    setEmailSuccess(false);
  }, [chatDetail]);

  return (
    <Modal
      modalDismiss={modalDismiss}
      noBtns={true}
      className={"md:max-w-[60%]"}
    >
      {!emailSuccess ? (
        <>
          <div className="">
            <h5 className="text-black text-base font-medium w-[calc(100%-32px)] not-italic">
              Send email to {to}
            </h5>
            <span className="text-gray-700 text-sm -mt-3 not-italic font-normal">
              Verify the email's content and then proceed to send it to the
              candidate.
            </span>
          </div>

          <div className="">
            {/* <div className=' mt-[20px]'>
                            <label className=' text-right text-[14px] font-normal not-italic required' htmlFor="">To</label>
                            <input value={to} required className='border  py-[11px] border-[#E1E1EE] bg-[#FFF] px-[20px] rounded-[8px] w-full' type="email"
                                onChange={e => setTo(e.target.value)}
                            />
                        </div> */}
            <div className="  mt-[20px]">
              <label
                className="  text-[14px] font-normal not-italic required"
                htmlFor=""
              >
                Subject
              </label>
              <input
                className="border  border-[#E1E1EE] py-[11px] bg-[#FFF] px-[20px] rounded-[8px] w-full"
                type="text"
                value={subject}
                onChange={(e) => setSubject(e?.target?.value)}
              />
            </div>
            <div className=" mt-[20px]">
              <label className=" text-[14px] font-normal not-italic" htmlFor="">
                Cc
              </label>
              <div className="relative border flex flex-wrap py-[11px]  border-[#E1E1EE] bg-[#FFF]  rounded-[8px] w-full px-[20px] ">
                {selectedOptions.map((option, index) => (
                  <span
                    className="h-[20px] flex items-center mr-[10px] rounded-[4px] bg-[#F3F5F9] px-[10px]"
                    key={index}
                  >
                    {option}
                    <svg
                      className="cursor-pointer"
                      onClick={(e) => handleRemoveOption(index)}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M7 7L17 17"
                        stroke="#252E4B"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7 17L17 7"
                        stroke="#252E4B"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                ))}
                <div className="relative">
                  <input
                    type="email"
                    className="w-auto"
                    value={inputValue}
                    onChange={handleInputChange}
                  />

                  {inputValue?.length > 2 && (
                    <ul className="absolute bg-[#F3F5F9] px-[10px] py-[5px] rounded-[5px] top-[24px]">
                      <li onClick={() => handleOptionClick(inputValue)}>
                        {inputValue}
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-[20px]" id="description">
              <label
                className=" text-right text-[14px] mt-[15px] font-normal not-italic"
                htmlFor=""
              >
                Body
              </label>
              <Editor
                editorState={editorStateDesc}
                onEditorStateChange={setEditorStateDesc}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
              />
            </div>
          </div>

          <div className="flex justify-end mt-[20px]">
            <button
              disabled={
                sendText === "Sending..." ||
                editorValue === "Generating message..." ||
                subject?.length === 0
                  ? true
                  : false
              }
              onClick={sendEmail}
              className="solid-button-blue px-[20px] h-[40px] rounded-[8px] bg-[#1369E9]  shadow-jobPostButtonShadow flex items-center justify-center text-[#FFF] text-[16px] not-italic font-normal"
            >
              {sendText}
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="60"
              height="60"
              viewBox="0 0 60 60"
              fill="none"
            >
              <rect width="60" height="60" rx="20" fill="#F3F9E6" />
              <rect
                x="7"
                y="7"
                width="45.3659"
                height="45.3659"
                rx="12"
                fill="#80BB01"
              />
              <path
                d="M29.1061 31.9281L30.0096 32.8359C30.4005 33.2288 31.0363 33.2288 31.4272 32.8359L39.5742 24.6493C40.0216 24.1998 40.7492 24.1998 41.1966 24.6493C41.6409 25.0958 41.6409 25.8174 41.1966 26.2639L31.7652 35.7412C31.1879 36.3213 30.2489 36.3213 29.6716 35.7412L24.2549 30.2982C23.8106 29.8517 23.8106 29.1301 24.2549 28.6836C24.7023 28.2341 25.43 28.2341 25.8774 28.6836L27.4926 30.3068L29.1061 31.9281ZM29.9094 29.4881C29.4663 29.0429 29.4663 28.3234 29.9093 27.8782L33.9539 23.813C34.4 23.3647 35.1257 23.3646 35.5719 23.8129C36.0149 24.2581 36.0149 24.9776 35.5719 25.4229L31.5273 29.488C31.0812 29.9364 30.3555 29.9364 29.9094 29.4881ZM25.0775 34.3651C25.5215 34.8113 25.5215 35.5323 25.0775 35.9785C24.6304 36.4278 23.9033 36.4278 23.4562 35.9785L17.8034 30.2982C17.3591 29.8517 17.3594 29.1298 17.8038 28.6833C18.2507 28.2341 18.9781 28.2338 19.4251 28.6829L20.2275 29.4892C20.2278 29.4895 20.2278 29.49 20.2275 29.4904C20.2272 29.4907 20.2272 29.4912 20.2275 29.4915L25.0775 34.3651Z"
                fill="white"
                stroke="white"
                strokeWidth="0.2"
              />
            </svg>
            <span className="text-[#16182F] text-24 font-semibold not-italic leading-[32px]">
              Email successfully sent to {chatDetail?.first_name}
            </span>
          </div>
          <div className="mt-[28px] flex justify-center">
            <button
              className="bg-bg1 rounded-[8px] py-[12px] px-[16px]"
              onClick={(e) => {
                setEmailSuccess(false);
                modalDismiss();
              }}
              data-dismiss="modal"
            >
              <span className="text-[#252E4B] text-[16px] font-normal not-italic flex items-center">
                <svg
                  className="mr-[5px]"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <path
                    d="M7.97508 5.65625L2.91675 10.7146L7.97508 15.7729"
                    stroke="#252E4B"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M17.0834 10.7188H3.05835"
                    stroke="#252E4B"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>{" "}
                Back to job{" "}
              </span>
            </button>
          </div>
        </>
      )}
    </Modal>
  );
}

export default SendEmail;
