import React, { useState } from "react";
import AutoCompleteInputLocation from "./AutoCompleteInputLocation";
import AutocompleteInput from "./AutocompleteInput";
import { MdDelete } from "react-icons/md";
import { FaCaretDown } from "react-icons/fa";
import { IoSend } from "react-icons/io5";
import { BsFilterLeft } from "react-icons/bs";
import Typewriter from "typewriter-effect";
const FilterModal = ({
  showFilter,
  setShowFilter,
  listcordinates,
  setListcordinates,
  loading,
  Locations,
  setListlocation,
  setFilterLocation,
  getLocationSuggestions,
  listlocation,
  showInputExperience,
  setShowInputExperience,
  minExp,
  setMinExp,
  maxExp,
  setMaxExp,
  selectedOptions,
  setSelectedOptions,
  showDropdown,
  setShowDropdown,
  listskill,
  setListskill,
  listjobtitle,
  setlistjobtitle,
  minTime,
  setMinTime,
  maxTime,
  setMaxTime,
  listdegrees,
  setListdegrees,
  handleFilterSubmit,
  locationRange,
  setLocationRange,
  isLocationDropdownOpen,
  setIsLocationDropdownOpen,
  locationOptions,
  jobRange,
  setJobRange,
  isjobRangeDropdownOpen,
  setIsjobRangeDropdownOpen,
  jobtitleOptions,
  schoolName,
  setSchoolName,
  listschoolname,
  setListschoolname,
  listCompanyNames,
  setListCompanyNames,
  setRequiredInfo,
  prompt,
  setPrompt,
  handleAISearch,
  setAllowCloseModal,
  setCurrentPage,
  handleFilteredSubmit,
  companyName,
  setCompanyName,
  searchList,
  fetchSuggestions,
  showPlaceholder,
  setShowPlaceholder,
  handleBlur,
  handleFocus,
  handleFocusOn,
  jobSearchList,
  fetchJobSuggestions,
}) => {
  const handleOptionClick = (option) => {
    setSelectedOptions((prevOptions) =>
      prevOptions.includes(option)
        ? prevOptions.filter((item) => item !== option)
        : [...prevOptions, option],
    );
    setRequiredInfo((prevInfo) =>
      prevInfo.includes(option)
        ? prevInfo.filter((item) => item !== option)
        : [...prevInfo, option],
    );
    setShowDropdown(false);
  };

  // const placeholderText =
  //   "Software Engineer with 5+ years of experience in San Francisco";
  // const placeholder = useTypewriter(placeholderText, 100);

  const addLocation = (location) => {
    const newLocation = { ...location, id: new Date().getTime() };
    setListcordinates((prev) => [...prev, newLocation]);
  };

  const removeLocation = (id) => {
    setListcordinates((prev) => prev.filter((item) => item.id !== id));
    setListlocation((prev) => {
      const itemToRemove = listcordinates.find((item) => item.id === id);
      if (itemToRemove) {
        return prev.filter((loc) => loc !== itemToRemove.name);
      }
      return prev;
    });
  };

  const [isValueSelected, setIsValueSelected] = useState(false);

  const handleSelectOption = (option) => {
    setLocationRange(option);
    setIsLocationDropdownOpen(false);
    setIsValueSelected(true);
  };

  const handleToggleDropdown = () => {
    setIsLocationDropdownOpen(!isLocationDropdownOpen);
    if (isLocationDropdownOpen && !isValueSelected) {
      setIsValueSelected(false);
    }
  };

  const handleInputChange = (e) => {
    setPrompt(e.target.value);
  };

  const handleClear = () => {
    setListCompanyNames([]);
    setListcordinates([]);
    setIsValueSelected(false);
    setLocationRange("25km");
    setMinExp(null);
    setMaxExp(null);
    setSelectedOptions([]);
    setListskill([]);
    setlistjobtitle([]);
    setJobRange("Select Range");
    setListlocation([]);
    setRequiredInfo([]);
    setSchoolName([]);
    setListschoolname([]);
    setListdegrees([]);
    setPrompt("");
    setCurrentPage(1);
    handleFocusOn();
    // setShowFilter(false);
  };

  return (
    <>
      {showFilter && (
        <>
          <div
            className="fixed inset-0 bg-black opacity-30 z-40"
            onClick={() => setShowFilter(false)}
          ></div>{" "}
          <div className="fixed rounded-lg top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-11/12 max-w-4xl bg-white z-50 p-3 overflow-auto h-[90%] ">
            <div className="flex items-center justify-between mb-3">
              <h2 className="text-lg font-semibold  text-gray-800">
                Edit Search Filters
              </h2>
              <button
                className="text-gray-700 px-4 py-1 text-md rounded-md font-bold hover:text-blue-800"
                onClick={() => setShowFilter(false)}
              >
                ✕
              </button>
            </div>
            <div className="flex flex-col">
              <div className="flex flex-col mt-[2px] px-5 py-2 pb-8 bg-defaultBg justify-between rounded-lg ">
                <h6 className="cursor-pointer text-base font-medium not-italic pt-5 leading-[22px] text-gray-800 mt-[2px] mb-2">
                  <span className="flex flex-row">
                    <BsFilterLeft className="font-extrabold text-gray-800 text-xl  mr-2" />{" "}
                    Set filters with AI
                  </span>
                </h6>
                <div className="relative">
                  {showPlaceholder && (
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "16px",
                        transform: "translateY(-50%)",
                        color: "#a0aec0", // Tailwind's text-gray-600
                        pointerEvents: "none",
                        width: "calc(100% - 20px)",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        zIndex: 1,
                        lineHeight: "1.75rem", // Approximate to input line height
                        fontSize: "1rem", // Tailwind's text-base
                      }}
                    >
                      <Typewriter
                        options={{
                          strings: [
                            "Software Engineer with 5+ years of experience in San Francisco",
                          ],
                          autoStart: true,
                          loop: true,
                          delay: 100,
                          deleteSpeed: 50,
                          pauseFor: 2000,
                        }}
                      />
                    </div>
                  )}
                  <input
                    type="text"
                    placeholder=""
                    className="border border-[#DADAE4] md:h-[35px] h-[25px] lg:h-[45px] bg-[#FFF] py-1 px-[10px] w-full rounded-md shadow placeholder-gray-600 text-base pr-10"
                    value={prompt}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setPrompt(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleAISearch();
                      }
                    }}
                    // style={{
                    //   position: 'relative',
                    //   zIndex: 2, // Ensure input is above placeholder
                    //   // backgroundColor: 'transparent',
                    // }}
                  />
                  <IoSend
                    className="absolute text-blue-800 hover:text-gray-800 hover:cursor-pointer right-2 top-1/2 transform -translate-y-1/2 text-[20px] mr-2"
                    onClick={() => {
                      setCurrentPage(1);
                      handleAISearch();
                    }}
                  />
                </div>
              </div>

              <div className="flex flex-wrap justify-between mt-1">
                <div className="w-full lg:w-[49.5%]">
                  <div className="flex flex-col mt-[4px] px-5 py-2 pb-4 bg-defaultBg justify-between rounded-lg">
                    <div className="flex flex-row ">
                      <h6 className="cursor-pointer text-base font-medium not-italic pt-5 leading-[22px] text-gray-800 mt-[2px]">
                        <span>Location(s)</span>
                      </h6>
                      <div className="relative inline-block text-left mt-5 ml-3">
                        <button
                          type="button"
                          className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-1  bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                          aria-haspopup="true"
                          aria-expanded="true"
                          onClick={handleToggleDropdown}
                        >
                          {isValueSelected
                            ? `Within ${locationRange}`
                            : "Exact Location"}
                          <span className=" text-gray-700 text-md ml-2">
                            <FaCaretDown
                              className={
                                isLocationDropdownOpen ? "rotate-180" : ""
                              }
                            />
                          </span>
                        </button>
                        {isLocationDropdownOpen && (
                          <div className="origin-top-right absolute right-0 z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                            <div className="">
                              {locationOptions.map((option, index) => (
                                <div
                                  key={index}
                                  className="block px-4 py-2 border border-gray-300 text-sm text-gray-700 cursor-pointer hover:bg-defaultBg"
                                  onClick={() => {
                                    setCurrentPage(1);
                                    handleSelectOption(option);
                                  }}
                                >
                                  Within {option}
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="relative ">
                      <AutoCompleteInputLocation
                        loading={loading.location}
                        idx={"location"}
                        list={Locations || []}
                        classText={
                          "border border-[#DADAE4] md:h-[35px] h-[25px] mt-2 lg:h-[45px] bg-[#FFF] rounded-[8px] py-[3px] px-[10px] shadow"
                        }
                        setvalue={(e) => {
                          setCurrentPage(1);
                          setListlocation(e);
                          setFilterLocation(e[e.length - 1]);
                        }}
                        addLocation={addLocation}
                        initialArray={listlocation}
                        autoSuggestion={true}
                        onInputChange={(e) => getLocationSuggestions(e)}
                        handleFilterSubmit={handleFilterSubmit}
                      />
                    </div>
                    <div className="flex flex-wrap mt-3 gap-2">
                      {listcordinates?.map((item) => (
                        <label
                          className="flex items-center py-[5px] px-[8px] border bg-[#00AC85] rounded-[4px]"
                          key={item.id} // Use unique id as key
                        >
                          <span className="text-[#fff] text-[14px] font-normal not-italic">
                            {item.name}
                          </span>
                          <span
                            onClick={() => {
                              setCurrentPage(1);
                              removeLocation(item.id);
                            }}
                          >
                            <svg
                              className="ml-[4px] cursor-pointer"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M4.66797 4.66669L11.3346 11.3334"
                                stroke="white"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M4.66927 11.3334L11.3359 4.66669"
                                stroke="white"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </span>
                        </label>
                      ))}
                    </div>

                    <div className="flex justify-between space-x-2 mt-3 py-[12px]">
                      <h6 className="cursor-pointer text-base font-medium not-italic leading-[22px] text-gray-800 mb-[5px]">
                        <span
                          onClick={() =>
                            setShowInputExperience(!showInputExperience)
                          }
                        >
                          Min Experience
                        </span>
                        <input
                          className="rounded-md w-full shadow-sm border border-[#DADAE4] p-2 mt-2 md:h-[35px] h-[25px] lg:h-[45px] MuiInputBase-input MuiFilledInput-input css-2bxn45 placeholder-gray-500"
                          aria-invalid="false"
                          autoComplete="off"
                          name="yearsOfExp"
                          placeholder="Ex: 2 years"
                          type="number"
                          value={minExp !== null ? minExp : ""}
                          onChange={(e) => {
                            const value = e.target.value;
                            setCurrentPage(1);
                            setMinExp(value === "" ? null : value);
                          }}
                        />
                      </h6>
                      <h6 className="cursor-pointer text-base font-medium not-italic leading-[22px] text-gray-800 mb-[5px]">
                        <span
                          onClick={() =>
                            setShowInputExperience(!showInputExperience)
                          }
                        >
                          Max Experience
                        </span>
                        <input
                          className="rounded-md w-full shadow-sm border border-[#DADAE4] p-2 mt-2 md:h-[35px] h-[25px] lg:h-[45px] MuiInputBase-input MuiFilledInput-input css-2bxn45 placeholder-gray-500"
                          aria-invalid="false"
                          autoComplete="off"
                          name="yearsOfExp"
                          placeholder="Ex: 10 years"
                          type="number"
                          value={maxExp !== null ? maxExp : ""}
                          onChange={(e) => {
                            const value = e.target.value;
                            setCurrentPage(1);
                            setMaxExp(value === "" ? null : value);
                          }}
                        />
                      </h6>
                    </div>
                    <div className="mt-3 pb-3">
                      <h6 className="cursor-pointer text-base font-medium not-italic leading-[22px] text-gray-800 mb-[3px]">
                        <span>Required Contact Info</span>
                      </h6>
                      <div
                        className="flex w- items-center shadow-sm border border-[#DADAE4] rounded-md p-2 mt-2 md:h-[35px] h-[25px] lg:h-[45px] bg-white cursor-pointer placeholder-gray-600"
                        onClick={() => setShowDropdown(!showDropdown)}
                      >
                        {selectedOptions.length > 0 &&
                          selectedOptions.map((option, index) => (
                            <span
                              key={index}
                              className="bg-[#00AC85] text-white px-2 py-1 rounded-md mr-2"
                            >
                              {option}
                            </span>
                          ))}
                        <span className="text-gray-600 text-base">
                          {selectedOptions.length === 0 &&
                            "Select Contact Info"}
                        </span>
                        <span className="ml-auto text-gray-700 text-md mr-2">
                          <FaCaretDown
                            className={showDropdown ? "rotate-180" : ""}
                          />
                        </span>
                      </div>
                      {showDropdown && (
                        <div className="absolute z-10 bg-white border border-gray-300 rounded-md shadow-lg  mt-1 w-[43.8%]">
                          <div
                            className="block px-4 py-2 border border-gray-300 text-sm text-gray-700 cursor-pointer hover:bg-defaultBg"
                            onClick={() => {
                              setCurrentPage(1);
                              handleOptionClick("Phone Number");
                            }}
                          >
                            Phone Number
                          </div>
                          <div
                            className="block px-4 py-2 border border-gray-300 text-sm text-gray-700 cursor-pointer hover:bg-defaultBg"
                            onClick={() => {
                              setCurrentPage(1);
                              handleOptionClick("Personal Email");
                            }}
                          >
                            Personal Email
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col mt-[8px] px-5 py-2 pb-6 bg-defaultBg justify-between rounded-lg">
                    <div className="flex flex-row ">
                      <h6 className="cursor-pointer text-base font-medium not-italic pt-5 leading-[22px] text-gray-800 mt-2">
                        <span>Skills</span>
                      </h6>
                    </div>
                    <div className="relative ">
                      <AutocompleteInput
                        idx={"skillsdata"}
                        classText={
                          "border border-[#DADAE4] md:h-[35px] h-[25px] lg:h-[45px] mt-2 bg-[#FFF] py-1 px-[10px]"
                        }
                        list={searchList}
                        setvalue={(e) => {
                          setCurrentPage(1);
                          setListskill(e);
                        }}
                        initialArray={listskill}
                        fetchSuggestions={fetchSuggestions}
                      />
                    </div>
                    <div className="flex flex-wrap mt-3 gap-2">
                      {listskill?.map((item, i) => (
                        <label
                          className="flex items-center py-[5px] px-[8px] border bg-[#00AC85] rounded-[4px]"
                          key={i}
                        >
                          <span className="text-[#fff] text-[14px] font-normal not-italic">
                            {item}
                          </span>
                          <span
                            onClick={() => {
                              setCurrentPage(1);
                              setListskill([
                                ...listskill.filter((i) => i !== item),
                              ]);
                            }}
                          >
                            <svg
                              className="ml-[4px] cursor-pointer"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M4.66797 4.66669L11.3346 11.3334"
                                stroke="white"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M4.66927 11.3334L11.3359 4.66669"
                                stroke="white"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </span>
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-[49.5%]">
                  <div className="flex flex-col mt-[4px] px-5 py-2 pb-2 bg-defaultBg justify-between rounded-lg">
                    <div className="flex flex-row">
                      <h6 className="cursor-pointer text-base font-medium not-italic pt-5 leading-[22px] text-gray-800 mt-[2px]">
                        <span>Job Title</span>
                      </h6>
                      <div className="relative inline-block text-left mt-5 ml-3">
                        <button
                          type="button"
                          className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-1 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                          aria-haspopup="true"
                          aria-expanded="true"
                          onClick={() =>
                            setIsjobRangeDropdownOpen(!isjobRangeDropdownOpen)
                          }
                        >
                          {jobRange}
                          <span className=" text-gray-700 text-md ml-2">
                            <FaCaretDown
                              className={
                                isjobRangeDropdownOpen ? "rotate-180" : ""
                              }
                            />
                          </span>
                        </button>
                      </div>
                      {isjobRangeDropdownOpen && (
                        <div className="origin-top-right absolute right-3 mt-[60px] z-50 w-[432px] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                          <div className="">
                            {jobtitleOptions.map((option, index) => (
                              <div
                                key={index}
                                className="block px-4 pt-2 pb-1 border border-gray-300 text-sm text-gray-700 cursor-pointer hover:bg-defaultBg"
                                onClick={() => {
                                  setCurrentPage(1);
                                  setJobRange(option);
                                  setIsjobRangeDropdownOpen(false);
                                }}
                              >
                                {option}
                                <div className="text-xs text-gray-600">
                                  {index === 0 &&
                                    "Find people who currently hold these job titles"}
                                  {index === 1 &&
                                    "Find people who hold/held these job titles at any point in their career"}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="relative">
                      <AutocompleteInput
                        idx={"jobtitle"}
                        classText={
                          "border border-[#DADAE4] md:h-[35px] h-[25px] mt-2 lg:h-[45px]  bg-[#FFF] rounded-[8px]  py-[5px] px-[10px] shadow "
                        }
                        list={jobSearchList}
                        setvalue={(e) => {
                          setCurrentPage(1);
                          setlistjobtitle(e);
                        }}
                        initialArray={listjobtitle}
                        fetchJobSuggestions={fetchJobSuggestions}
                      />

                      <div className="flex flex-wrap mt-3 gap-2">
                        {listjobtitle?.map((item, i) => (
                          <label
                            className="flex items-center py-[5px] px-[8px] border bg-[#00AC85] rounded-[4px]"
                            key={i}
                          >
                            <span className="text-[#fff] text-[14px] font-normal not-italic">
                              {item}
                            </span>
                            <span
                              onClick={() => {
                                setCurrentPage(1);
                                setlistjobtitle([
                                  ...listjobtitle.filter((i) => i !== item),
                                ]);
                              }}
                            >
                              <svg
                                className="ml-[4px] cursor-pointer"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <path
                                  d="M4.66797 4.66669L11.3346 11.3334"
                                  stroke="white"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M4.66927 11.3334L11.3359 4.66669"
                                  stroke="white"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </span>
                          </label>
                        ))}
                      </div>
                      <div className="mt-3 pt-[12px] pb-[14px]">
                        <div className="flex flex-col">
                          <span className="cursor-pointer text-base font-medium not-italic leading-[22px] text-gray-800 mb-[5px] pt-0 ">
                            Company Name
                          </span>
                          <div className="relative">
                            <AutocompleteInput
                              idx={"companyName"}
                              classText={
                                "border border-[#DADAE4] md:h-[35px] h-[25px] lg:h-[45px] mt-[2px] bg-[#FFF] py-1 px-[10px] rounded-md shadow-sm placeholder-gray-600 text-base"
                              }
                              list={listCompanyNames} // Assuming you have a list of company names
                              setvalue={(e) => {
                                setCurrentPage(1);
                                setListCompanyNames(e);
                                setCompanyName(""); // Clear the input field
                              }}
                              initialArray={listCompanyNames} // Assuming this is the state holding the selected company names
                              placeholder="Enter the Company Name" // Assuming AutocompleteInput supports a placeholder prop
                            />
                          </div>
                        </div>

                        <div className="flex flex-wrap gap-2 mt-3">
                          {listCompanyNames?.map((item, i) => (
                            <label
                              className="flex items-center py-[5px] px-[8px] border bg-[#00AC85] rounded-[4px]"
                              key={i}
                            >
                              <span className="text-[#fff] text-[14px] font-normal not-italic">
                                {item}
                              </span>
                              <span
                                onClick={() => {
                                  setCurrentPage(1);
                                  setListCompanyNames([
                                    ...listCompanyNames.filter(
                                      (i) => i !== item,
                                    ),
                                  ]);
                                }}
                              >
                                <svg
                                  className="ml-[4px] cursor-pointer"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path
                                    d="M4.66797 4.66669L11.3346 11.3334"
                                    stroke="white"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M4.66927 11.3334L11.3359 4.66669"
                                    stroke="white"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </span>
                            </label>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-2 pt-3 pb-[14px] bg-defaultBg rounded-lg px-5">
                    <div className="relative">
                      <div className="pt-5 pb-3">
                        <span className="cursor-pointer text-base font-medium not-italic leading-[22px] text-gray-800 mb-2">
                          Degree Required
                        </span>
                        <div className="relative">
                          <AutocompleteInput
                            idx={"degree"}
                            classText={
                              "border border-[#DADAE4] md:h-[35px] h-[25px] lg:h-[45px] mt-2 bg-[#FFF] py-1 px-[10px]"
                            }
                            list={[
                              "Associate",
                              "Bachelor",
                              "Master",
                              "Doctorate",
                              "Professional",
                            ]}
                            setvalue={(e) => {
                              setCurrentPage(1);
                              setListdegrees(e);
                            }}
                            initialArray={listdegrees}
                          />
                        </div>
                      </div>
                      <div className="flex flex-wrap gap-2">
                        {listdegrees?.map((item, i) => (
                          <label
                            className="flex items-center py-[5px] px-[8px] border bg-[#00AC85] rounded-[4px]"
                            key={i}
                          >
                            <span className="text-[#fff] text-[14px] font-normal not-italic">
                              {item}
                            </span>
                            <span
                              onClick={() => {
                                setCurrentPage(1);
                                setListdegrees([
                                  ...listdegrees.filter((i) => i !== item),
                                ]);
                              }}
                            >
                              <svg
                                className="ml-[4px] cursor-pointer"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <path
                                  d="M4.66797 4.66669L11.3346 11.3334"
                                  stroke="white"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M4.66927 11.3334L11.3359 4.66669"
                                  stroke="white"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </span>
                          </label>
                        ))}
                      </div>
                      <div className="pb-3 mt-[10px]">
                        <div className="flex flex-col mb-2">
                          <span className="cursor-pointer text-base mt-1 font-medium not-italic leading-[22px] text-gray-800 mb-2">
                            School Name
                          </span>
                          <div className="relative">
                            <AutocompleteInput
                              idx={"schoolName"}
                              classText={
                                "border border-[#DADAE4] md:h-[35px] h-[25px] lg:h-[45px] bg-[#FFF] py-1 px-[10px] w-full rounded-md shadow placeholder-gray-600 text-base"
                              }
                              list={listschoolname} // Assuming you have a list of school names
                              setvalue={(e) => {
                                setCurrentPage(1);
                                setListschoolname(e);
                                setSchoolName(""); // Clear the input field
                              }}
                              initialArray={listschoolname} // Assuming this is the state holding the selected school names
                            />
                          </div>
                        </div>
                        <div className="flex flex-wrap gap-2  mt-3">
                          {listschoolname?.map((item, i) => (
                            <label
                              className="flex items-center py-[5px] px-[8px] border bg-[#00AC85] rounded-[4px]"
                              key={i}
                            >
                              <span className="text-[#fff] text-[14px] font-normal not-italic">
                                {item}
                              </span>
                              <span
                                onClick={() => {
                                  setCurrentPage(1);
                                  setListschoolname([
                                    ...listschoolname.filter((i) => i !== item),
                                  ]);
                                }}
                              >
                                <svg
                                  className="ml-[4px] cursor-pointer"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path
                                    d="M4.66797 4.66669L11.3346 11.3334"
                                    stroke="white"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M4.66927 11.3334L11.3359 4.66669"
                                    stroke="white"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </span>
                            </label>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-end mt-5">
              <div className="flex flex-row">
                <button
                  onClick={handleClear}
                  className="mr-2 border font-semibold border-gray-500 px-6 py-2 rounded-md text-gray-700 hover:text-white hover:bg-gray-200 flex items-center"
                >
                  <span className="text-gray-700">Clear</span>
                  <MdDelete className="ml-1 text-gray-700 text-lg" />
                </button>
              </div>
              <div className="flex flex-row">
                <button
                  onClick={() => {
                    handleFilteredSubmit();
                    // setAllowCloseModal(false);
                  }}
                  className="bg-blue-800 flex flex-rowfont-semibold text-white px-5 py-2 rounded-md hover:bg-blue-900"
                >
                  <span className="font-semibold">Apply</span>
                  <IoSend className="text-white ml-[6px]  mt-[3px] text-[16px]" />
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default FilterModal;
