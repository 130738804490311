import React, { useState, useEffect } from "react";
import { getTypeColor } from "../../../components/recruiter/createJob2/TypesButton";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import * as d3 from "d3";
import SvgIcon5 from "../../../components/recruiter/candidate/icons/SvgIcon5";
import SvgIcon6 from "../../../components/recruiter/candidate/icons/SvgIcon6";
import InitialsLogo from "../../../components/all/InitialsLogo";
import moment from "moment/moment";
import ProgressBar from "../../../components/analytics/ProgressBar";

function ExpandableText({ children, maxChar = 150, classes }) {
  const [expand, setExpand] = useState(false);

  useEffect(() => {}, [expand]);
  return (
    children && (
      <p className={classes}>
        {children?.length <= maxChar ? (
          children
        ) : expand ? (
          <>
            <span>{children}</span>
            <button
              className="ml-2 p-1 rounded hover:bg-gray-300"
              onClick={() => setExpand(false)}
            >
              <svg width="16" height="16" viewBox="0 0 24 24" fill="none">
                <path
                  d="M5 14H10V19M19 10H14V5"
                  stroke="#252E4B"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </>
        ) : (
          <>
            <span>{String(children).substring(0, maxChar)} ...</span>
            <button
              className="ml-2 px-1 rounded hover:bg-gray-300"
              onClick={() => setExpand(true)}
            >
              <svg width="16" height="16" viewBox="0 0 24 24" fill="none">
                <path
                  d="M10 19H5V14M14 5H19V10"
                  stroke="#252E4B"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </>
        )}
      </p>
    )
  );
}

const DetailModal = ({ profile, setShowCandidate }) => {
  const [sortedExperience, setSortedExperience] = useState([]);
  const [sortedEducation, setSortedEducation] = useState([]);
  const [expShowAll, setExpShowAll] = useState(false);
  const [eduShowAll, setEduShowAll] = useState(false);

  useEffect(() => {
    if (profile?.experience?.length > 0) {
      const sortedExp = [...profile.experience].sort(
        (a, b) => new Date(b.start) - new Date(a.start),
      );
      setSortedExperience(sortedExp);
    }
    if (profile?.education?.length > 0) {
      const sortedEdu = [...profile.education].sort(
        (a, b) => new Date(b.start) - new Date(a.start),
      );
      setSortedEducation(sortedEdu);
    }
  }, [profile]);

  const displayedExperience = expShowAll
    ? sortedExperience
    : sortedExperience.slice(0, 5);
  const displayedEducation = eduShowAll
    ? sortedEducation
    : sortedEducation.slice(0, 5);

  const [width, setWidth] = useState(500);
  console.log("Data in the profile is : ", profile);
  // console.log("Data in the profileDATA  is : ", profileData);

  const [activeTab, setActiveTab] = useState("Details");
  const [activeTabName, setActiveTabName] = useState("Details");
  const checkLabel = (label1, label2) =>
    label1 ? label1 : label2 ? label2 : null;

  useEffect(() => {
    window.addEventListener("resize", setChartWidth);
    setChartWidth();
  }, [profile]);

  function setChartWidth() {
    try {
      setTimeout(() => {
        setWidth(
          Math.floor(
            d3.select("#candidateAnalytics").node().getBoundingClientRect()
              .width,
          ) ?? 500,
        );
      }, 500);
    } catch (err) {
      console.error(err);
      setChartWidth(500);
    }
  }

  return (
    <section
      id="candidateDrawer"
      className="relative block z-[200] w-screen"
      aria-labelledby="slide-over-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-black bg-opacity-25 transition-opacity" />
      <div className="pointer-events-none fixed inset-y-0 right-0 flex w-[88%] max-w-full pl-10">
        <div className="pointer-events-auto relative w-full">
          <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-16 sm:pr-4">
            <button
              onClick={() => setShowCandidate(false)}
              type="button"
              className="relative rounded-full p-4 bg-white text-gray-800 group focus:outline-none focus:ring-2 focus:ring-white"
            >
              <svg
                className="h-6 w-6 group-hover:stroke-blue"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="flex h-full flex-col overflow-y-auto bg-white pt-6 shadow-xl">
            <div
              id="topBanner"
              className="w-full relative px-6 border-t-0 border-x-0 border-b border-[#e1e1ee] border-solid"
            >
              <div className="relative flex justify-start items-start flex-row gap-4 max-w-full w-full">
                <InitialsLogo
                  width="56px"
                  height="56px"
                  fontSize="24px"
                  styleclassName="block rounded-xl border-[none]"
                  str={profile?.first_name}
                />
                <div className="relative grow flex flex-col flex-wrap max-w-full">
                  <span className="relative text-lg font-medium text-[#252e4b]">
                    {profile?.first_name}{" "}
                    {profile?.last_name?.length ? profile?.last_name : ""}
                  </span>
                  {profile?.title && profile?.title !== "N/A" && (
                    <span className="text-sm overflow-ellipsis text-[#5f6989] mt-1.5">
                      {profile?.title}
                    </span>
                  )}
                </div>
              </div>
              {(profile?.linkedin?.length > 0 ||
                profile?.email?.length > 0 ||
                profile?.github?.length > 0) && (
                <div className="flex justify-start items-stretch flex-row gap-2.5 h-8 mt-2">
                  {profile?.linkedin && (
                    <a
                      className="btn-sec w-8 h-8 px-1"
                      href={profile?.linkedin}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-4 h-4"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M4.35313 3.55556C4.35295 3.89233 4.2138 4.21523 3.96629 4.45324C3.71878 4.69125 3.38318 4.82487 3.03333 4.8247C2.68347 4.82453 2.34801 4.69059 2.10075 4.45234C1.85348 4.21409 1.71467 3.89106 1.71484 3.55429C1.71502 3.21753 1.85417 2.89462 2.10168 2.65661C2.34919 2.41861 2.68479 2.28499 3.03465 2.28516C3.3845 2.28532 3.71996 2.41927 3.96723 2.65751C4.21449 2.89576 4.3533 3.2188 4.35313 3.55556ZM4.3927 5.76496H1.75442V13.7137H4.3927V5.76496ZM8.56119 5.76496H5.9361V13.7137H8.53481V9.54253C8.53481 7.21885 11.681 7.00299 11.681 9.54253V13.7137H14.2863V8.67909C14.2863 4.76184 9.6297 4.90787 8.53481 6.83157L8.56119 5.76496Z"
                          fill="#5F6989"
                        />
                      </svg>
                    </a>
                  )}
                  {profile?.github?.length > 0 && (
                    <a href={profile?.github[0]}>
                      <SvgIcon5 className="w-8 h-8" />
                    </a>
                  )}
                  {profile?.email?.length > 0 && (
                    <a href={`mailto:${profile?.email[0]}`}>
                      <SvgIcon6 className="w-8 h-8" />
                    </a>
                  )}
                </div>
              )}
              <div className="flex justify-start items-center flex-row gap-6 mt-4">
                <button
                  onClick={() => {
                    setActiveTab("Details");
                    setActiveTabName("Details");
                  }}
                  data-testid="details"
                  className={`text-base font-medium pt-2 pb-3.5 border-t-0 border-b-2 border-x-0 ${
                    activeTabName === "Details"
                      ? "border-[#1369e9] text-[#16182f]"
                      : "border-transparent text-[#252e4b]"
                  } border-solid hover:border-blue-600`}
                >
                  Details
                </button>
                {profile?.resume_link && (
                  <button
                    onClick={() => {
                      setActiveTab("Resume");
                      setActiveTabName("Resume");
                    }}
                    data-testid="resume"
                    className={` text-base font-medium pt-2 pb-3.5 border-t-0 border-b-2 border-x-0 ${
                      activeTabName === "Resume"
                        ? "border-[#1369e9] text-[#16182f]"
                        : "border-transparent text-[#252e4b]"
                    } border-solid hover:border-blue-600`}
                  >
                    Resume
                  </button>
                )}
              </div>
            </div>
            {activeTab === "Details" ? (
              !profile?.about &&
              profile?.experience?.length === 0 &&
              profile?.education?.length === 0 &&
              profile?.skills?.length === 0 ? (
                <div className="w-full h-screen flex justify-center items-center text-[#5f6989] text-base font-medium">
                  No Data For Details
                </div>
              ) : (
                <section
                  id="profile-section"
                  className="w-full p-4 grow mb-4 overflow-y-auto md:flex md:gap-4 relative"
                >
                  <div
                    className={`${profile?.skills?.length > 0 ? "md:w-7/12 shrink" : null} w-full`}
                  >
                    {/* About section */}
                    {profile?.about && (
                      <div className="flex justify-start items-stretch flex-col mb-4">
                        <h2 className="text-base font-medium text-[#16182f]">
                          About
                        </h2>
                        <p className="max-w-[396px] text-sm leading-[21px] text-[#5f6989] mt-3">
                          {profile?.about}
                        </p>
                      </div>
                    )}

                    {/* Work experience section */}
                    {profile?.experience?.length > 0 ? (
                      <div className="flex justify-start items-stretch flex-col mb-6 gap-5">
                        <h2 className="text-base font-medium text-[#16182f]">
                          Work Experience
                        </h2>
                        {/* Loop starts */}
                        {displayedExperience.map((experience, i) => (
                          <div
                            className="grid grid-cols-[50px_1fr] gap-x-4 gap-y-1"
                            key={"experience" + i}
                          >
                            <InitialsLogo
                              width="50px"
                              height="50px"
                              fontSize="24px"
                              styleClass="rounded-xl border border-[#e1e1ee] row-span-4"
                              str={experience?.company}
                            />
                            <p className="col-start-2 text-base font-semibold text-[#252e4b]">
                              {experience?.position}
                            </p>
                            <p className="col-start-2 text-sm text-[#1369e9]">
                              {experience?.company}
                            </p>
                            <div className="w-full col-start-2 flex flex-row gap-1">
                              <p className="text-sm text-[#252e4b]">
                                {moment(experience?.start).format("MMM YYYY")} -{" "}
                                {experience?.ongoing
                                  ? "Present"
                                  : moment(experience?.end).format("MMM YYYY")}
                              </p>
                            </div>
                            <ExpandableText
                              maxChar={150}
                              classes="col-start-2 text-sm leading-[22px] text-[#5f6989]"
                            >
                              {experience?.description}
                            </ExpandableText>
                          </div>
                        ))}
                        {profile?.experience?.length > 5 &&
                          (expShowAll ? (
                            <button onClick={() => setExpShowAll(false)}>
                              Collapse all
                            </button>
                          ) : (
                            <button onClick={() => setExpShowAll(true)}>
                              Show all
                            </button>
                          ))}
                      </div>
                    ) : null}

                    {/* Education section */}
                    {profile?.education?.length > 0 ? (
                      <div className="flex justify-start items-stretch flex-col">
                        <h2 className="text-base font-medium text-[#16182f]">
                          Education
                        </h2>
                        {displayedEducation.map((education, i) => (
                          <div
                            className="grid grid-cols-[50px_1fr] gap-x-2 gap-y-1 mt-3"
                            key={"education" + i}
                          >
                            <InitialsLogo
                              width="50px"
                              height="50px"
                              fontSize="24px"
                              styleClass="rounded-xl border border-[#e1e1ee] row-span-4"
                              str={education?.school}
                            />
                            <p className="col-start-2 text-base font-semibold text-[#252e4b]">
                              {education?.degree}
                            </p>
                            <p className="col-start-2 text-sm text-[#1369e9]">
                              {education?.school}
                            </p>
                            <p className="w-full col-start-2 flex flex-row text-sm text-[#252e4b]">
                              {moment(education?.start).format("MMM YYYY")}
                            </p>
                            <ExpandableText
                              maxChar={150}
                              classes="col-start-2 text-sm leading-[22px] text-[#5f6989]"
                            >
                              {education?.description}
                            </ExpandableText>
                          </div>
                        ))}
                        {profile?.education?.length > 5 &&
                          (eduShowAll ? (
                            <button onClick={() => setEduShowAll(false)}>
                              Collapse all
                            </button>
                          ) : (
                            <button onClick={() => setEduShowAll(true)}>
                              Show all
                            </button>
                          ))}
                      </div>
                    ) : null}
                  </div>
                  {profile?.skills?.length > 0 && (
                    <div
                      className="w-full md:w-5/12 mb-4 pb-2 relative"
                      id="candidateAnalytics"
                    >
                      <h4 className="text-[#16182F] text-[16px] not-italic font-medium">
                        Skills
                      </h4>
                      <div className="w-full">
                        {profile?.skills?.length > 0 &&
                          profile?.skills?.map((data, it) => (
                            <div className="w-full mb-4" key={it}>
                              <h4 className="text-gray-700 text-sm not-italic font-medium mb-3">
                                {checkLabel(data?.criteria, data?.label)}
                              </h4>
                              <div className="w-full border p-2 border-[#E1E1EE] rounded-xl relative">
                                {checkLabel(data?.skills, data?.keySkills)?.map(
                                  (skill, i) => (
                                    <div
                                      className=" flex last-of-type:pb-0 pb-2 items-center ml-0"
                                      key={i}
                                    >
                                      <span
                                        className="w-[45%] text-[#252E4B] text-[14px] not-italic leading-7 truncate overflow-hidden  mr-[10px]"
                                        onMouseOver={(e) => {
                                          var overflowed =
                                            e.target.scrollWidth >
                                            e.target.clientWidth;
                                          e.target.title = overflowed
                                            ? e.target.textContent
                                            : "";
                                        }}
                                        onMouseOut={(e) =>
                                          (e.target.title = "")
                                        }
                                      >
                                        {skill?.skills}
                                      </span>
                                      <span className="w-[45%]  mr-[10px]">
                                        <ProgressBar
                                          id={"skills-bar" + it + i}
                                          style_classes="w-full"
                                          data={Math.abs(skill?.score)}
                                          height={8}
                                          barColor={
                                            getTypeColor(skill?.type) ?? "teal"
                                          }
                                          bgColor="#EFF0F2"
                                          width={width * 0.45 - 10}
                                          fromRight={skill?.score < 0}
                                        />
                                      </span>
                                      <span style={{ width: 20 }}>
                                        {Math.floor(skill?.score)}
                                      </span>
                                    </div>
                                  ),
                                )}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </section>
              )
            ) : (
              <section className="flex h-full flex-grow ">
                <div className="w-full h-full p-4 grow">
                  <iframe
                    className="w-full h-full"
                    title="PDF Viewer"
                    src={profile?.resume_link}
                    type="application/pdf"
                  />
                </div>
              </section>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default DetailModal;

// {profile?.experience?.length > 0 &&
//   (() => {
//     const filteredExperience =
//       profile.experience.details.filter(
//         (exp) =>
//           !(exp.position === "N/A" && exp.company === "N/A"),
//       );
//     if (filteredExperience.length === 0) return null;

//     return (
//       <div className="flex justify-start items-stretch flex-col mb-6 gap-5">
//         <h2 className="text-base font-medium text-[#16182f]">
//           Work Experience
//         </h2>
//         {/* Loop starts */}
//         {filteredExperience
//           .slice(
//             0,
//             expShowAll ? filteredExperience.length : 5,
//           )
//           .map((exp, i) => (
//             <div
//               className="grid grid-cols-[50px_1fr] gap-x-4 gap-y-1"
//               key={"experience" + i}
//             >
//               <InitialsLogo
//                 width="50px"
//                 height="50px"
//                 fontSize="24px"
//                 styleClass="rounded-xl border border-[#e1e1ee] row-span-4"
//                 str={exp?.company}
//               />
//               {exp.position !== "N/A" && (
//                 <p className="col-start-2 text-base font-semibold text-[#252e4b]">
//                   {exp?.position}
//                 </p>
//               )}
//               {exp.company !== "N/A" && (
//                 <p className="col-start-2 text-sm text-[#1369e9]">
//                   {exp?.company}
//                 </p>
//               )}
//             </div>
//           ))}
//         {filteredExperience.length > 5 &&
//           (expShowAll ? (
//             <button onClick={() => setExpShowAll(false)}>
//               Collapse
//             </button>
//           ) : (
//             <button onClick={() => setExpShowAll(true)}>
//               Show all
//             </button>
//           ))}
//       </div>
//     );
//   })()}
