import React, { useEffect, useState } from "react";
import SelectBox from "../../layouts/SelectBox";
import AvailabilityForm from "./AvavilabilityCheckbyDate";
import Header from "../jobPost/JobPostHeader";
import Footer from "../jobPost/JobPostFooter";
import { ScaleLoader } from "react-spinners";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import moment from "moment-timezone";
import TimezoneSelect from "../../components/recruiter/candidate/interview/TimezoneSelect";
const core = require("../../lib/core");

function Avavilabiltycheck() {
  const { userid } = useParams();
  const [timeZone, setTimeZone] = useState("");
  const [UserName, setUsername] = useState("");
  const [isloading, setisloading] = useState(true);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [availabilities, setAvailabilities] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [hasExistingAvailability, setHasExistingAvailability] = useState(false);
  const [Submissionwarning, SetSubmissionwarning] = useState("");
  const [status, setstatus] = useState(false);
  function getNextSevenDays() {
    const days = [];
    for (let i = 0; i < 1; i++) {
      const date = new Date();
      date.setDate(date.getDate() + i);
      days.push(date.toISOString());
    }
    return days;
  }

  const [availability, setAvailability] = useState(
    getNextSevenDays().map((date) => ({
      date,
      slots: [{ start_time: "", end_time: "" }],
    })),
  );

  useEffect(() => {
    fetchAvailability();
    fetchUsername();
  }, [isEditing]);
  // useEffect(() => {
  //   if (availabilities.length > 0) {
  //     setHasExistingAvailability(true);
  //     setIsEditing(true);
  //   } else {
  //     setHasExistingAvailability(false);
  //     setIsEditing(false);
  //   }
  // }, [availabilities]);

  const check = (availdata) => {
    availdata.map((item) => {
      console.log("item Data", item.start_time, item.end_time);
      if (item.start_time == undefined || item.end_time == undefined) {
        return false;
      }
    });

    return true;
  };

  const fetchAvailability = async () => {
    try {
      setisloading(true);
      const json = await core.API(
        core.API_METHODS.GET,
        core.SCHEDULING_API.GET_CAN_AVAILABILITY + userid,
        1,
      );
      if (json?.data && json.data.availabilityData.length > 0) {
        console.log(json.data.availabilityData);
        const formattedAvailability = json.data.availabilityData.map(
          (dateObj) => ({
            date: new Date(dateObj.date).toISOString(),
            slots: dateObj.slots.map((slot) => ({
              start_time: moment
                .utc(slot.start_time)
                .tz(json.data.timezone)
                .format("hh:mm A"),
              end_time: moment
                .utc(slot.end_time)
                .tz(json.data.timezone)
                .format("hh:mm A"),
            })),
          }),
        );
        setHasExistingAvailability(true);
        // console.log("Existing Data", formattedAvailability, "Exists", hasExistingAvailability)
        setAvailabilities(formattedAvailability);
        setTimeZone(json?.data?.timezone);
      } else {
        setHasExistingAvailability(false);
        setAvailabilities(
          getNextSevenDays().map((date) => ({
            date,
            slots: [{ start_time: "", end_time: "" }],
          })),
        );
      }
      setisloading(false);
    } catch (error) {
      console.error(error);
      setisloading(false);
    }
  };

  const handleEditAvailability = () => {
    setSubmitStatus(false);
    setIsEditing(true);
  };
  const fetchUsername = async () => {
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.SCHEDULING_API.GET_CANDIDATE + userid,
        1,
      );

      if (json?.data) {
        setUsername(json.data.name);
      } else {
        SetSubmissionwarning("Error fetching details");
        setTimeout(() => {
          SetSubmissionwarning("");
        }, 2000);
        // toast("Error fetching user details");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const convertTimeToISOString = (time, date, timezone) => {
    const timeFormats = ["h:mm A", "HH:mm"];
    const momentObj = moment
      .tz(
        `${moment(date).format("YYYY-MM-DD")} ${time}`,
        "YYYY-MM-DD h:mm A",
        timeZone,
      )
      .utc()
      .format();

    return momentObj;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setisloading(true);
    const userId = userid;

    // const dataToSubmit =
    //   isEditing || hasExistingAvailability ? availabilities : availability;
    const hasEmptySlots = availabilities.some((dateObj) =>
      dateObj.slots.some((slot) => !slot.start_time || !slot.end_time),
    );

    if (hasEmptySlots) {
      setisloading(false);
      SetSubmissionwarning("Please fill the time slots");
      setTimeout(() => {
        SetSubmissionwarning("");
      }, 3000);
      // toast.warn("Please fill all time slots ");
      return;
    }

    if (status) {
      setisloading(false);
      SetSubmissionwarning("Please check time slots");
      setTimeout(() => {
        SetSubmissionwarning("");
      }, 3000);
      return;
    }
    const availabilityData = availabilities
      .filter((dateObj) => dateObj.slots.length > 0 && dateObj.date)
      .map((dateObj) => ({
        date: dateObj.date
          ? moment.tz(dateObj.date, timeZone).toISOString()
          : null,
        slots: dateObj.slots
          .filter((slot) => slot.start_time && slot.end_time)
          .map((slot) => ({
            start_time: convertTimeToISOString(
              slot.start_time,
              dateObj.date,
              timeZone,
            ),
            end_time: convertTimeToISOString(
              slot.end_time,
              dateObj.date,
              timeZone,
            ),
          })),
      }));

    // if (!check(availabilityData)) {
    //   setisloading(false);
    //   // toast.warn("Fill the Time Slots");
    //   SetSubmissionwarning("Please fill the time slots");
    //   setTimeout(() => {
    //     SetSubmissionwarning("");
    //   }, 3000);
    //   return;
    // }
    // const AvailData = availabilityData.filter(
    //   (item) => item.date !== null && item.slots.length > 0,
    // );
    console.log("availData", availabilities);
    if (availabilityData.length == 0) {
      setisloading(false);
      SetSubmissionwarning("Submit atleat one times slot");
      setTimeout(() => {
        SetSubmissionwarning("");
      }, 3000);
      // toast("Please Fill the time slots");
      return;
    }

    const requestData = {
      user: userId,
      availabilityData: availabilityData,
      // matchProfile: matchProfileId, // dont send this in request data
      timezone: timeZone,
    };

    console.log("Request Data:", requestData);
    try {
      const json = await core.API(
        core.API_METHODS.POST,
        core.SCHEDULING_API.POST_CANDIDATE_AVAILABILITY,
        1,
        requestData,
      );
      if (json?.data) {
        if (json.code == 201) {
          console.log(json.message);
          setisloading(false);
          setSubmitStatus(true);
          if (isEditing) {
            setIsEditing(false);
          }
        } else {
          toast.error(json.message);
          setisloading(false);
        }
      } else {
        console.error("Error:", error);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="font-sans h-full flex flex-col overflow-auto items-center bg-[#F3F5F9]">
      <div className="bg-white w-full">
        <div className="flex justify-center items-center md:mx-16 w-11/12 sm:mx-0 sm:px-0">
          <Header
            orgDetails={{
              domain_url: "https://www.sproutsai.com",
              logo: "https://sproutsweb-assets.s3.amazonaws.com/organization/sproutsai-d107a.svg",
            }}
            fixHeader="https://sproutsweb-assets.s3.amazonaws.com/organization/sproutsai-d107a.svg"
          />
        </div>
      </div>

      <div className="w-11/12 flex justify-center mt-[20px]">
        {!submitStatus ? (
          <div className="w-11/12">
            <form onSubmit={handleSubmit} className="space-y-4 mb-10">
              <div className="flex flex-col md:flex-row md:justify-between gap-4 ">
                <div className="flex flex-col">
                  <span className="text-[16px] md:text-[22px] font-semibold">
                    Candidate availability
                  </span>
                  <span className="text-[#252E4B] text-sm font-normal">
                    Hello, {UserName} 👋
                  </span>
                </div>
                <div className="flex flex-col items-end">
                  <div className="flex items-center justify-end gap-4 w-full md:w-[120%] h-10">
                    {/* <span className="rounded-l-lg flex px-4 items-center  shadow-[0px_2px_0px_rgba(0,0,0,0.04)] text-xs md:text-sm h-[40px] bg-gray-400 text-gray-800 not-italic font-normal overflow-hidden">
                        Select Time Zone
                      </span>
                      <SelectBox
                        value={timeZone}
                        onChange={(e) => setTimeZone(e.target.value)}
                        options={timeZones}
                        size={"medium"}
                        customClass={"rounded-l-none text-xs md:text-md"}
                      /> */}
                    <TimezoneSelect
                      Timezone={timeZone}
                      setTimezone={setTimeZone}
                    />

                    <button
                      type="submit"
                      className="bg-[#1369E9] text-white px-4 md:px-8 py-2 rounded"
                    >
                      {hasExistingAvailability ? "Update" : "Submit"}
                    </button>
                  </div>
                  <div>
                    {Submissionwarning && (
                      <div className="text-red-900 text-xs md:text-sm mt-2">
                        {Submissionwarning}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex flex-col-reverse md:grid md:grid-cols-5 gap-8 overflow-y-auto w-full">
                <div className="rounded-[12px] bg-white shadow-PipelineCardsShadow py-[18px] col-span-6 p-[28px] h-[400px] overflow-y-auto">
                  {isloading ? (
                    <div className="mx-auto md:w-2/3 py-10 flex justify-center items-center">
                      <ScaleLoader height={40} color="#1369E9" loading={true} />
                    </div>
                  ) : (
                    <AvailabilityForm
                      availability={availabilities}
                      setAvailability={setAvailabilities}
                      timeZone={timeZone}
                      setTimeZone={setTimeZone}
                      isEditing={isEditing}
                      status={status}
                      changeStatus={setstatus}
                    />
                  )}
                </div>

                {/* <div className="col-span-1 rounded-[12px] bg-white shadow-PipelineCardsShadow p-[18px]">
                  <label className="text-xs md:text-sm text-gray-800 not-italic font-normal overflow-hidden">
                    Select Time Zone
                  </label>
                  <SelectBox
                    value={timeZone}
                    onChange={(e) => setTimeZone(e.target.value)}
                    options={timeZones}
                    size={"small"}
                  />
                </div> */}
              </div>
            </form>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center gap-8">
            <span className="text-[green] text-[18px] font-semibold">
              Submitted successfully
            </span>
            <button
              className="btn-md btn btn-primary"
              onClick={handleEditAvailability}
            >
              Edit Availability
            </button>
          </div>
        )}
      </div>

      <div className="md:absolute md:bottom-0 w-full md:mt-10 mt-auto">
        <Footer />
      </div>
    </div>
  );
}

export default Avavilabiltycheck;
