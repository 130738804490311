import React from "react";

export default function InterviewTranscript({ transcript }) {
  return (
    <div className="my-4">
      <ul>
        {transcript.map((entry, index) => (
          <li key={index} className="mb-4">
            <p>
              <strong>
                {entry.name.charAt(0).toUpperCase() + entry.name.slice(1)}
              </strong>{" "}
              : {entry.content}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
}
