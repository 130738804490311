import React, { useContext, useEffect, useState } from "react";
import General from "./General";
import { FaRegNoteSticky } from "react-icons/fa6";
import Password from "./Password";
import Messaging from "./Messaging";
import Notification from "./Notification";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HeaderNew from "../../../components/all/header/HeaderNew";
import JobSidebar from "../../../components/all/JobSidebar";
import { useNavigate } from "react-router-dom";
import Guidelines from "./Guidelines";
import JobIntegrationCard from "./JobIntegrationCard";
import { ThemeProvider } from "../../../context/ThemeContext";

const core = require("../../../lib/core");

function NewSetting() {
  const [isIntegrationButtonClicked, setIsIntegrationButtonClicked] =
    useState(false);
  const [activeTab, setActiveTab] = useState(
    isIntegrationButtonClicked ? "itegrationCard" : "general",
  );
  const [isIntegrationAction, setIsIntegrationAction] = useState(false);

  const updateActiveTab = (tab, isIntegrationToggle = false) => {
    setActiveTab(tab);
    setIsIntegrationAction(isIntegrationToggle);
    if (tab === "itegrationCard") {
      setIsIntegrationButtonClicked(true);
    }
  };

  useEffect(() => {
    return () => {
      setIsIntegrationButtonClicked(false);
    };
  }, []);

  return (
    // <ThemeProvider>

    <div
      className="w-screen h-screen p-0 overflow-hidden"
      id="job_details_page"
    >
      <div className={`flex flex-row w-screen h-screen left-0 relative top-0`}>
        <JobSidebar active="" open={false} hideSidebarItems={true} />
        <div className="w-full">
          <HeaderNew />
          <SettingContent
            activeTab={activeTab}
            setActiveTab={updateActiveTab}
            isIntegrationAction={isIntegrationAction}
            setIsIntegrationButtonClicked={setIsIntegrationButtonClicked}
          />
        </div>
      </div>
    </div>
    // </ThemeProvider>
  );
}

export default NewSetting;

const SettingContent = ({
  activeTab,
  setActiveTab,
  isIntegrationAction,
  setIsIntegrationButtonClicked,
}) => {
  const [dpUrl, setDpUrl] = useState("");
  const { user } = useContext(core.UserContext);

  const [image, setImage] = useState(null);
  const [file, setFile] = useState(null);

  const onFileChange = (e) => {
    setFile(e.target.files[0]);
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);

    setTimeout(async function () {
      let formData = new FormData();
      formData.append("file", file);
      const token = await core.getData(core.data.ACCESS_TOKEN);
      fetch(core.USER_API.POST_UPLOAD_USER_PIC, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          uid: user?.id,
        },
        body: formData,
      })
        .then((res) => {
          console.log(formData);
          res.json();
          toast.success("Profile pic uploaded successfully", {
            position: "bottom-right",
            autoClose: 2000,
          });
        })
        .then((data) => {
          if (data.error) {
          } else {
            toast.error(data.error);
          }
        });
    }, 1000);
  };

  const nav = useNavigate();
  return (
    // <ThemeProvider>

    <div className=" recruiter" id="setting">
      {/* <Header category="recruiter"showOnlyLogo={false} menu={<Menu />}/>
  <SideBar /> */}
      {/* <button onClick={submitFileData}>save</button> */}
      <main className="main-content col-xs-12 no-padding ml-[-66px]">
        <div className="col-xs-3 no-padding  left-sec ">
          <button
            className={`${activeTab === "general" ? "bg-[#F4F8FF]" : ""} px-[12px] rounded-[8px]`}
            onClick={(e) => setActiveTab("general")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M2.17969 16.6667C2.17969 16.6667 4.58529 12.0833 10.0015 12.0833C15.8353 12.0833 17.8215 16.6667 17.8215 16.6667M10.0015 10C10.6645 10 11.3004 9.73661 11.7693 9.26777C12.2381 8.79893 12.5015 8.16304 12.5015 7.5C12.5015 6.83696 12.2381 6.20107 11.7693 5.73223C11.3004 5.26339 10.6645 5 10.0015 5C9.33846 5 8.70258 5.26339 8.23374 5.73223C7.7649 6.20107 7.5015 6.83696 7.5015 7.5C7.5015 8.16304 7.7649 8.79893 8.23374 9.26777C8.70258 9.73661 9.33846 10 10.0015 10Z"
                stroke="#001B4B"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Account info
          </button>
          <button
            className={`${activeTab === "password" ? "bg-[#F4F8FF]" : ""} px-[12px] rounded-[8px]`}
            onClick={(e) => setActiveTab("password")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M10 8.75C9.47605 8.75203 8.97024 8.94206 8.57456 9.28551C8.17887 9.62897 7.91961 10.103 7.84392 10.6215C7.76824 11.1399 7.88115 11.6683 8.16214 12.1106C8.44312 12.5528 8.8735 12.8795 9.375 13.0312V14.375C9.375 14.5408 9.44085 14.6997 9.55806 14.8169C9.67527 14.9342 9.83424 15 10 15C10.1658 15 10.3247 14.9342 10.4419 14.8169C10.5592 14.6997 10.625 14.5408 10.625 14.375V13.0312C11.1265 12.8795 11.5569 12.5528 11.8379 12.1106C12.1188 11.6683 12.2318 11.1399 12.1561 10.6215C12.0804 10.103 11.8211 9.62897 11.4254 9.28551C11.0298 8.94206 10.5239 8.75203 10 8.75ZM10 11.875C9.81458 11.875 9.63332 11.82 9.47915 11.717C9.32498 11.614 9.20482 11.4676 9.13386 11.2963C9.06291 11.125 9.04434 10.9365 9.08051 10.7546C9.11669 10.5727 9.20598 10.4057 9.33709 10.2746C9.4682 10.1435 9.63525 10.0542 9.8171 10.018C9.99896 9.98184 10.1875 10.0004 10.3588 10.0714C10.5301 10.1423 10.6765 10.2625 10.7795 10.4167C10.8825 10.5708 10.9375 10.7521 10.9375 10.9375C10.9375 11.1861 10.8387 11.4246 10.6629 11.6004C10.4871 11.7762 10.2486 11.875 10 11.875ZM16.25 6.25H13.4375V4.0625C13.4375 3.15082 13.0753 2.27648 12.4307 1.63182C11.786 0.987164 10.9117 0.625 10 0.625C9.08832 0.625 8.21398 0.987164 7.56932 1.63182C6.92466 2.27648 6.5625 3.15082 6.5625 4.0625V6.25H3.75C3.41848 6.25 3.10054 6.3817 2.86612 6.61612C2.6317 6.85054 2.5 7.16848 2.5 7.5V16.25C2.5 16.5815 2.6317 16.8995 2.86612 17.1339C3.10054 17.3683 3.41848 17.5 3.75 17.5H16.25C16.5815 17.5 16.8995 17.3683 17.1339 17.1339C17.3683 16.8995 17.5 16.5815 17.5 16.25V7.5C17.5 7.16848 17.3683 6.85054 17.1339 6.61612C16.8995 6.3817 16.5815 6.25 16.25 6.25ZM7.8125 4.0625C7.8125 3.48234 8.04297 2.92594 8.4532 2.5157C8.86344 2.10547 9.41984 1.875 10 1.875C10.5802 1.875 11.1366 2.10547 11.5468 2.5157C11.957 2.92594 12.1875 3.48234 12.1875 4.0625V6.25H7.8125V4.0625ZM16.25 16.25H3.75V7.5H16.25V16.25Z"
                fill="#001B4B"
              />
            </svg>
            Change password
          </button>
          <button
            className={`${activeTab === "notification" ? "bg-[#F4F8FF]" : ""} px-[12px] rounded-[8px]`}
            onClick={(e) => setActiveTab("notification")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M12.499 15.8327C12.499 16.4957 12.2356 17.1316 11.7668 17.6005C11.2979 18.0693 10.662 18.3327 9.99899 18.3327C9.33595 18.3327 8.70007 18.0693 8.23123 17.6005C7.76238 17.1316 7.49899 16.4957 7.49899 15.8327M10.5998 4.16853L9.37816 4.16603C6.59149 4.15936 4.17233 6.42353 4.15316 9.16603V12.3244C4.15316 12.9827 4.06983 13.6252 3.71066 14.1727L3.47149 14.5377C3.10733 15.091 3.49899 15.8327 4.15316 15.8327H15.8448C16.499 15.8327 16.8898 15.091 16.5265 14.5377L16.2873 14.1727C15.929 13.6252 15.8448 12.9819 15.8448 12.3235V9.16686C15.8115 6.42353 13.3865 4.1752 10.5998 4.16853V4.16853Z"
                stroke="#001B4B"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.9987 1.66602C10.4407 1.66602 10.8646 1.84161 11.1772 2.15417C11.4898 2.46673 11.6654 2.89065 11.6654 3.33268V4.16602H8.33203V3.33268C8.33203 2.89065 8.50763 2.46673 8.82019 2.15417C9.13275 1.84161 9.55667 1.66602 9.9987 1.66602Z"
                stroke="#001B4B"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Notification
          </button>
          <button
            className={`${activeTab === "itegrationCard" ? "bg-[#F4F8FF]" : ""} px-[12px] rounded-[8px]`}
            onClick={(e) => setActiveTab("itegrationCard")}
          >
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M12.499 15.8327C12.499 16.4957 12.2356 17.1316 11.7668 17.6005C11.2979 18.0693 10.662 18.3327 9.99899 18.3327C9.33595 18.3327 8.70007 18.0693 8.23123 17.6005C7.76238 17.1316 7.49899 16.4957 7.49899 15.8327M10.5998 4.16853L9.37816 4.16603C6.59149 4.15936 4.17233 6.42353 4.15316 9.16603V12.3244C4.15316 12.9827 4.06983 13.6252 3.71066 14.1727L3.47149 14.5377C3.10733 15.091 3.49899 15.8327 4.15316 15.8327H15.8448C16.499 15.8327 16.8898 15.091 16.5265 14.5377L16.2873 14.1727C15.929 13.6252 15.8448 12.9819 15.8448 12.3235V9.16686C15.8115 6.42353 13.3865 4.1752 10.5998 4.16853V4.16853Z"
                stroke="#001B4B"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.9987 1.66602C10.4407 1.66602 10.8646 1.84161 11.1772 2.15417C11.4898 2.46673 11.6654 2.89065 11.6654 3.33268V4.16602H8.33203V3.33268C8.33203 2.89065 8.50763 2.46673 8.82019 2.15417C9.13275 1.84161 9.55667 1.66602 9.9987 1.66602Z"
                stroke="#001B4B"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg> */}
            <FaRegNoteSticky size={18} color="#001B4B" />
            Integration
          </button>

          {user?.account !== 1 && (
            <button
              className={`${activeTab === "message" ? "bg-[#F4F8FF]" : ""} px-[12px] rounded-[8px]`}
              onClick={(e) => setActiveTab("message")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M10.0013 10.0007C10.4615 10.0007 10.8346 9.62755 10.8346 9.16732C10.8346 8.70708 10.4615 8.33398 10.0013 8.33398C9.54106 8.33398 9.16797 8.70708 9.16797 9.16732C9.16797 9.62755 9.54106 10.0007 10.0013 10.0007Z"
                  fill="#001B4B"
                />
                <path
                  d="M13.3333 10.0007C13.7936 10.0007 14.1667 9.62755 14.1667 9.16732C14.1667 8.70708 13.7936 8.33398 13.3333 8.33398C12.8731 8.33398 12.5 8.70708 12.5 9.16732C12.5 9.62755 12.8731 10.0007 13.3333 10.0007Z"
                  fill="#001B4B"
                />
                <path
                  d="M6.66536 10.0007C7.1256 10.0007 7.4987 9.62755 7.4987 9.16732C7.4987 8.70708 7.1256 8.33398 6.66536 8.33398C6.20513 8.33398 5.83203 8.70708 5.83203 9.16732C5.83203 9.62755 6.20513 10.0007 6.66536 10.0007Z"
                  fill="#001B4B"
                />
                <path
                  d="M15.8346 2.5H4.16797C3.50493 2.5 2.86904 2.76339 2.4002 3.23223C1.93136 3.70107 1.66797 4.33696 1.66797 5V17.5C1.66822 17.6477 1.70771 17.7926 1.78239 17.92C1.85706 18.0474 1.96425 18.1526 2.09297 18.225C2.21752 18.2956 2.35815 18.3329 2.5013 18.3333C2.65087 18.3333 2.79767 18.293 2.9263 18.2167L6.66797 15.95C6.80627 15.8677 6.96546 15.8271 7.1263 15.8333H15.8346C16.4977 15.8333 17.1336 15.5699 17.6024 15.1011C18.0712 14.6323 18.3346 13.9964 18.3346 13.3333V5C18.3346 4.33696 18.0712 3.70107 17.6024 3.23223C17.1336 2.76339 16.4977 2.5 15.8346 2.5ZM16.668 13.3333C16.668 13.5543 16.5802 13.7663 16.4239 13.9226C16.2676 14.0789 16.0556 14.1667 15.8346 14.1667H7.1263C6.67115 14.1663 6.22454 14.2902 5.83464 14.525L3.33464 16.025V5C3.33464 4.77899 3.42243 4.56702 3.57871 4.41074C3.73499 4.25446 3.94695 4.16667 4.16797 4.16667H15.8346C16.0556 4.16667 16.2676 4.25446 16.4239 4.41074C16.5802 4.56702 16.668 4.77899 16.668 5V13.3333Z"
                  fill="#001B4B"
                  stroke="white"
                  strokeWidth="0.4"
                />
              </svg>
              Message
            </button>
          )}
        </div>
        <div className="col-xs-9 right-sec bg-[#F3F5F9] h-[calc(100%-100px)] overflow-y-scroll scrollbar-candidate rounded-[16px] no-padding p-[18px]">
          <span
            onClick={(e) => nav("/")}
            className="flex cursor-pointer items-center mb-[18px] text-[#252E4B] text-[16px] font-normal not-italic"
          >
            <svg
              className="mr-[8px]"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M7.97435 4.9375L2.91602 9.99583L7.97435 15.0542"
                stroke="#252E4B"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M17.0836 10H3.05859"
                stroke="#252E4B"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Back to Dashboard
          </span>

          {activeTab === "general" && (
            <div className="col-xs-12">
              <General setShowTab={setActiveTab} />
            </div>
          )}
          {activeTab === "password" && (
            <div className="col-xs-12 ">
              <Password />
            </div>
          )}
          {activeTab === "notification" && (
            <div className="col-xs-12 ">
              <Notification />
            </div>
          )}
          {(activeTab === "itegrationCard" || isIntegrationAction) && (
            <div className="col-xs-12 ">
              <JobIntegrationCard
                setActiveTab={(tab) => setActiveTab(tab, true)}
                setIsIntegrationButtonClicked={setIsIntegrationButtonClicked}
              />
            </div>
          )}
          {activeTab === "message" && (
            <div className="col-xs-12 ">
              <Messaging />
            </div>
          )}
        </div>
      </main>
    </div>
    // </ThemeProvider>
  );
};
