import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import EditButton from "../../assets/Edit";
import EducationForm from "./forms/Education";
import ExperienceForm from "./forms/Experience";
import LinksForm from "./forms/Links";
import PersonalForm from "./forms/Personal";
import ProjectForm from "./forms/Project";
import ResumeForm from "./forms/Resume";
import Skills from "./forms/Skills";
import { getTypeColor } from "../../recruiter/createJob2/TypesButton";
import ResumeAnalytics from "./ResumeAnalytics";
import Avatar from "../../assets/candidate/Avatar.png";
import ResumeImg from "../../assets/candidate/Resume .png";
import Modal from "../../modal/Modal";
import InitialsLogo from "../../all/InitialsLogo";
import moment from "moment/moment";
import ProgressBar from "../../analytics/ProgressBar";
import { FaLink } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { IoSend } from "react-icons/io5";
import { MdAdd } from "react-icons/md";
import { FaBook } from "react-icons/fa";
import { MdWorkHistory } from "react-icons/md";
import { FaFileCode } from "react-icons/fa6";
// import Skills from './../../recruiter/createJob2/Skills';
const core = require("../../../lib/core");

function ExpandableText({ children, maxChar = 150, classes }) {
  const [expand, setExpand] = useState(false);

  useEffect(() => {}, [expand]);
  return (
    children && (
      <p className={classes}>
        {children?.length <= maxChar ? (
          children
        ) : expand ? (
          <>
            <span>{children}</span>
            <button
              className="ml-2 p-1 rounded hover:bg-gray-300"
              onClick={() => setExpand(false)}
            >
              <svg width="16" height="16" viewBox="0 0 24 24" fill="none">
                <path
                  d="M5 14H10V19M19 10H14V5"
                  stroke="#252E4B"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </>
        ) : (
          <>
            <span>{String(children).substring(0, maxChar)} ...</span>
            <button
              className="ml-2 px-1 rounded hover:bg-gray-300"
              onClick={() => setExpand(true)}
            >
              <svg width="16" height="16" viewBox="0 0 24 24" fill="none">
                <path
                  d="M10 19H5V14M14 5H19V10"
                  stroke="#252E4B"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </>
        )}
      </p>
    )
  );
}

const experience_data = {
  company: "",
  position: "",
  start: "",
  end: "",
  ongoing: "",
  description: "",
};
const education_data = {
  school: "",
  degree: "",
  major: "",
  start: "",
  end: "",
  ongoing: "",
  description: "",
  GPA: "",
};
const project_data = {
  Project_name: "",
  Description: "",
};
export default function Profile() {
  // Var for User
  const { user } = useContext(core.UserContext);
  // Var for Profile data
  const [width, setWidth] = useState(500);
  const [profile, setProfile] = useState();
  const [resumeFile, setResumeFile] = useState("Upload Resume");
  const [resumeFileName, setResumeFileName] = useState("Upload Resume");
  const [resumeBtnName, setResumeBtnName] = useState("Upload Resume");
  const [imgFile, setImgFile] = useState();
  const [img, setImg] = useState(user?.pic);
  const [urls, setUrls] = useState([]);
  const [education, setEducation] = useState();
  const [skill, setSkill] = useState([]);
  const [tool, setTool] = useState([]);
  const [experience, setExperience] = useState();
  const [project, setProject] = useState();

  //  Var for CRUD operation
  const [selection, setSelection] = useState();
  const [formState, setFormState] = useState({});
  const [criteriaSkilArray, setCriteriaSkilArray] = useState([]);
  const [experienceArr, setExperienceArr] = useState([experience_data]);
  const [projectArr, setProjectArr] = useState([project_data]);
  const [educationArr, setEducationArr] = useState([education_data]);
  const [skills, setSkills] = useState([]);

  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  // Determine the number of experiences to display
  const displayedExperiences = showAll
    ? profile?.experience
    : profile?.experience.slice(0, 4);

  const checkLabel = (label1, label2) =>
    label1 ? label1 : label2 ? label2 : null;

  useEffect(() => {
    getProfile();
  }, []);

  async function getProfile() {
    let response = await core.API(
      core.API_METHODS.GET,
      core.CANDIDATE_API.GET_PROFILE_DETAILS,
      1,
      {},
    );
    setProfile(response?.data);
    setSkills(response?.data.skills);
    setFormState(response?.data);
    if (response?.data?.experience.length > 0) {
      setExperienceArr(response?.data?.experience);
    } else {
      setExperienceArr([
        {
          company: "",
          position: "",
          start: "",
          end: "",
          ongoing: "",
          description: "",
        },
      ]);
    }

    if (response?.data?.education.length > 0) {
      setEducationArr(response?.data?.education);
    } else {
      setEducationArr([
        {
          school: "",
          degree: "",
          major: "",
          start: "",
          end: "",
          ongoing: "",
          description: "",
          GPA: "",
        },
      ]);
    }
    if (response?.data?.project.length > 0) {
      setProjectArr(response?.data?.project);
    } else {
      setProjectArr([
        {
          name: "",
          link: "",
          description: "",
        },
      ]);
    }
    // setCriteriaSkilArray(response?.data?.skills);
    setTool(response?.data?.tools);
  }
  function setInput(key, value) {
    setFormState({ ...formState, [key]: value });
  }
  function onFileChange(file) {
    try {
      setResumeBtnName("Loading");
      setResumeFile(file);
      setResumeFileName(file.name);
      setResumeBtnName("Change Resume");
    } catch (err) {
      setResumeBtnName("Upload Resume");
    }
  }

  const [resumeLink, setResumeLink] = useState("");
  const [resumeData, setResumeData] = useState("");

  async function upload_resume() {
    var formData = new FormData();
    formData.append("file", resumeFile);
    const token = await core.getData(core.data.ACCESS_TOKEN);

    await fetch(core.CANDIDATE_API.POST_UPLOAD_RESUME, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        uid: user?.id,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        setResumeLink(data?.data?.link);
      });

    await fetch("https://parser.sproutsai.com/resume_parser", {
      method: "POST",
      body: formData,
      enctype: "multipart/form-data",
    })
      .then((res) => res.json())
      .then((data) => {
        setResumeData(data);
        upload_resume_data(resumeLink, data);
      });
  }

  async function upload_resume_data(resumeLink, resumeData) {
    await core
      .API(core.API_METHODS.POST, core.CANDIDATE_API.POST_UPDATE_RESUME, 1, {
        link: resumeLink,
        resume: resumeData,
      })
      .then((res) => res.json())
      .then((data) => {});
  }

  function onImgChange(file) {
    setImgFile(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImg(reader.result);
    };
    reader.readAsDataURL(file);
  }
  async function upload_picture() {
    let formData = new FormData();
    formData.append("file", imgFile);
    const token = await core.getData(core.data.ACCESS_TOKEN);
    fetch(core.USER_API.POST_UPLOAD_USER_PIC, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        uid: user?.id,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {});
  }
  function setEducationInput(key, value) {
    setEducation({ ...education, [key]: value });
  }
  function setExperienceInput(key, value) {
    setExperience({ ...experience, [key]: value });
  }
  function setProjectInput(key, value) {
    setProject({ ...project, [key]: value });
  }

  async function add_skills(e) {
    e.preventDefault();
    try {
      formState.skills = criteriaSkilArray;
      update_profile();
    } catch (err) {}
  }
  async function update_experience() {
    try {
      let response = await core.API(
        core.API_METHODS.PUT,
        core.USER_API.PUT_UPDATE_EXPERIENCE + experience?._id,
        1,
        experience,
      );
      toast.success(response.message);
      window.location.reload();
    } catch (err) {
      toast.error(err);
    }
  }
  async function add_experience() {
    try {
      let response = await core.API(
        core.API_METHODS.POST,
        core.USER_API.POST_ADD_EXPERIENCE,
        1,
        experience,
      );
      window.location.reload();
    } catch (err) {}
  }
  async function remove_experience() {
    try {
      let response = await core.API(
        core.API_METHODS.DELETE,
        core.USER_API.DELETE_EXPERIENCE + experience?._id,
        1,
      );
      toast.success(response.message);
      window.location.reload();
    } catch (err) {
      toast.error(err);
    }
  }
  async function update_education() {
    try {
      let response = await core.API(
        core.API_METHODS.PUT,
        core.USER_API.PUT_UPDATE_EDUCATION + education?._id,
        1,
        education,
      );
      toast.success(response.message);
      window.location.reload();
    } catch (err) {
      toast.error(err);
    }
  }
  async function add_education() {
    try {
      let response = await core.API(
        core.API_METHODS.POST,
        core.USER_API.POST_ADD_EDUCATION,
        1,
        education,
      );
      window.location.reload();
    } catch (err) {}
  }
  async function remove_education() {
    try {
      let response = await core.API(
        core.API_METHODS.DELETE,
        core.USER_API.DELETE_EDUCATION + education?._id,
        1,
      );
      toast.success(response.message);
      window.location.reload();
    } catch (err) {
      toast.error(err);
    }
  }

  async function add_project() {
    try {
      let response = await core.API(
        core.API_METHODS.POST,
        core.USER_API.POST_ADD_PROJECT,
        1,
        project,
      );
      // window.location.reload();
    } catch (err) {}
  }

  async function update_project() {
    try {
      let response = await core.API(
        core.API_METHODS.PUT,
        core.USER_API.PUT_UPDATE_PROJECT + project?._id,
        1,
        project,
      );
      toast.success(response.message);
      window.location.reload();
    } catch (err) {
      toast.error(err);
    }
  }

  async function remove_project() {
    try {
      let response = await core.API(
        core.API_METHODS.DELETE,
        core.USER_API.DELETE_PROJECT + project?._id,
        1,
      );
      toast.success(response.message);
      window.location.reload();
    } catch (err) {
      toast.error(err);
    }
  }

  async function update_profile(e) {
    let response = await core.API(
      core.API_METHODS.PUT,
      core.CANDIDATE_API.PUT_UPDATE_PROFILE,
      1,
      {
        skills: skills,
        education: educationArr,
        experience: experienceArr,
        projects: projectArr,
      },
    );
    getProfile();
    setEditSkills(false);
    // window.location.reload();
  }

  async function update_personal_profile() {
    let response = await core.API(
      core.API_METHODS.PUT,
      core.CANDIDATE_API.PUT_UPDATE_PROFILE,
      1,
      formState,
    );
    getProfile();
  }

  const [reload, setReload] = useState(0);
  // edit the experience data get from resume parser
  const handleChange = (index, key, value) => {
    setExperienceArr((prevExperience) => {
      const updatedExperience = [...prevExperience];
      updatedExperience[index][key] = value;
      return updatedExperience;
    });
  };

  const handleChangeEdu = (index, key, value) => {
    setEducationArr((prevEducation) => {
      const updatedEducation = [...prevEducation];
      updatedEducation[index][key] = value;
      return updatedEducation;
    });
  };
  const handleChangeProject = (index, key, value) => {
    setProjectArr((prevProject) => {
      const updatedProject = [...prevProject];
      updatedProject[index][key] = value;
      return updatedProject;
    });
  };

  const [view, setView] = useState("Details");
  const [editSkills, setEditSkills] = useState(false);

  function editEnabled() {
    setEditSkills(true);
  }

  const updateSkills = () => {};

  // to add new experience
  const addEmptyExp = () => {
    setExperienceArr([
      ...experienceArr,
      {
        company: "",
        position: "",
        start: "",
        end: "",
        ongoing: "",
        description: "",
      },
    ]);
    setReload(reload + 1);
  };
  // to add new education
  const addEmptyEdu = () => {
    setEducationArr([
      ...educationArr,
      {
        school: "",
        degree: "",
        major: "",
        start: "",
        end: "",
        ongoing: "",
        description: "",
        GPA: "",
      },
    ]);
    setReload(reload + 1);
  };
  const addEmptyProject = () => {
    setProjectArr([
      ...projectArr,
      {
        name: "",
        link: "",
        description: "",
      },
    ]);
    setReload(reload + 1);
  };

  // console.log("User is :", user);

  const [selectedOption, setSelectedOption] = useState("Experience");

  const menuOptions = ["Experience", "Education", "Projects"];

  return (
    <div className="col-xs-12 no-padding" id="edit-profile">
      {!editSkills ? (
        <button
          className="edit-button"
          // data-tooltip="Edit"
          onClick={() => {
            editEnabled();
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M16.862 4.487L19.5 7.125M18 14V18.75C18 19.3467 17.7629 19.919 17.341 20.341C16.919 20.763 16.3467 21 15.75 21H5.25C4.65326 21 4.08097 20.763 3.65901 20.341C3.23705 19.919 3 19.3467 3 18.75V8.25C3 7.65327 3.23705 7.08097 3.65901 6.65901C4.08097 6.23706 4.65326 6 5.25 6H10M16.862 4.487L18.549 2.799C18.9007 2.44733 19.3777 2.24976 19.875 2.24976C20.3723 2.24976 20.8493 2.44733 21.201 2.799C21.5527 3.15068 21.7502 3.62766 21.7502 4.125C21.7502 4.62235 21.5527 5.09933 21.201 5.451L10.582 16.07C10.0533 16.5984 9.40137 16.9867 8.685 17.2L6 18L6.8 15.315C7.01328 14.5986 7.40163 13.9467 7.93 13.418L16.862 4.487V4.487Z"
              stroke="#474747"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>{" "}
          Edit
        </button>
      ) : (
        <>
          <div className="absolute right-0 -top-[70px] bg-none border-none flex items-center font-medium text-[16px] leading-[24px] text-[#363636] space-x-4">
            <button
              className="flex text-sm  bg-blue-800 hover:bg-blue-900 text-white px-4 py-2 rounded-md hover:scale-105 transition-all duration-300"
              // data-tooltip="Revert"
              onClick={() => {
                getProfile();
                setEditSkills(false);
              }}
            >
              Cancel <MdDelete className="ml-1 text-lg  text-white " />
            </button>
            <button
              className="flex text-sm  bg-blue-800 hover:bg-blue-900 text-white px-4 py-2 rounded-md hover:scale-105 transition-all duration-300"
              // data-tooltip="Done"
              onClick={() => {
                update_profile();
              }}
            >
              Apply <IoSend className="ml-1 text-md mt-[2px] text-white" />
            </button>
          </div>
        </>
      )}

      <div className="w-full flex flex-col md:flex-row items-start p-8 my-4 space-x-5 bg-white shadow rounded-lg">
        {/* Profile Image Section */}
        <div className="w-24 h-24 md:w-40 md:h-40 rounded-full overflow-hidden mr-5">
          <img
            src={img ?? Avatar}
            alt="Profile"
            className="object-cover w-full h-full"
          />
        </div>

        {/* Profile Details Section */}
        <div className="flex-1 ml-12">
          {editSkills ? (
            <div className="space-y-4">
              {/* Name Input */}
              <div className="w-full flex space-x-4">
                <div className="w-[50%]">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    value={formState?.first_name}
                    onChange={(e) => setInput("first_name", e.target.value)}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Enter your name"
                  />
                </div>
                <div className="w-[50%]">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    value={formState?.last_name}
                    onChange={(e) => setInput("last_name", e.target.value)}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Enter your name"
                  />
                </div>
              </div>

              {/* Profile Image Upload */}
              <div>
                <label
                  htmlFor="image"
                  className="block text-sm font-medium text-gray-700"
                >
                  Profile Image
                </label>
                <input
                  type="file"
                  id="image"
                  onChange={(e) => {
                    onImgChange(e.target.files[0]);
                    upload_picture();
                  }}
                  className="mt-1 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                />
              </div>

              {/* Resume Upload */}
              <div>
                <label
                  htmlFor="resume"
                  className="block text-sm font-medium text-gray-700"
                >
                  Resume
                </label>
                <input
                  type="file"
                  id="resume"
                  onChange={(e) => {
                    // upload_resume();
                    onFileChange(e.target.files[0]);
                  }}
                  className="mt-1 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                />
              </div>

              {/* Save Button */}
              {/* <button
                onClick={() => {
                  upload_picture();
                  // upload_resume();
                }}
                className="mt-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Save Changes
              </button> */}
            </div>
          ) : (
            <div>
              <h2 className="text-xl font-semibold text-gray-900">
                {profile?.first_name} {profile?.last_name}
              </h2>
              <p className="text-sm text-gray-600">{profile?.title}</p>

              {/* {profile?.resume ? (
                <a
                  href={profile.resume}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center text-blue-600 hover:text-blue-800 mt-4"
                >
                  <img src={ResumeImg} alt="Resume" className="w-6 h-6 mr-2" />
                  Resume
                </a>
              ) : (
                <p className="text-gray-500 mt-4">No resume uploaded</p>
              )} */}
              <p className="mt-4 text-gray-700">{profile?.summary}</p>
            </div>
          )}
        </div>
      </div>

      {/* <div className="flex space-x-4 "> */}
      {/* {editSkills && (
          <div className="w-full md:w-1/4 flex flex-col justify-center bg-white shadow-lg rounded-lg p-4 h-60">
            <ul className="flex flex-col text-base justify-center space-y-2">
              <li
                className={`p-3 flex rounded-md cursor-pointer font-semibold transition-colors duration-200 ${selectedOption === "Experience"
                  ? "bg-gray-200 text-blue-700"
                  : "bg-white text-gray-600 hover:bg-gray-100"
                  }`}
                onClick={() => setSelectedOption("Experience")}
              >
                <MdWorkHistory className={`${selectedOption === "Experience"
                  ? "text-blue-700" : "text-gray-600"} mr-3 text-lg`} />  Experience
              </li>
              <li
                className={`p-3 flex rounded-md cursor-pointer font-semibold transition-colors duration-200 ${selectedOption === "Education"
                  ? "bg-gray-200 text-blue-700"
                  : "bg-white text-gray-600 hover:bg-gray-100"
                  }`}
                onClick={() => setSelectedOption("Education")}
              >
                <FaBook className={`${selectedOption === "Education"
                  ? "text-blue-700" : "text-gray-600"} mr-3 text-lg p-[1px] mt-[2px]`} /> Education
              </li>
              <li
                className={`p-3 flex rounded-md cursor-pointer font-semibold transition-colors duration-200 ${selectedOption === "Projects"
                  ? "bg-gray-200 text-blue-700"
                  : "bg-white text-gray-600 hover:bg-gray-100"
                  }`}
                onClick={() => setSelectedOption("Projects")}
              >
                <FaFileCode className={`${selectedOption === "Projects"
                  ? "text-blue-700" : "text-gray-600"} mr-3 text-lg  mt-[2px]`} />
                Projects
              </li>
            </ul>

          </div>
        )} */}

      <div className={`flex bg-white shadow-lg rounded-md w-full p-3`}>
        <div
          className={`flex flex-col w-full ${editSkills ? "w-full px-5" : "md:w-7/12"}`}
        >
          {/* {(editSkills ? selectedOption === "Experience" : true) && ( */}
          <div className="col-xs-12 my-4 py-2 mx-1  ">
            <div className="flex justify-start items-stretch flex-col mb-6 gap-5">
              <div className="flex justify-between items-center">
                <h2 className="text-xl font-medium text-[#16182f]">
                  Work Experience
                </h2>

                {editSkills && (
                  <button
                    className="flex text-md font-medium bg-blue-800 hover:bg-blue-900 text-white px-4 py-1 rounded-md hover:scale-105 transition-all duration-300"
                    onClick={addEmptyExp}
                  >
                    <MdAdd className="text-white mr-1 mt-[2px] text-xl font-extrabold " />{" "}
                    Add
                  </button>
                )}
              </div>
              <div id="exp-modal" className="modal fade " role={"dialog"}>
                <Modal
                  form={
                    <ExperienceForm
                      input_function={setExperienceInput}
                      formState={experience}
                    />
                  }
                  onSave={add_experience}
                  onRemove={() => {
                    window.location.reload();
                  }}
                />
              </div>
            </div>

            {editSkills ? (
              <>
                {experienceArr.map((exp, index) => (
                  <div key={index} className="edit-candidate">
                    <h5 className="col-xs-12 no-padding mt-16">
                      <span>Experience {index + 1}</span>
                      <span
                        onClick={() => {
                          let tempvar = experienceArr;
                          tempvar.splice(index, 1);
                          setExperienceArr(tempvar);
                          setReload(reload + 1);
                        }}
                        className="cursor-pointer"
                      >
                        <svg
                          className="w-[18px] h-[18px]"
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                        >
                          <g id="left icon">
                            <path
                              className="group-hover:stroke-[#910000]"
                              id="Vector"
                              d="M11.0833 5.24935L10.6657 10.6783C10.5722 11.894 9.55851 12.8327 8.33926 12.8327H5.66074C4.44149 12.8327 3.42779 11.894 3.33428 10.6783L2.91667 5.24935M12.25 4.08268C10.7345 3.34417 8.93296 2.91602 7 2.91602C5.06704 2.91602 3.26545 3.34417 1.75 4.08268M5.83333 2.91602V2.33268C5.83333 1.68835 6.35567 1.16602 7 1.16602C7.64433 1.16602 8.16667 1.68835 8.16667 2.33268V2.91602M5.83333 6.41602V9.91602M8.16667 6.41602V9.91602"
                              stroke="#FF475D"
                              strokeLinecap="round"
                            ></path>
                          </g>
                        </svg>
                      </span>
                    </h5>
                    <label className="col-xs-12 no-padding">
                      Company Name:
                    </label>
                    <input
                      type="text"
                      className="col-xs-12"
                      value={exp["company"]}
                      onChange={(event) =>
                        handleChange(index, "company", event.target.value)
                      }
                    />

                    <label className="col-xs-12 no-padding">
                      Job Position:
                    </label>
                    <input
                      type="text"
                      className="col-xs-12"
                      value={exp["position"]}
                      onChange={(event) =>
                        handleChange(index, "position", event.target.value)
                      }
                    />

                    <div className="col-md-6 start ">
                      <label
                        htmlFor="start"
                        className="col-xs-12 no-padding required"
                      >
                        Start date
                      </label>
                      <input
                        className="col-md-12"
                        type="date"
                        name="start"
                        required="required"
                        onChange={(event) =>
                          handleChange(index, "start", event.target.value)
                        }
                        // max={todaysDate}
                        value={exp?.start?.split("T")[0]}
                        // onChange={(event) => {
                        //   if (new Date(event.target.value) < new Date()) {
                        //     input_function("start", event.target.value);
                        //   }
                        // }}
                      />
                    </div>
                    <div className="col-md-6 end">
                      <label htmlFor="end" className="col-xs-12 no-padding">
                        End date
                      </label>
                      <input
                        className="col-xs-12 no-padding"
                        type="date"
                        name="final"
                        onChange={(event) =>
                          handleChange(index, "end", event.target.value)
                        }
                        // required={!checked}
                        // disabled={checked}
                        min={formState?.start}
                        // max={todaysDate}
                        value={exp?.end?.split("T")[0]}
                        // value={formState?.end?.split("T")[0] ?? formState?.eend?.split("T")[0]}
                        // onChange={(event) => {
                        //   if (new Date(event.target.value) < new Date()) {
                        //     input_function("eend", event.target.value);
                        //     input_function("end", event.target.value);
                        //   }
                        // }}
                      />
                      {/* <div className="col-xs-12 no-padding checkbox-input">
                    <input
                      type="checkbox"
                      value={formState?.ongoing}
                      onChange={(event) => {
                        setChecked(event.target.checked);
                        input_function("ongoing", event.target.checked);
                      }}
                    />
                    <label htmlFor="checkbox">I currently work here</label>
                  </div> */}
                    </div>

                    <label className="col-xs-12 no-padding">Description:</label>
                    <textarea
                      className="col-xs-12"
                      value={exp["description"]}
                      onChange={(event) =>
                        handleChange(index, "description", event.target.value)
                      }
                    />
                  </div>
                ))}
              </>
            ) : (
              <>
                {profile?.experience != null &&
                profile?.experience.length > 0 ? (
                  <div className="mt-2">
                    {displayedExperiences.map((item, i) => (
                      <div
                        className="grid grid-cols-[50px_1fr] gap-x-4 gap-y-1 mt-3"
                        key={"experience" + i}
                      >
                        <InitialsLogo
                          width="50px"
                          height="50px"
                          fontSize="24px"
                          styleClass="rounded-xl border border-[#e1e1ee] row-span-4"
                          str={item?.company}
                        />
                        <p className="col-start-2 text-base font-semibold text-[#252e4b]">
                          {item?.position}
                        </p>
                        <p className="col-start-2 text-sm text-[#1369e9]">
                          {item?.company}
                        </p>
                        <div className="w-full col-start-2 flex flex-row gap-1">
                          <p className="text-sm text-[#252e4b]">
                            {moment(item?.start).format("MMM YYYY")} -{" "}
                            {item?.ongoing
                              ? "Present"
                              : moment(item?.end).format("MMM YYYY")}
                          </p>
                        </div>
                        <ExpandableText
                          maxChar={150}
                          classes="col-start-2 text-sm leading-[22px] text-[#5f6989]"
                        >
                          {item?.description}
                        </ExpandableText>
                      </div>
                    ))}

                    {/* Show "Show All" or "Collapse" button if there are more than 4 experiences */}
                    {profile.experience.length > 4 && (
                      <div className="mt-4 text-center">
                        <button
                          onClick={toggleShowAll}
                          className="text-sm font-semibold text-gray-700 no-underline hover:no-underline hover:cursor-pointer"
                        >
                          {showAll ? "Collapse" : "Show All"}
                        </button>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="section-data no-padding col-xs-12">
                    {/* <EmptyState text={"No work experience added yet"} /> */}
                    <div className="no-data">
                      <button
                        // data-toggle="modal"
                        // data-target="#exp-modal"
                        onClick={(e) => editEnabled(true)}
                      >
                        + Add work experience
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          {/* )}  */}
          {/* {(editSkills ? selectedOption === "Education" : true) && ( */}
          <div className="col-xs-12 my-4 py-2 mx-1 ">
            <div className="flex justify-start items-stretch flex-col mb-6 gap-5">
              <div className="flex justify-between items-center">
                <h2 className="text-xl font-medium text-[#16182f] ">
                  Education
                </h2>
                {editSkills && (
                  <button
                    className="flex text-md font-medium bg-blue-800 hover:bg-blue-900 text-white px-4 py-1 rounded-md hover:scale-105 transition-all duration-300"
                    onClick={addEmptyEdu}
                    // data-toggle="modal"
                    // data-target="#exp-modal"
                    // data-tooltip="Add"
                  >
                    <MdAdd className="text-white mr-1 mt-[2px] text-xl font-extrabold " />{" "}
                    Add
                  </button>
                )}
              </div>
              <div id="edu-modal" className="modal fade " role={"dialog"}>
                <Modal
                  form={
                    <EducationForm
                      input_function={setEducationInput}
                      formState={education}
                    />
                  }
                  onSave={add_education}
                  onRemove={() => {
                    window.location.reload();
                  }}
                />
              </div>
            </div>
            {editSkills ? (
              <>
                {educationArr.map((edu, index) => (
                  <div key={index} className="edit-candidate">
                    <h5 className="col-xs-12 no-padding mt-16">
                      Education {index + 1}
                      <span
                        onClick={() => {
                          let tempvar = educationArr;
                          tempvar.splice(index, 1);
                          setEducationArr(tempvar);
                          setReload(reload + 1);
                        }}
                        className="cursor-pointer"
                      >
                        <svg
                          className="w-[18px] h-[18px]"
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                        >
                          <g id="left icon">
                            <path
                              className="group-hover:stroke-[#910000]"
                              id="Vector"
                              d="M11.0833 5.24935L10.6657 10.6783C10.5722 11.894 9.55851 12.8327 8.33926 12.8327H5.66074C4.44149 12.8327 3.42779 11.894 3.33428 10.6783L2.91667 5.24935M12.25 4.08268C10.7345 3.34417 8.93296 2.91602 7 2.91602C5.06704 2.91602 3.26545 3.34417 1.75 4.08268M5.83333 2.91602V2.33268C5.83333 1.68835 6.35567 1.16602 7 1.16602C7.64433 1.16602 8.16667 1.68835 8.16667 2.33268V2.91602M5.83333 6.41602V9.91602M8.16667 6.41602V9.91602"
                              stroke="#FF475D"
                              strokeLinecap="round"
                            ></path>
                          </g>
                        </svg>
                      </span>
                    </h5>
                    <label className="col-xs-12 no-padding">Institute:</label>
                    <input
                      type="text"
                      className="col-xs-12"
                      value={edu?.school}
                      onChange={(event) =>
                        handleChangeEdu(index, "school", event.target.value)
                      }
                    />
                    <div className="col-md-6 start ">
                      <label className="col-xs-12 no-padding">Degree:</label>
                      <input
                        type="text"
                        className="col-xs-12"
                        value={edu?.degree}
                        onChange={(event) =>
                          handleChangeEdu(index, "degree", event.target.value)
                        }
                      />
                    </div>
                    <div className="col-md-6 end ">
                      <label className="col-xs-12 no-padding">
                        Score/CGPA:
                      </label>
                      <input
                        type="text"
                        className="col-xs-12"
                        value={edu.GPA}
                        onChange={(event) =>
                          handleChangeEdu(index, "GPA", event.target.value)
                        }
                      />
                    </div>

                    <div className="col-md-6 start ">
                      <label
                        htmlFor="start"
                        className="col-xs-12 no-padding required"
                      >
                        Start date
                      </label>
                      <input
                        className="col-md-12"
                        type="date"
                        name="start"
                        required="required"
                        onChange={(event) =>
                          handleChangeEdu(index, "start", event.target.value)
                        }
                        // max={todaysDate}
                        value={edu?.start?.split("T")[0]}
                        // onChange={(event) => {
                        //   if (new Date(event.target.value) < new Date()) {
                        //     input_function("start", event.target.value);
                        //   }
                        // }}
                      />
                    </div>
                    <div className="col-md-6 end">
                      <label htmlFor="end" className="col-xs-12 no-padding">
                        End date
                      </label>
                      <input
                        className="col-xs-12 no-padding"
                        type="date"
                        name="final"
                        onChange={(event) =>
                          handleChangeEdu(index, "end", event.target.value)
                        }
                        // required={!checked}
                        // disabled={checked}
                        // min={formState?.start}
                        // max={todaysDate}
                        // value={formState?.end?.split("T")[0] ?? formState?.eend?.split("T")[0]}
                        value={formState?.end?.split("T")[0]}
                        // onChange={(event) => {
                        //   if (new Date(event.target.value) < new Date()) {
                        //     input_function("eend", event.target.value);
                        //     input_function("end", event.target.value);
                        //   }
                        // }}
                      />
                      {/* <div className="col-xs-12 no-padding checkbox-input">
                  <input
                    type="checkbox"
                    value={formState?.ongoing}
                    onChange={(event) => {
                      setChecked(event.target.checked);
                      input_function("ongoing", event.target.checked);
                    }}
                  />
                  <label htmlFor="checkbox">I currently work here</label>
                </div> */}
                    </div>
                    <label className="col-xs-12 no-padding">Description:</label>
                    <textarea
                      className="col-xs-12"
                      value={edu?.description}
                      onChange={(event) =>
                        handleChangeEdu(
                          index,
                          "description",
                          event.target.value,
                        )
                      }
                    />
                  </div>
                ))}
              </>
            ) : (
              <>
                {profile?.education != null && profile?.education.length > 0 ? (
                  <div className="mt-2 ">
                    {profile?.education.map((item, i) => (
                      <div
                        className="grid grid-cols-[50px_1fr] gap-x-2 gap-y-1 mt-3"
                        key={"education" + i}
                      >
                        <InitialsLogo
                          width="50px"
                          height="50px"
                          fontSize="24px"
                          styleClass="rounded-xl border border-[#e1e1ee] row-span-4"
                          str={item?.school}
                        />
                        <p className="col-start-2 text-base font-semibold text-[#252e4b]">
                          {item?.degree}
                        </p>
                        <p className="col-start-2 text-sm text-[#1369e9]">
                          {item?.school}
                        </p>
                        <p className="w-full col-start-2 flex flex-row text-sm text-[#252e4b]">
                          {moment(item?.start).format("MMM YYYY")}
                        </p>
                        {/* <div className="col-xs-12 no-padding date-row">
                            <span className="title">
                              {item.degree}
                              {" | "}
                              {item.major}
                            </span>
                            <span className=" duration">
                              {new Date(item.start).toString().split(" ")[1]}{" "}
                              {new Date(item.start).toString().split(" ")[3]}
                              {" - "}
                              {new Date(item.end).toString().split(" ")[1]}{" "}
                              {new Date(item.end).toString().split(" ")[3]}
                            </span>
                          </div> */}
                        {/* <span className="col-xs-10 no-padding sub-title">
                            {item.school}
                          </span>
                          <span className="col-xs-10 no-padding description"></span> */}
                        <ExpandableText
                          maxChar={150}
                          classes="col-start-2 text-sm leading-[22px] text-[#5f6989]"
                        >
                          {item?.description}
                        </ExpandableText>

                        <div className="col-xs-12 no-padding edit-row">
                          {/* <span className="col-xs-10 no-padding grade">
                              Grade: {item.GPA}
                            </span> */}
                          {/* <button
                            className="col-xs-2 edit"
                            data-tooltip="Edit"
                            data-toggle="modal"
                            data-target="#edit-edu-modal"
                            onClick={() => {
                              setEducation(formState.education[i]);
                            }}
                          >
                            <EditButton />
                          </button> */}
                          <div
                            id="edit-edu-modal"
                            className="modal fade "
                            role={"dialog"}
                          >
                            <Modal
                              form={
                                <EducationForm
                                  input_function={setEducationInput}
                                  formState={education}
                                />
                              }
                              onSave={update_education}
                              onRemove={remove_education}
                            />
                          </div>
                        </div>

                        {/* <div className="line"></div> */}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="section-data no-padding col-xs-12 ">
                    {/* <EmptyState text={"No educational qualifications added yet"} /> */}
                    <div className="no-data">
                      <button
                        onClick={(e) => editEnabled(true)}
                        // data-toggle="modal"
                        // data-target="#edu-modal"
                      >
                        + Add education
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          {/* )} */}
          {/* {(editSkills ? selectedOption === "Projects" : true) && ( */}
          <div className="col-xs-12 my-4 py-2 mx-1 ">
            <div className="flex justify-start items-stretch flex-col">
              <div className="flex justify-between items-center">
                <h2 className="text-xl flex font-medium text-[#16182f] mb-4 ">
                  Projects
                  {/* {editSkills && (<FaFileCode className={`text-gray-700 ml-3 text-2xl  `} />)} */}
                </h2>

                {editSkills && (
                  <button
                    className="flex text-md font-medium bg-blue-800 hover:bg-blue-900 text-white px-4 py-1 rounded-md hover:scale-105 transition-all duration-300"
                    onClick={addEmptyProject}
                    // data-toggle="modal"
                    // data-target="#exp-modal"
                    // data-tooltip="Add"
                  >
                    <MdAdd className="text-white mr-1 mt-[2px] text-xl font-extrabold " />{" "}
                    Add
                  </button>
                )}
              </div>

              <div id="project-modal" className="modal fade" role="dialog">
                <Modal
                  form={
                    <ProjectForm
                      formState={project}
                      input_function={setProjectInput}
                    />
                  }
                  onSave={add_project}
                  onRemove={() => {
                    window.location.reload();
                  }}
                />
              </div>
            </div>

            {editSkills ? (
              <>
                {projectArr.map((project, index) => (
                  <div key={index} className="edit-candidate">
                    <h5 className="col-xs-12 no-padding mt-16">
                      <span>Project {index + 1}</span>
                      <span
                        onClick={() => {
                          let tempvar = projectArr;
                          tempvar.splice(index, 1);
                          setProjectArr(tempvar);
                          setReload(reload + 1);
                        }}
                        className="cursor-pointer"
                      >
                        <svg
                          className="w-[18px] h-[18px]"
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                        >
                          <g id="left icon">
                            <path
                              className="group-hover:stroke-[#910000]"
                              id="Vector"
                              d="M11.0833 5.24935L10.6657 10.6783C10.5722 11.894 9.55851 12.8327 8.33926 12.8327H5.66074C4.44149 12.8327 3.42779 11.894 3.33428 10.6783L2.91667 5.24935M12.25 4.08268C10.7345 3.34417 8.93296 2.91602 7 2.91602C5.06704 2.91602 3.26545 3.34417 1.75 4.08268M5.83333 2.91602V2.33268C5.83333 1.68835 6.35567 1.16602 7 1.16602C7.64433 1.16602 8.16667 1.68835 8.16667 2.33268V2.91602M5.83333 6.41602V9.91602M8.16667 6.41602V9.91602"
                              stroke="#FF475D"
                              strokeLinecap="round"
                            />
                          </g>
                        </svg>
                      </span>
                    </h5>

                    <div className="col-xs-12 no-padding">
                      <label
                        className="col-xs-12 no-padding required"
                        htmlFor="name"
                      >
                        Project Title
                      </label>
                      <input
                        type="text"
                        className="input col-xs-12 col-md-12 no-padding"
                        name="name"
                        value={project?.Project_name}
                        required="required"
                        placeholder="Type to search... "
                        onChange={(event) => {
                          handleChangeProject(
                            index,
                            "name",
                            event.target.value,
                          );
                        }}
                      />
                    </div>

                    {/* <div className="col-xs-12 no-padding">
                      <label
                        className="col-xs-12 no-padding required"
                        htmlFor="link"
                      >
                        Project Link
                      </label>
                      <input
                        type="text"
                        className="input col-xs-12 col-md-12 no-padding"
                        name="link"
                        value={project?.link}
                        required="required"
                        placeholder="Type to search... "
                        onChange={(event) => {
                          handleChangeProject(
                            index,
                            "link",
                            event.target.value,
                          );
                        }}
                      />
                    </div> */}

                    <div className="col-xs-12 no-padding">
                      <label
                        className="col-xs-12 no-padding"
                        htmlFor="description"
                      >
                        Project Description
                      </label>
                      <textarea
                        type="text"
                        className="input col-xs-12 col-md-12 no-padding"
                        name="description"
                        value={project?.Description}
                        placeholder="Type to Enter "
                        onChange={(event) => {
                          handleChangeProject(
                            index,
                            "description",
                            event.target.value,
                          );
                        }}
                      ></textarea>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <>
                {profile?.project !== null && profile?.project.length !== 0 ? (
                  <div className="mt-2">
                    {profile?.project.map((item, i) => (
                      <div
                        className="grid grid-cols-[50px_1fr] gap-x-2 gap-y-1 mt-3"
                        key={"project" + i}
                      >
                        <InitialsLogo
                          width="50px"
                          height="50px"
                          fontSize="24px"
                          styleClass="rounded-xl border border-[#e1e1ee] row-span-4"
                          str={item?.Project_name}
                        />
                        <p className="col-start-2 text-base font-semibold text-[#252e4b]">
                          {item?.Project_name}
                        </p>
                        {/* <a
                          href={item?.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex col-start-2 text-sm font-semibold text-gray-700 hover:cursor-pointer no-underline hover:no-underline"
                        >
                          Link{" "}
                          <FaLink className="ml-1 mt-[2px] text-base text-[#252e4b]" />
                        </a> */}
                        <ExpandableText
                          maxChar={150}
                          classes="col-start-2 text-sm leading-[22px] text-[#5f6989]"
                        >
                          {item?.Description}
                        </ExpandableText>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="no-data">
                    <button>+ Add academic/ personal project</button>
                  </div>
                )}
              </>
            )}
          </div>
          {/* )} */}
        </div>
        {editSkills ? null : (
          <div
            className="flex w-full md:w-5/12 relative"
            id="candidateAnalytics"
          >
            <div className="col-xs-12 my-4 py-2 mx-1 w-full ">
              <div className="flex justify-start items-stretch flex-col">
                <h2 className="text-xl font-medium text-[#16182f] mb-4">
                  Skills
                </h2>
              </div>

              {profile?.skills?.length > 0 ? (
                profile.skills.map((data, it) => (
                  <div className="w-full mb-4" key={it}>
                    <h4 className="text-gray-700 text-sm not-italic font-medium mb-3">
                      {checkLabel(data?.criteria, data?.label)}
                    </h4>
                    <div className="w-full border p-2 border-[#E1E1EE] rounded-xl relative">
                      {checkLabel(data?.skills, data?.keySkills)?.map(
                        (skill, i) => (
                          <div
                            className="flex last:pb-0 pb-2 items-center ml-0"
                            key={i}
                          >
                            <span
                              className="w-[45%] text-[#252E4B] text-[14px] not-italic leading-7 truncate overflow-hidden mr-[10px]"
                              onMouseOver={(e) => {
                                const overflowed =
                                  e.target.scrollWidth > e.target.clientWidth;
                                e.target.title = overflowed
                                  ? e.target.textContent
                                  : "";
                              }}
                              onMouseOut={(e) => (e.target.title = "")}
                            >
                              {skill?.skills}
                            </span>
                            <span className="w-[45%] mr-[10px]">
                              <ProgressBar
                                id={"skills-bar" + it + i}
                                style_classes="w-full"
                                data={Math.abs(skill?.score)}
                                height={8}
                                barColor={getTypeColor(skill?.type) ?? "teal"}
                                bgColor="#EFF0F2"
                                width={width * 0.45 - 10}
                                fromRight={skill?.score < 0}
                              />
                            </span>
                            <span style={{ width: 20 }}>
                              {Math.floor(skill?.score)}
                            </span>
                          </div>
                        ),
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <div className="section-data no-padding col-xs-12">
                  <div className="no-data">
                    <button onClick={editEnabled}>+ Add Skills</button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {/* </div> */}
    </div>
  );
}

// <div className="section-data no-padding col-xs-12 ">
//   {profile?.experience.map((item, i) => (
//     <div className="col-xs-12 data-row no-padding" key={i}>
//       <div className="col-xs-12 no-padding">
//         <div className="col-xs-12 no-padding date-row">
//           <span className="title">{item.position}</span>
//           <span className=" duration">
//             {new Date(item.start).toString().split(" ")[1]}{" "}
//             {new Date(item.start).toString().split(" ")[3]}
//             {" - "}
//             {new Date(item.end).toString().split(" ")[1]}{" "}
//             {new Date(item.end).toString().split(" ")[3]}
//           </span>
//         </div>
//         <div className="col-xs-12 no-padding sub-title">
//           {item.company}
//         </div>
//         <div className="col-xs-12 no-padding edit-row">
//           <span className="col-xs-10 no-padding description">
//             {item.description}
//           </span>
//           {/* <button
//           className="col-xs-2 edit"
//           data-tooltip="Edit"
//           data-toggle="modal"
//           data-target="#edit-exp-modal"
//           onClick={() => {
//             setExperience(formState.experience[i]);
//           }}
//         >
//           <EditButton />
//         </button> */}
//           <div
//             id="edit-exp-modal"
//             className="modal fade"
//             role={"dialog"}
//           >
//             <Modal
//               form={
//                 <ExperienceForm
//                   input_function={setExperienceInput}
//                   formState={experience}
//                 />
//               }
//               onSave={update_experience}
//               onRemove={remove_experience}
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   ))}
// </div>

{
  /* <div className="toggle-button ">
          <button
            className={
              view == "Details" ? "toggle-button active" : "toggle-button"
            }
            onClick={() => setView("Details")}
          >
            Details
          </button>
          <button
            className={
              view == "Analytics" ? "toggle-button active" : "toggle-button "
            }
            onClick={() => setView("Analytics")}
          >
            Analytics
          </button>
          <button
            className={
              view == "Both" ? "toggle-button active" : "toggle-button "
            }
            onClick={() => setView("Both")}
          >
            Both
          </button>
        </div> */
}

{
  /* <div className="col-xs-12 no-padding skills-section data-section">
          <div className="col-xs-12 section-header no-padding">
            <h4>Skills</h4>

            {(profile?.skills?.length > 0 || profile?.tools?.length > 0) &&

              <button
                className="add-button"
                data-toggle="modal"
                data-target="#skills-modal"
                data-tooltip="Add"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 20V12M12 12V4M12 12H20M12 12H4"
                    stroke="#363636"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
            }

            <div id="skills-modal" className="modal fade " role={"dialog"}>
              <Modal
                form={
                  <Skills
                    skillArr={skill}
                    setSkillArr={setSkill}
                    toolArr={tool}
                    setToolArr={setTool}
                    criteriaSkilArray={criteriaSkilArray}
                    setCriteriaSkilArray={setCriteriaSkilArray}
                  />
                }
                onSave={add_skills}
              />
            </div>
          </div>
          <div className="section-data col-xs-12 no-padding">
            {criteriaSkilArray?.length > 0 || tool?.length > 0 ? (
              <>
                {criteriaSkilArray != null && (
                  <div className="col-xs-12 col-md-6 no-padding skills">
                    <h6>Skills</h6>
                    {criteriaSkilArray?.map((item, itr) => (
                      <>
                        <h5>{item?.label}</h5>
                        {item?.keySkills?.map((skills) => (
                          <div key={itr} className="tag">
                            <span className="text">{skills?.label}</span>
                          </div>
                        ))}
                      </>
                    ))}
                  </div>
                )}

                {tool != null && (
                  <div className="col-xs-12 col-md-6 no-padding tools">
                    <h6>Tool</h6>
                    {tool.map((item, itr) => (
                      <div key={itr} className="tag">
                        <span className="text">{item.name}</span>
                      </div>
                    ))}
                  </div>
                )}
              </>
            ) : (
              // <EmptyState text={"No skills or tools added yet"} />
              <div className="no-data">
                <button
                  data-toggle="modal"
                  data-target="#skills-modal"
                >
                  {"+ "} {" Add skill & tool"}
                </button>
              </div>
            )}
          </div>
        </div> */
}

{
  /* {view != "Details" && (
        <div className={
          view == "Both" ? "col-xs-5 details-section" : "col-xs-12 details-section no-padding"
        }>
          <ResumeAnalytics editSkills={editSkills}
            skills={skills}
            setSkills={setSkills}
            profile={profile}

          />
        </div>
      )} */
}
