import { useContext, useEffect, useRef, useState } from "react";
import Header from "../../../components/all/header/Header";
import Filter from "../../../components/candidate/dashboard/Filter";
import SideBar from "../../../components/all/SideBar";
import Menu from "../../../components/all/header/Menu";
import { BsFilterLeft } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import { FaExpandAlt } from "react-icons/fa";
import { FaCaretDown } from "react-icons/fa";
import queryString from "query-string";
import JobPost from "../../../components/candidate/dashboard/JobPost";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderNew from "../../../components/all/header/HeaderNew";
import CandidateSidebar from "../../../components/all/CandidateSidebar";
import ReactPaginate from "react-paginate";
import AutocompleteInput from "../../recruiter/candidate/AutocompleteInput";
import { ScaleLoader } from "react-spinners";
import AutoCompleteInputLocation from "../../recruiter/candidate/AutoCompleteInputLocation";
import InitialsLogo from "../../../components/all/InitialsLogo";
import { toast } from "react-toastify";
import { MdLocationCity } from "react-icons/md";
import { IoMdTime } from "react-icons/io";
import { IoSend } from "react-icons/io5";
import { FaBookmark } from "react-icons/fa";
import { FaRegBookmark } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { FaBusinessTime } from "react-icons/fa6";
import parse from "html-react-parser";
import { FcApproval } from "react-icons/fc";
import { MdOutlineWorkOutline } from "react-icons/md";

const core = require("../../../lib/core");

export default function Dashboard() {
  const [jobs, setJobs] = useState([]);
  const [filters, setFilters] = useState([]);
  const [sort, setSort] = useState([]);
  const loc = useLocation();
  var filterStr;
  useEffect(() => {
    filterStr = loc.state ? loc.state : "";
    console.log(loc.state ? loc.state : "");
    getDefalutCandidateDashboard("?" + loc.pathname.split("/").at(-1));
  }, [filterStr]);

  const getDefalutCandidateDashboard = async (location) => {
    console.log(location);
    const json = await core.API(
      core.API_METHODS.GET,
      core.CANDIDATE_API.GET_CANDIDATE_DASHBOARD + location,
      1,
    );
    // console.log(json)
    if (json.data) {
      setFilters(json?.data?.filters);
      setSort(json?.data?.sortBy);
      setJobs(json?.data?.jobs);
      console.log(json?.data);
    } else {
      setJobs([]);
    }
  };

  const getCandidateDashboard = async (filter = "") => {
    console.log(filter);
    const json = await core.API(
      core.API_METHODS.GET,
      core.CANDIDATE_API.GET_CANDIDATE_DASHBOARD + filter,
      1,
    );
    // console.log(json)
    if (json.data) {
      setFilters(json?.data?.filters);
      setSort(json?.data?.sortBy);
      setJobs(json?.data?.jobs);
      console.log(json?.data);
    } else {
      setJobs([]);
    }
  };

  const [jobIndex, setJobIndex] = useState([]);

  const timeDifference = (difference) => {
    if (difference < 1000) {
      return "Just now";
    } else if (difference < 60000) {
      return `${Math.floor(difference / 1000)} seconds ago`;
    } else if (difference < 3600000) {
      return `${Math.floor(difference / 60000)} minutes ago`;
    } else if (difference < 86400000) {
      return `${Math.floor(difference / 3600000)} hours ago`;
    } else if (difference < 2592000000) {
      return `${Math.floor(difference / 86400000)} days ago`;
    } else if (difference < 31536000000) {
      return `${Math.floor(difference / 2592000000)} months ago`;
    } else {
      return `${Math.floor(difference / 31536000000)} years ago`;
    }
  };

  async function applyJob(e, job_id) {
    try {
      e.preventDefault();
      let response = await core.API(
        core.API_METHODS.POST,
        core.CANDIDATE_API.POST_APPLY_JOB + String(job_id),
        1,
        {},
      );
      console.log(response);
      toast.success(response.data.message);
      setJobIndex([...jobIndex, job_id]);
    } catch (err) {
      console.log(err);
    }
  }

  const ApplyBtn = ({ applied, job_id }) => {
    return jobIndex.includes(job_id) ? (
      <h4 style={{ color: "green" }}>Applied</h4>
    ) : (
      <button
        className="flex font-semibold bg-blue-800 hover:bg-blue-900 hover:scale-105 transition-all duration-300 text-white px-4 py-2 rounded-md"
        onClick={(e) => {
          applyJob(e, job_id);
        }}
      >
        Apply
        <IoSend className="text-white ml-[6px] mt-[3px] text-[16px]" />
      </button>
    );
  };

  async function bookmarkJob(job_id) {
    try {
      let response = await core.API(
        core.API_METHODS.POST,
        core.CANDIDATE_API.POST_SAVE_JOB + String(job_id),
        1,
        {},
      );
      reload_function();
      toast.success(response.data.message);
    } catch (err) {
      console.log(err);
    }
  }

  function parseHtml(html) {
    const parsedHtml = parse(html.split("&lt;").join("<"));
    return parsedHtml;
  }

  const [filterLocation, setFilterLocation] = useState([]);
  const queryParams = queryString.parse(location.search);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [minExp, setMinExp] = useState(null);
  const [maxExp, setMaxExp] = useState(null);
  const [isLocationDropdownOpen, setIsLocationDropdownOpen] = useState(false);
  const [locationRange, setLocationRange] = useState("25km");
  const locationOptions = ["50km", "100km", "250km", "500km"];
  const [jobRange, setJobRange] = useState("Select Range");
  const [requiredInfo, setRequiredInfo] = useState([]);
  const [jobrequiredInfo, setJobRequiredInfo] = useState([]);
  const [listCompanyNames, setListCompanyNames] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [jobtypeDropdown, setJobtypeDropdown] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [scaleLoading, setScaleLoading] = useState(false);
  const [triggerFilterSubmit, setTriggerFilterSubmit] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [jobTypeOptions, setJobTypeOptions] = useState([]);
  const [jobsperpage, setJobsperPage] = useState([]);

  const { user } = useContext(core.UserContext);
  console.log("USer is :", user);

  const [listdegrees, setListdegrees] = useState(
    queryParams?.degrees?.length > 0 ? queryParams?.degrees?.split(",") : [],
  );
  const [listschoolname, setListschoolname] = useState([]);
  const [degree, setDegree] = useState("");

  const [listjobtitle, setlistjobtitle] = useState(
    queryParams?.title?.length > 0 ? queryParams?.title?.split(",") : [],
  );
  const [listskill, setListskill] = useState(
    queryParams?.skills?.length > 0 ? queryParams?.skills?.split(",") : [],
  );
  // const [showInputEducation, setShowInputEducation] = useState(false);
  const [showInputExperience, setShowInputExperience] = useState(false);

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const candidatesPerPage = 20;
  const [Locations, setLocations] = useState([]);
  const dropdownRef = useRef(null);

  const handlePageClick = (data) => {
    // console.log(data?.selected);
    setCurrentPage(data?.selected + 1);
    setDetailsVisibility({});
    // scrollToComponent();
  };

  // const scrollToComponent = () => {
  //   dropdownRef.current.scrollIntoView({
  //     behavior: "smooth",
  //   });
  // };

  // useEffect(() => {
  //   handleFilterSubmit(currentPage);
  // }, [currentPage]);

  useEffect(() => {
    handleFilterSubmit();
  }, []);

  const [detailsVisibility, setDetailsVisibility] = useState({});

  const timeoutId = useRef(null);
  const [listlocation, setListlocation] = useState(
    queryParams?.location?.length > 0 ? queryParams?.location?.split(",") : [],
  );

  const [listcordinates, setListcordinates] = useState(
    queryParams?.lat?.length > 0 &&
      queryParams?.lon?.length > 0 &&
      queryParams?.location?.length > 0
      ? queryParams?.lat?.split(",").map((lat, i) => ({
          lat: lat,
          lon: queryParams?.lon?.split(",")[i],
          name: queryParams?.location?.split(",")[i],
        }))
      : [],
  );

  const getLocationSuggestions = async (val) => {
    try {
      clearTimeout(timeoutId.current);
      setLoading((prev) => ({ ...prev, location: true }));

      timeoutId.current = setTimeout(async () => {
        let response = await core.API(
          core.API_METHODS.GET,
          core.SUGGESTION_API.GET_SUGGESTION + "?q=" + val + "&format=json",
          1,
        );
        if (response) {
          setLocations(response);
        }
        setLoading((prev) => ({ ...prev, location: false }));
      }, 500);
    } catch (err) {
      console.log(err);
    }
  };

  const requestData = {
    location: listlocation,
    experienceRange: [minExp, maxExp],
    requiredContact: requiredInfo,
    jobType: jobrequiredInfo,
    skills: listskill,
    education: listdegrees,
    jobPositionNames: listjobtitle,
    companies: listCompanyNames,
    page: currentPage,
    pageSize: 20,
    sort: "Date posted",
  };

  console.log("Request Data:", requestData);

  const handleFilterSubmit = async () => {
    try {
      setScaleLoading(true);
      const result = await core.API(
        core.API_METHODS.POST,
        core.CANDIDATE_API.GET_CANDIDATE_DASHBOARD,
        1,
        {
          location: listlocation,
          experienceRange: [minExp, maxExp],
          requiredContact: requiredInfo,
          jobType: jobrequiredInfo,
          skills: listskill,
          education: listdegrees,
          jobPositionNames: listjobtitle,
          companies: listCompanyNames,
          page: currentPage,
          pageSize: 20,
          sort: "Date posted",
        },
      );
      console.log("Response is : ", result);
      const message = result.message;
      setTotalPage(result?.data?.pagination?.totalPages);
      setJobsperPage(result.data.jobs);
      // console.log("Jobs are : ", result?.data?.jobs);
      // console.log("Jobs are  throught job post : ", jobsperpage);

      setScaleLoading(false);
    } catch (error) {
      console.error("Error fetching filtered candidates:", error);
      setScaleLoading(false);
    }
  };

  // useEffect(() => {
  //   console.log("Jobs are (after setJobpost):", jobpost);
  // }, [jobpost]);

  const addLocation = (location) => {
    const newLocation = { ...location, id: new Date().getTime() };
    setListcordinates((prev) => [...prev, newLocation]);
  };

  const removeLocation = (id) => {
    setListcordinates((prev) => prev.filter((item) => item.id !== id));
    setListlocation((prev) => {
      const itemToRemove = listcordinates.find((item) => item.id === id);
      if (itemToRemove) {
        return prev.filter((loc) => loc !== itemToRemove.name);
      }
      return prev;
    });

    setTriggerFilterSubmit((prev) => !prev);
  };

  const [isValueSelected, setIsValueSelected] = useState(false);

  const handleSelectOption = (option) => {
    setLocationRange(option);
    setIsLocationDropdownOpen(false);
    setIsValueSelected(true);
  };

  const handleToggleDropdown = () => {
    setIsLocationDropdownOpen(!isLocationDropdownOpen);
    if (isLocationDropdownOpen && !isValueSelected) {
      setIsValueSelected(false);
    }
  };

  const handleClear = () => {
    setListCompanyNames([]);
    setListcordinates([]);
    setIsValueSelected(false);
    setLocationRange("25km");
    setMinExp(null);
    setMaxExp(null);
    setSelectedOptions([]);
    setJobTypeOptions([]);
    setJobRequiredInfo([]);
    setListskill([]);
    setlistjobtitle([]);
    setJobRange("Select Range");
    setListlocation([]);
    setRequiredInfo([]);
    setListschoolname([]);
    setListdegrees([]);
    setPrompt("");
    // setShowFilter(false);
  };

  const handleOptionClick = (option) => {
    setSelectedOptions((prevOptions) =>
      prevOptions.includes(option)
        ? prevOptions.filter((item) => item !== option)
        : [...prevOptions, option],
    );
    setRequiredInfo((prevInfo) =>
      prevInfo.includes(option)
        ? prevInfo.filter((item) => item !== option)
        : [...prevInfo, option],
    );
    setShowDropdown(false);
  };

  const handlejobTypeClick = (option) => {
    setJobTypeOptions((prevOptions) =>
      prevOptions.includes(option)
        ? prevOptions.filter((item) => item !== option)
        : [...prevOptions, option],
    );
    setJobRequiredInfo((prevInfo) =>
      prevInfo.includes(option)
        ? prevInfo.filter((item) => item !== option)
        : [...prevInfo, option],
    );
    setJobtypeDropdown(false);
  };

  const [bookmarkedJobs, setBookmarkedJobs] = useState(() => {
    // Initialize state with saved jobs (if needed)
    return jobs.filter((job) => job?.saved).map((job) => job?.id);
  });

  const toggleBookmark = (jobId) => {
    setBookmarkedJobs(
      (prev) =>
        prev.includes(jobId)
          ? prev.filter((id) => id !== jobId) // Remove from bookmarks
          : [...prev, jobId], // Add to bookmarks
    );
  };

  useEffect(() => {
    handleFilterSubmit();
  }, [
    locationRange,
    minExp,
    maxExp,
    listskill,
    listCompanyNames,
    listdegrees,
    listjobtitle,
    triggerFilterSubmit,
    jobrequiredInfo,
    requiredInfo,
    currentPage,
    jobRange,
  ]);

  useEffect(() => {
    const updatedCoordinates = listlocation.map((loc) => ({
      name: loc,
      id: new Date().getTime() + Math.random(), // Ensure unique ID
    }));
    setListcordinates(updatedCoordinates);
  }, [listlocation]);

  // console.log("Jobposts are : ", jobsperpage);
  const [jobSearchList, setJobSearchList] = useState([]);

  const fetchJobSuggestions = async (query) => {
    if (query.length <= 2) {
      setJobSearchList([]);
      return;
    }
    try {
      const apiUrl = `${core.RECRUITER_API.GET_JOBS_SUGGESTIONS}?query=${query}`;
      console.log("API URL is:", apiUrl);

      const resultfromcore = await core.API(core.API_METHODS.GET, apiUrl, 1);

      // Add the user's input to the suggestions list
      setJobSearchList([...new Set([query, ...resultfromcore])]);
      // console.log("Job Search List is : ", jobSearchList);
      setJobSearchList([...new Set(resultfromcore)]);
    } catch (error) {
      console.error("Error fetching skill suggestions:", error);
      setJobSearchList([]);
    }
  };

  const [searchList, setSearchList] = useState([]);

  const fetchSuggestions = async (query) => {
    if (query.length <= 2) {
      setSearchList([query]);
      return;
    }
    try {
      const apiUrl = `${core.RECRUITER_API.GET_SKILLS_SUGGESTIONS}?query=${query}`;
      console.log("API URL is:", apiUrl);

      const resultfromcore = await core.API(core.API_METHODS.GET, apiUrl, 1);

      // Add the user's input to the suggestions list
      setSearchList([...new Set([query, ...resultfromcore])]);
    } catch (error) {
      console.error("Error fetching skill suggestions:", error);
      setSearchList([query]);
    }
  };

  return (
    <div
      className="w-screen h-screen p-0 overflow-hidden"
      id="job_details_page"
    >
      <div
        className={`flex flex-row w-screen h-screen left-0 relative top-0 mb-2`}
      >
        <CandidateSidebar active="Dashboard" open={false} />
        <div className="w-full">
          <div className="mb-3">
            <HeaderNew />
          </div>

          <div className="mb-1">
            {/* <DashboardContent
              jobs={jobs}
              sort={sort}
              filters={filters}
              getCandidateDashboard={getCandidateDashboard}
            /> */}
            <main className="relative flex  w-full h-fit  pt-1 pl-2 ml-1 mb-4 pb-2 rounded-tl-2xl overflow-y-auto bg-[#F3F5F9] ">
              {/* <div className="h-full mb-2 overflow-y-auto " id="dashboard"> */}
              {/* <main className=" w-full flex flex-row mb-2"> */}

              <div
                id="job-list"
                className="flex flex-col w-[69.5%] mx-2 mt-2  h-[calc(95vh-56px)] mb-4 overflow-y-auto scrollbar-hide "
              >
                {scaleLoading ? (
                  <div className="w-full min-h-full flex items-center justify-center">
                    <div className="auto-complete-list mt-0 absolute no-list-style w-[69%] rounded-lg shadow-menuOptionShadow z-10 border top-[44px] flex items-center justify-center py-5">
                      <ScaleLoader height={14} color="#1369E9" loading={true} />
                    </div>
                  </div>
                ) : Array.isArray(jobsperpage) && jobsperpage.length > 0 ? (
                  <>
                    {jobsperpage.map((job, i) => (
                      <div className="w-full bg-white shadow-sm rounded-lg p-6 mb-4 transition duration-300 hover:shadow-md">
                        <div className="no-underline hover:no-underline flex justify-between">
                          <div className="flex w-[85%] ">
                            <div className="w-1/10">
                              <InitialsLogo
                                className="w-16 h-16 rounded-md overflow-hidden"
                                str={job?.company?.name}
                                width={64}
                                height={64}
                                bgRounded="8px"
                                fontSize={28}
                              />
                            </div>
                            <div className="ml-4 w-full" id="main">
                              <div className="flex justify-between">
                                <h2 className="flex text-xl font-semibold text-gray-700">
                                  {job?.company?.name}
                                  {job?.position && job?.company?.name
                                    ? " - "
                                    : null}
                                  {job?.position}
                                  {bookmarkedJobs.includes(job.id) ||
                                  job?.saved ? (
                                    <FaBookmark
                                      className="ml-2 mt-1 text-lime-600 hover:cursor-pointer hover:scale-105 transition-all duration-300"
                                      onClick={() => {
                                        bookmarkJob(job?.id);
                                        toggleBookmark(job?.id);
                                      }}
                                    />
                                  ) : (
                                    <FaRegBookmark
                                      className="ml-2 mt-1 text-lime-600 hover:cursor-pointer hover:scale-105 transition-all duration-300"
                                      onClick={() => {
                                        bookmarkJob(job?.id);
                                        toggleBookmark(job?.id);
                                      }}
                                    />
                                  )}
                                </h2>
                                {job?.hiringStatus == "Actively hiring" ? (
                                  <div className="flex text-gray-700 font-semibold">
                                    <FcApproval className="text-lg mr-1" />{" "}
                                    Actively Hiring
                                  </div>
                                ) : null}
                              </div>
                              {job?.location?.[0] && (
                                <p className="flex text-gray-600">
                                  <FaLocationDot className="mt-[3px] mr-2 text-gray-600" />
                                  {typeof job.location[0] === "string"
                                    ? job.location[0]
                                    : job.location[0]?.name}
                                </p>
                              )}
                              <div className="flex">
                                {job?.experienceLevel && (
                                  <p className="flex text-gray-600">
                                    <FaBusinessTime className="mt-[3px] mr-2 text-gray-600" />
                                    Experience:{" "}
                                    {typeof job.experienceLevel === "string"
                                      ? job.experienceLevel
                                      : job.experienceLevel.min
                                        ? `${job.experienceLevel.min}+ years`
                                        : "Not specified"}
                                  </p>
                                )}
                                {job?.workplace && job?.workplace != "" && (
                                  <p
                                    className={`flex items-end ${job?.experienceLevel ? "ml-4 " : " "} justify-end text-gray-600`}
                                  >
                                    <MdLocationCity className="text-gray-600 mr-1 text-lg mb-[2px]" />{" "}
                                    Workplace : {job?.workplace}
                                  </p>
                                )}
                              </div>
                              {job?.description && (
                                <div className="text-gray-700 my-2">
                                  {(() => {
                                    const sentences =
                                      job.description.split(/(?<=\.)\s+/);
                                    const firstThreeSentences = sentences
                                      .slice(0, 3)
                                      .join(" ");
                                    if (firstThreeSentences.length <= 200) {
                                      return parseHtml(firstThreeSentences);
                                    } else {
                                      let truncated = job.description.slice(
                                        0,
                                        200,
                                      );
                                      if (
                                        truncated.charAt(
                                          truncated.length - 1,
                                        ) !== " "
                                      ) {
                                        const lastSpaceIndex =
                                          truncated.lastIndexOf(" ");
                                        truncated = job.description.slice(
                                          0,
                                          lastSpaceIndex,
                                        );
                                      }
                                      return parseHtml(truncated + ".");
                                    }
                                  })()}
                                </div>
                              )}
                              {/* <div className="flex flex-wrap items-center gap-2 mt-2">
                          {job?.criterias
                            ?.map((criteria) => criteria?.skills)
                            .flat()
                            .map((skill, index) => (
                              <span
                                key={`${index}-${Date.now()}-${Math.random()}`}
                          className="bg-[#00AC85] text-white text-sm font-semibold px-2 py-1 rounded"
                              >
                          {typeof skill === "string" ? skill : skill?.label}
                        </span>
                            ))}
                      </div> */}
                            </div>
                          </div>
                          <div
                            className="w-[15%] flex flex-col justify-between "
                            id="ending"
                          >
                            <div className="flex flex-col items-end justify-end">
                              {job?.createdAt && (
                                <p className="flex text-gray-600 mb-2 items-center text-right">
                                  <IoMdTime className="text-lg mr-1 text-gray-600" />
                                  {timeDifference(
                                    new Date() - new Date(job.createdAt),
                                  )}
                                </p>
                              )}
                              {/* {job?.jobType?.length > 0 &&
                          !(job?.jobType?.length === 1 && job?.jobType[0]?.trim() === "") && (
                            <p className="flex items-end justify-end text-gray-600 mb-4 text-right">
                              <MdOutlineWorkOutline className="text-lg mr-1 text-gray-600" />
                              Job:{" "}
                              {job.jobType
                                .filter((type) =>
                                  typeof type === "string" ? true : type.status
                                )
                                .map((type) =>
                                  typeof type === "string" ? type : type.type
                                )
                                .join(", ")}
                            </p>
                          )} */}
                            </div>

                            <div className="flex justify-end mt-auto">
                              <ApplyBtn applied={false} job_id={job.id} />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="flex justify-center relative bg-[#FFF] border-t border-t-[#EFF0F2] rounded-lg ">
                      <ReactPaginate
                        previousLabel={"<- Prev"}
                        nextLabel={"Next ->"}
                        breakLabel={"..."}
                        pageCount={totalPage}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                        forcePage={currentPage - 1}
                      />
                    </div>
                  </>
                ) : (
                  <div className="w-full  min-h-full flex items-center justify-center flex-col ">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="140"
                        height="131"
                        viewBox="0 0 140 131"
                        fill="none"
                      >
                        <path
                          d="M74.4084 129.006C109.686 129.006 138.286 100.406 138.286 65.0031C138.286 29.5998 109.686 1 74.4084 1C39.1311 1 10.5312 29.5998 10.5312 65.0031C10.5312 100.406 39.1311 129.006 74.4084 129.006Z"
                          fill="#F1F3F9"
                          stroke="#D6DCE8"
                          strokeWidth="2"
                          strokeMiterlimit="10"
                        />
                        <path
                          d="M133.625 39.4324H24.3911C21.9973 39.4324 20.1074 37.5425 20.1074 35.1487V10.5805C20.1074 8.18673 21.9973 6.29688 24.3911 6.29688H133.499C135.893 6.29688 137.782 8.18673 137.782 10.5805V35.1487C137.908 37.5425 135.893 39.4324 133.625 39.4324Z"
                          fill="white"
                          stroke="#D6DCE8"
                          strokeWidth="2"
                          strokeMiterlimit="10"
                        />
                        <path
                          d="M46.5665 22.8087C46.5665 25.5804 45.3066 28.1003 43.4168 29.8641C41.7789 31.376 39.5111 32.2579 37.1172 32.2579C34.7234 32.2579 32.4556 31.376 30.8177 29.8641C28.8019 28.1003 27.668 25.5804 27.668 22.8087C27.668 17.643 31.9516 13.3594 37.1172 13.3594C42.2829 13.3594 46.5665 17.643 46.5665 22.8087Z"
                          fill="#D6DCE8"
                        />
                        <path
                          d="M43.4174 29.8581C41.7795 31.37 39.5117 32.2519 37.1179 32.2519C34.7241 32.2519 32.4562 31.37 30.8184 29.8581C31.5743 29.6061 32.4562 29.2281 33.5901 28.7242C33.8421 28.5982 34.0941 28.3462 34.2201 28.0942C34.3461 27.9682 34.3461 27.7162 34.3461 27.5902V25.1964L34.2201 25.0704C33.9681 24.8184 33.8421 24.4405 33.8421 24.0625L33.5901 23.9365C32.9602 24.0625 33.0862 23.4326 32.8342 22.1726C32.8342 21.6687 32.8342 21.5427 33.0862 21.4167L33.3382 21.1647C32.2043 18.5189 32.7082 16.8811 33.9681 16.2511C33.5901 15.3692 33.5901 15.1172 33.5901 15.1172C33.5901 15.1172 36.11 15.4952 36.8659 15.3692C37.9998 15.1172 39.6377 15.3692 40.2676 16.8811C41.2756 17.259 41.6535 17.889 41.7795 18.6449C41.9055 19.7788 41.2756 21.0387 41.1496 21.4167C41.2756 21.5427 41.4016 21.6687 41.2756 22.0467C41.1496 23.1806 41.1496 23.9365 40.5196 23.8105L40.0157 24.9444C40.0157 25.0704 40.0157 25.0704 40.0157 25.1964C40.0157 25.3224 40.0157 25.7004 40.0157 27.7162C40.0157 27.9682 40.1416 28.2202 40.1416 28.3462C40.2676 28.5982 40.5196 28.7242 40.6456 28.8501C41.7795 29.2281 42.6615 29.6061 43.4174 29.8581Z"
                          fill="white"
                        />
                        <path
                          d="M41.1823 21.5427C41.1823 21.2804 41.0511 20.887 40.92 20.6248V20.4936C40.3955 19.4446 39.3464 19.0512 38.2973 19.0512C35.6747 18.92 35.4124 19.4446 34.4945 18.6578C34.7567 19.0512 34.7567 19.7068 34.3633 20.4936C34.1011 21.0182 33.5765 21.2804 33.052 21.4116C31.8718 18.6578 32.3963 16.9531 33.7077 16.2974C33.3143 15.3795 33.3143 15.1172 33.3143 15.1172C33.3143 15.1172 35.9369 15.5106 36.7237 15.3795C37.9039 15.1172 39.6087 15.3795 40.2643 16.9531C41.3134 17.3465 41.7068 18.0021 41.8379 18.7889C41.9691 19.7068 41.3134 21.0182 41.1823 21.5427Z"
                          fill="#AAB2C5"
                        />
                        <path
                          d="M40.1423 28.3461V28.85H34.2207V28.2201C34.3467 28.0941 34.3467 27.8421 34.3467 27.7161V25.3223L34.2207 25.1963V25.0703C34.3467 25.3223 34.4727 25.4483 34.7247 25.7003L36.6145 26.9602C36.9925 27.3381 37.6224 27.3381 38.1264 26.9602L39.8903 25.4483L40.0163 25.3223C40.0163 25.4483 40.0163 25.8263 40.0163 27.8421C39.8903 27.9681 40.0163 28.0941 40.1423 28.3461Z"
                          fill="url(#paint0_linear_4307_81330)"
                        />
                        <path
                          d="M77.5594 20.025H53.3693C52.7393 20.025 52.3613 19.521 52.3613 19.0171V15.8673C52.3613 15.2373 52.8653 14.8594 53.3693 14.8594H77.5594C78.1894 14.8594 78.5673 15.3633 78.5673 15.8673V19.0171C78.5673 19.647 78.1894 20.025 77.5594 20.025Z"
                          fill="#1369E9"
                        />
                        <path
                          d="M106.285 30.7359H53.3693C52.7393 30.7359 52.3613 30.232 52.3613 29.728V26.5782C52.3613 25.9483 52.8653 25.5703 53.3693 25.5703H106.285C106.915 25.5703 107.293 26.0743 107.293 26.5782V29.728C107.293 30.3579 106.789 30.7359 106.285 30.7359Z"
                          fill="#D6DCE8"
                        />
                        <path
                          d="M133.625 122.073H24.3911C21.9973 122.073 20.1074 120.183 20.1074 117.789V93.2212C20.1074 90.8273 21.9973 88.9375 24.3911 88.9375H133.499C135.893 88.9375 137.782 90.8273 137.782 93.2212V117.789C137.908 120.183 135.893 122.073 133.625 122.073Z"
                          fill="white"
                          stroke="#D6DCE8"
                          strokeWidth="2"
                          strokeMiterlimit="10"
                        />
                        <path
                          d="M46.5665 105.449C46.5665 108.221 45.3066 110.741 43.4168 112.505C41.7789 114.017 39.5111 114.899 37.1172 114.899C34.7234 114.899 32.4556 114.017 30.8177 112.505C28.8019 110.741 27.668 108.221 27.668 105.449C27.668 100.284 31.9516 96 37.1172 96C42.2829 96 46.5665 100.284 46.5665 105.449Z"
                          fill="#D6DCE8"
                        />
                        <path
                          d="M43.4174 112.499C41.7795 114.011 39.5117 114.893 37.1179 114.893C34.7241 114.893 32.4562 114.011 30.8184 112.499C31.5743 112.247 32.4562 111.869 33.5901 111.365C33.8421 111.239 34.0941 110.987 34.2201 110.735C34.3461 110.609 34.3461 110.357 34.3461 110.231V107.837L34.2201 107.711C33.9681 107.459 33.8421 107.081 33.8421 106.703L33.5901 106.577C32.9602 106.703 33.0862 106.073 32.8342 104.813C32.8342 104.309 32.8342 104.183 33.0862 104.057L33.3382 103.805C32.2043 101.16 32.7082 99.5217 33.9681 98.8917C33.5901 98.0098 33.5901 97.7578 33.5901 97.7578C33.5901 97.7578 36.11 98.1358 36.8659 98.0098C37.9998 97.7578 39.6377 98.0098 40.2676 99.5217C41.2756 99.8996 41.6535 100.53 41.7795 101.286C41.9055 102.419 41.2756 103.679 41.1496 104.057C41.2756 104.183 41.4016 104.309 41.2756 104.687C41.1496 105.821 41.1496 106.577 40.5196 106.451L40.0157 107.459C40.0157 107.585 40.0157 107.585 40.0157 107.711C40.0157 107.837 40.0157 108.215 40.0157 110.231C40.0157 110.483 40.1416 110.735 40.1416 110.861C40.2676 111.113 40.5196 111.239 40.6456 111.365C41.7795 111.995 42.6615 112.247 43.4174 112.499Z"
                          fill="white"
                        />
                        <path
                          d="M41.1823 104.183C41.1823 103.921 41.0511 103.528 40.92 103.265V103.134C40.3955 102.085 39.3464 101.692 38.2973 101.692C35.6747 101.561 35.4124 102.085 34.4945 101.298C34.7567 101.692 34.7567 102.347 34.3633 103.134C34.1011 103.659 33.5765 103.921 33.052 104.052C31.8718 101.298 32.3963 99.5937 33.7077 98.938C33.3143 98.0201 33.3143 97.7578 33.3143 97.7578C33.3143 97.7578 35.9369 98.1512 36.7237 98.0201C37.9039 97.7578 39.6087 98.0201 40.2643 99.5937C41.3134 99.9871 41.7068 100.643 41.8379 101.43C41.9691 102.479 41.3134 103.659 41.1823 104.183Z"
                          fill="#AAB2C5"
                        />
                        <path
                          d="M40.1423 110.987V111.491H34.2207V110.861C34.3467 110.735 34.3467 110.483 34.3467 110.357V107.963L34.2207 107.837V107.711C34.3467 107.963 34.4727 108.089 34.7247 108.341L36.6145 109.601C36.9925 109.979 37.6224 109.979 38.1264 109.601L39.8903 108.089L40.0163 107.963C40.0163 108.089 40.0163 108.467 40.0163 110.483C39.8903 110.609 40.0163 110.861 40.1423 110.987Z"
                          fill="url(#paint1_linear_4307_81330)"
                        />
                        <path
                          d="M77.5594 102.798H53.3693C52.7393 102.798 52.3613 102.294 52.3613 101.79V98.6407C52.3613 98.0108 52.8653 97.6328 53.3693 97.6328H77.5594C78.1894 97.6328 78.5673 98.1368 78.5673 98.6407V101.79C78.5673 102.294 78.1894 102.798 77.5594 102.798Z"
                          fill="#1369E9"
                        />
                        <path
                          d="M106.285 113.509H53.3693C52.7393 113.509 52.3613 113.005 52.3613 112.501V109.352C52.3613 108.722 52.8653 108.344 53.3693 108.344H106.285C106.915 108.344 107.293 108.848 107.293 109.352V112.501C107.293 113.005 106.789 113.509 106.285 113.509Z"
                          fill="#D6DCE8"
                        />
                        <path
                          d="M115.104 80.7527H5.99656C3.60275 80.7527 1.71289 78.8628 1.71289 76.469V51.9009C1.71289 49.507 3.60275 47.6172 5.99656 47.6172H115.104C117.498 47.6172 119.388 49.507 119.388 51.9009V76.469C119.514 78.8628 117.498 80.7527 115.104 80.7527Z"
                          fill="white"
                          stroke="#D6DCE8"
                          strokeWidth="2"
                          strokeMiterlimit="10"
                        />
                        <path
                          d="M59.1629 61.3453H34.9728C34.3428 61.3453 33.9648 60.8413 33.9648 60.3374V57.1876C33.9648 56.5577 34.4688 56.1797 34.9728 56.1797H59.1629C59.7929 56.1797 60.1708 56.6836 60.1708 57.1876V60.3374C60.1708 60.9673 59.7929 61.3453 59.1629 61.3453Z"
                          fill="#1369E9"
                        />
                        <path
                          d="M87.8887 72.1812H34.9728C34.3428 72.1812 33.9648 71.6773 33.9648 71.1733V68.0235C33.9648 67.3936 34.4688 67.0156 34.9728 67.0156H87.7628C88.3927 67.0156 88.7707 67.5196 88.7707 68.0235V71.1733C88.8967 71.6773 88.3927 72.1812 87.8887 72.1812Z"
                          fill="#D6DCE8"
                        />
                        <path
                          d="M28.296 64.129C28.296 66.7748 27.1621 69.1686 25.3983 70.9325C25.2723 71.0584 25.2723 71.0584 25.1463 71.1844C23.5084 72.6963 21.2406 73.5782 18.8468 73.5782C16.4529 73.5782 14.3111 72.6963 12.6732 71.3104C12.6732 71.3104 12.6732 71.3104 12.5472 71.3104C10.5314 69.5466 9.27148 67.0267 9.27148 64.129C9.27148 58.9634 13.5552 54.6797 18.7208 54.6797C24.0124 54.6797 28.296 58.9634 28.296 64.129Z"
                          fill="#D6DCE8"
                        />
                        <path
                          d="M25.3595 71.1269C24.9762 70.2326 24.3374 69.466 23.3152 68.9549C22.8042 68.6994 22.4209 68.5716 21.782 68.5716H21.3987C20.5044 68.5716 20.3766 68.4438 20.3766 68.4438V66.9106C21.3987 66.144 22.1653 65.1219 22.4209 63.972C23.0597 63.8442 23.443 63.3332 23.3152 62.6943C23.3152 62.4388 23.0597 62.1833 23.0597 61.9277C23.0597 61.8 23.0597 61.6722 23.0597 61.5444C23.0597 61.4167 23.0597 61.4167 23.0597 61.2889C23.0597 61.1611 23.0597 61.1611 23.0597 61.0334C23.0597 60.5223 22.9319 60.139 22.5486 59.6279C21.782 58.2225 20.2488 57.3281 18.5879 57.3281C18.2046 57.3281 17.949 57.3281 17.5657 57.4559C16.9269 57.5837 16.4158 57.8392 15.9048 58.2225C15.777 58.3503 15.777 58.3503 15.6492 58.478C15.6492 58.478 15.6492 58.478 15.5215 58.6058C15.0104 59.1169 14.6271 59.7557 14.3716 60.3945C14.116 61.0334 14.116 61.8 14.116 62.5666C14.116 62.5666 14.116 62.5666 14.116 62.6943C14.116 62.8221 14.116 62.8221 14.116 62.9499C14.116 62.9499 14.116 63.0776 13.9883 63.0776C14.2438 62.8221 14.116 63.2054 14.2438 63.4609C14.4993 64.0998 14.8826 63.972 15.3937 64.3553C15.3937 64.3553 15.3937 64.3553 15.2659 64.3553L14.7549 64.4831C11.944 65.3774 11.6885 68.3161 14.4993 68.9549C14.2438 69.0827 14.116 69.2104 13.9883 69.2104C13.2217 69.8493 12.7106 70.6159 12.4551 71.3825C14.116 72.7879 16.2881 73.6822 18.7156 73.6822C21.1432 73.6822 23.443 72.7879 25.104 71.2547C25.2317 71.3824 25.3595 71.2547 25.3595 71.1269C25.4873 71.1269 25.4873 71.1269 25.3595 71.1269ZM17.438 68.0605C17.3102 67.9327 17.1824 67.805 17.1824 67.805C17.0547 67.6772 16.9269 67.6772 16.7991 67.5495C16.7991 67.5495 16.6714 67.5494 16.6714 67.4217C16.2881 67.1662 15.9048 66.9106 15.777 66.5273C15.777 66.3996 15.9048 66.144 16.1603 65.8885C16.2881 66.0163 16.4158 66.144 16.5436 66.3996C16.6714 66.5273 16.7991 66.6551 16.9269 66.7829C17.0547 66.7829 17.1824 66.9106 17.1824 67.0384C17.1824 67.0384 17.1824 67.0384 17.3102 67.0384C17.3102 67.0384 17.3102 67.0384 17.438 67.0384V68.0605Z"
                          fill="white"
                        />
                        <path
                          d="M21.5523 68.4972C21.4263 69.0012 21.1744 69.3792 21.1744 69.3792L16.3867 68.6232L16.6387 67.3633C16.6387 67.3633 16.7647 67.3633 16.7647 67.4893C16.8907 67.6153 17.0167 67.6153 17.1427 67.7413C17.2687 67.7413 17.2687 67.8673 17.3946 67.9933V67.1114C17.3946 67.1114 17.3946 67.1114 17.2687 67.1114C18.0246 67.4893 19.0325 67.7413 20.4184 66.8594V68.3713C20.5444 68.3713 20.6704 68.4972 21.5523 68.4972Z"
                          fill="url(#paint2_linear_4307_81330)"
                        />
                        <path
                          d="M20.5601 61.4673C21.4589 61.4673 22.3577 61.3389 23.1281 61.0821C23.1281 61.0821 23.1281 60.9537 23.2565 60.8253C23.1281 60.44 22.9997 59.9264 22.7429 59.4128C21.9725 58.0004 20.4317 57.1016 18.7624 57.1016C18.3772 57.1016 18.0077 57.129 17.4925 57.2574C16.8069 57.467 16.4885 57.6512 16.3261 57.7496C16.3261 57.7496 15.9508 58.002 15.6808 58.2572C15.4107 58.5124 14.7819 59.4128 14.5251 60.0548C14.2683 60.8253 14.0611 61.4801 14.1895 62.2505C14.3179 62.2505 14.5251 62.1093 14.6535 62.1093C15.6808 61.5957 16.4512 60.9537 16.8364 59.9264C17.8636 60.8253 19.1476 61.3389 20.5601 61.4673Z"
                          fill="#AAB2C5"
                        />
                        <path
                          d="M16.2012 66.0156C16.3272 66.1416 16.4532 66.2676 16.5791 66.3936C16.4532 66.2676 16.3272 66.1416 16.2012 66.0156Z"
                          fill="#3973F4"
                        />
                        <path
                          d="M17.965 70.6745C17.5871 71.1785 16.9571 71.1785 16.3271 71.1785C16.9571 70.5485 16.5791 68.6587 14.5633 68.9107C11.6655 68.4067 12.0435 65.3829 14.8153 64.501L15.3192 64.375C15.3192 64.375 15.3192 64.375 15.4452 64.501C15.6972 65.1309 15.9492 65.5089 16.3271 66.0129C15.1932 67.0208 16.8311 67.2728 17.5871 68.0287C18.091 68.2807 18.595 69.9186 17.965 70.6745Z"
                          fill="#AAB2C5"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_4307_81330"
                            x1="37.1833"
                            y1="28.873"
                            x2="37.1833"
                            y2="26.7908"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="white" />
                            <stop offset="1" stopColor="#E2E5EC" />
                          </linearGradient>
                          <linearGradient
                            id="paint1_linear_4307_81330"
                            x1="37.1833"
                            y1="111.514"
                            x2="37.1833"
                            y2="109.431"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="white" />
                            <stop offset="1" stopColor="#E2E5EC" />
                          </linearGradient>
                          <linearGradient
                            id="paint2_linear_4307_81330"
                            x1="18.9711"
                            y1="69.3945"
                            x2="18.9711"
                            y2="68.0064"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="white" />
                            <stop offset="1" stopColor="#E2E5EC" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </span>
                    <span className="text-[#252E4B] font-medium text-[18px] leading-[17px] not-italic mt-[24px]">
                      No Job Found
                    </span>
                  </div>
                )}
              </div>
              <div className="w-[29%] mb-4 mx-2 mt-2 h-[calc(95vh-56px)]  overflow-y-scroll overflow-x-hidden scrollbar-hide">
                {/* <Filter
        jobs={jobs}
        sort_option={sort}
        filter_options={filters}
        filter_function={getCandidateDashboard}
        /> */}
                <div
                  id="filter"
                  className="relative flex-grow-0 flex-shrink-0 bg-white  w-full flex-col sm:hidden md:flex overflow-y-auto"
                >
                  <div className="flex flex-col h-full ">
                    <h6 className="flex flex-row items-center justify-between text-base font-medium not-italic pt-2 leading-[22px] text-gray-800 mt-[2px]">
                      <div className="flex items-center">
                        <BsFilterLeft className="font-extrabold text-gray-800 text-xl mr-2 ml-2 mt-[2px]" />
                        Filters
                      </div>
                      <div className="flex items-center">
                        <button
                          onClick={handleClear}
                          className="flex flex-row rounded-lg shadow-sm bg-blue-800 hover:bg-blue-900 text-white px-3 py-2 hover:cursor-pointer mr-2"
                        >
                          Clear <MdDelete className="ml-1 text-white text-lg" />
                        </button>
                      </div>
                    </h6>

                    <div className="flex flex-col  mx-2 mt-2 px-5 py-2 pb-4 bg-defaultBg justify-between ">
                      <div className="flex flex-row ">
                        <h6 className="cursor-pointer text-base font-medium not-italic pt-5 leading-[22px] text-gray-800 mt-1">
                          <span>Location(s)</span>
                        </h6>
                      </div>
                      <div className="relative ">
                        <AutoCompleteInputLocation
                          loading={loading.location}
                          idx={"location"}
                          list={Locations || []}
                          classText={
                            "border border-[#DADAE4] md:h-[35px] h-[25px] mt-2 lg:h-[45px] bg-[#FFF] rounded-[8px] py-[3px] px-[10px] shadow"
                          }
                          setvalue={(e) => {
                            setCurrentPage(1);
                            setListlocation(e);
                            setFilterLocation(e[e.length - 1]);
                          }}
                          addLocation={addLocation}
                          initialArray={listlocation}
                          autoSuggestion={true}
                          onInputChange={(e) => {
                            getLocationSuggestions(e);
                          }}
                          handleFilterSubmit={handleFilterSubmit}
                        />
                      </div>
                      <div className="flex flex-wrap mt-3 gap-2">
                        {listcordinates?.map((item) => (
                          <label
                            className="flex items-center py-[5px] px-[8px] border bg-[#00AC85] rounded-[4px]"
                            key={item.id} // Use unique id as key
                          >
                            <span className="text-[#fff] text-[14px] font-normal not-italic">
                              {item.name}
                            </span>
                            <span onClick={() => removeLocation(item.id)}>
                              <svg
                                className="ml-[4px] cursor-pointer"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <path
                                  d="M4.66797 4.66669L11.3346 11.3334"
                                  stroke="white"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M4.66927 11.3334L11.3359 4.66669"
                                  stroke="white"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </span>
                          </label>
                        ))}
                      </div>

                      <div className="flex justify-between space-x-2 mt-3 py-[12px]">
                        <h6 className="cursor-pointer text-base font-medium not-italic leading-[22px] text-gray-800 mb-[5px]">
                          <span
                            onClick={() => {
                              setCurrentPage(1);
                              setShowInputExperience(!showInputExperience);
                            }}
                          >
                            Min Experience
                          </span>
                          <input
                            className="rounded-md w-full shadow-sm border border-[#DADAE4] p-2 mt-2 md:h-[35px] h-[25px] lg:h-[45px] MuiInputBase-input MuiFilledInput-input css-2bxn45 placeholder-gray-500"
                            aria-invalid="false"
                            autoComplete="off"
                            name="yearsOfExp"
                            placeholder="Ex: 2 years"
                            type="number"
                            value={minExp !== null ? minExp : ""}
                            onChange={(e) => setMinExp(e.target.value)}
                          />
                        </h6>
                        <h6 className="cursor-pointer text-base font-medium not-italic leading-[22px] text-gray-800 mb-[5px]">
                          <span
                            onClick={() => {
                              setCurrentPage(1);
                              setShowInputExperience(!showInputExperience);
                            }}
                          >
                            Max Experience
                          </span>
                          <input
                            className="rounded-md w-full shadow-sm border border-[#DADAE4] p-2 mt-2 md:h-[35px] h-[25px] lg:h-[45px] MuiInputBase-input MuiFilledInput-input css-2bxn45 placeholder-gray-500"
                            aria-invalid="false"
                            autoComplete="off"
                            name="yearsOfExp"
                            placeholder="Ex: 10 years"
                            type="number"
                            value={maxExp !== null ? maxExp : ""}
                            onChange={(e) => setMaxExp(e.target.value)}
                          />
                        </h6>
                      </div>
                      <div className="mt-3 pb-3 relative">
                        <h6 className="cursor-pointer text-base font-medium not-italic leading-[22px] text-gray-800 mb-[3px]">
                          <span>Workplace Type</span>
                        </h6>
                        <div
                          className="flex w-full items-center shadow-sm border border-[#DADAE4] rounded-md p-2 mt-2 md:h-[35px] h-[25px] lg:h-[45px] bg-white cursor-pointer placeholder-gray-600"
                          onClick={() => setShowDropdown(!showDropdown)}
                        >
                          {selectedOptions.length > 0 &&
                            selectedOptions.map((option, index) => (
                              <span
                                key={index}
                                className="bg-[#00AC85] text-white px-2 py-1 rounded-md mr-2"
                              >
                                {option}
                              </span>
                            ))}
                          <span className="text-gray-600 text-base">
                            {selectedOptions.length === 0 && "Select Workplace"}
                          </span>
                          <span className="ml-auto text-gray-700 text-md mr-2">
                            <FaCaretDown
                              className={showDropdown ? "rotate-180" : ""}
                            />
                          </span>
                        </div>
                        {showDropdown && (
                          <div className="absolute z-10 bg-white border border-gray-300 rounded-md shadow-lg mt-1 w-full">
                            <div
                              className="block px-4 py-2 border border-gray-300 text-sm text-gray-700 cursor-pointer hover:bg-defaultBg"
                              onClick={() => {
                                setCurrentPage(1);
                                handleOptionClick("On-Site");
                              }}
                            >
                              On-Site
                            </div>
                            <div
                              className="block px-4 py-2 border border-gray-300 text-sm text-gray-700 cursor-pointer hover:bg-defaultBg"
                              onClick={() => {
                                setCurrentPage(1);
                                handleOptionClick("Remote");
                              }}
                            >
                              Remote
                            </div>
                            <div
                              className="block px-4 py-2 border border-gray-300 text-sm text-gray-700 cursor-pointer hover:bg-defaultBg"
                              onClick={() => {
                                setCurrentPage(1);
                                handleOptionClick("Hybrid");
                              }}
                            >
                              Hybrid
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="flex flex-col px-5 py-2 pb-6 bg-defaultBg justify-between mx-2  mt-2">
                      <div className="mt-3 pb-3 relative">
                        <h6 className="cursor-pointer text-base font-medium not-italic leading-[22px] text-gray-800 mb-[3px]">
                          <span>Job Type</span>
                        </h6>
                        <div
                          className={`flex w-full items-center shadow-sm border border-[#DADAE4] rounded-md p-2 mt-2 bg-white cursor-pointer placeholder-gray-600 flex-wrap ${
                            jobTypeOptions.length > 3
                              ? "md:h-[70px] h-[50px] lg:h-[90px]"
                              : "md:h-[35px] h-[25px] lg:h-[45px]"
                          }`}
                          onClick={() => setJobtypeDropdown(!jobtypeDropdown)}
                        >
                          {jobTypeOptions.length > 0 &&
                            jobTypeOptions.map((option, index) => (
                              <span
                                key={index}
                                className="bg-[#00AC85] text-white px-2 py-1 rounded-md mr-2"
                              >
                                {option}
                              </span>
                            ))}
                          <span className="text-gray-600 text-base">
                            {jobTypeOptions.length === 0 && "Select Job Type"}
                          </span>
                          <span className="ml-auto text-gray-700 text-md mr-2">
                            <FaCaretDown
                              className={jobtypeDropdown ? "rotate-180" : ""}
                            />
                          </span>
                        </div>
                        {jobtypeDropdown && (
                          <div className="absolute z-10 bg-white border border-gray-300 rounded-md shadow-lg mt-1 w-full">
                            <div
                              className="block px-4 py-2 border border-gray-300 text-sm text-gray-700 cursor-pointer hover:bg-defaultBg"
                              onClick={() => {
                                setCurrentPage(1);
                                handlejobTypeClick("Full Time");
                              }}
                            >
                              Full Time
                            </div>
                            <div
                              className="block px-4 py-2 border border-gray-300 text-sm text-gray-700 cursor-pointer hover:bg-defaultBg"
                              onClick={() => {
                                setCurrentPage(1);
                                handlejobTypeClick("Part Time");
                              }}
                            >
                              Part Time
                            </div>
                            <div
                              className="block px-4 py-2 border border-gray-300 text-sm text-gray-700 cursor-pointer hover:bg-defaultBg"
                              onClick={() => {
                                setCurrentPage(1);
                                handlejobTypeClick("Contract");
                              }}
                            >
                              Contract
                            </div>
                            <div
                              className="block px-4 py-2 border border-gray-300 text-sm text-gray-700 cursor-pointer hover:bg-defaultBg"
                              onClick={() => {
                                setCurrentPage(1);
                                handlejobTypeClick("Internship");
                              }}
                            >
                              Internship
                            </div>
                            <div
                              className="block px-4 py-2 border border-gray-300 text-sm text-gray-700 cursor-pointer hover:bg-defaultBg"
                              onClick={() => {
                                setCurrentPage(1);
                                handlejobTypeClick("Volunteer");
                              }}
                            >
                              Volunteer
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="flex flex-row ">
                        <h6 className="cursor-pointer text-base font-medium not-italic pt-5 leading-[22px] text-gray-800 mt-1">
                          <span>Skills</span>
                        </h6>
                      </div>
                      <div className="relative ">
                        <AutocompleteInput
                          idx={"skillsdata"}
                          classText={
                            "border border-[#DADAE4] md:h-[35px] h-[25px] lg:h-[45px] mt-2 bg-[#FFF] py-1 px-[10px] rounded-md"
                          }
                          list={searchList} // Pass the fetched suggestions
                          setvalue={(e) => {
                            setCurrentPage(1);
                            setListskill(e);
                          }}
                          initialArray={listskill}
                          fetchSuggestions={fetchSuggestions}
                        />
                      </div>
                      <div className="flex flex-wrap mt-3 gap-2">
                        {listskill?.map((item, i) => (
                          <label
                            className="flex items-center py-[5px] px-[8px] border bg-[#00AC85] rounded-[4px]"
                            key={i}
                          >
                            <span className="text-[#fff] text-[14px] font-normal not-italic">
                              {item}
                            </span>
                            <span
                              onClick={() => {
                                setCurrentPage(1);
                                setListskill([
                                  ...listskill.filter((i) => i !== item),
                                ]);
                              }}
                            >
                              <svg
                                className="ml-[4px] cursor-pointer"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <path
                                  d="M4.66797 4.66669L11.3346 11.3334"
                                  stroke="white"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M4.66927 11.3334L11.3359 4.66669"
                                  stroke="white"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </span>
                          </label>
                        ))}
                      </div>
                      <div className="relative">
                        <div className="pt-5 pb-3">
                          <span className="cursor-pointer text-base font-medium not-italic leading-[22px] text-gray-800 mb-2">
                            Degree Required
                          </span>
                          <div className="relative">
                            <AutocompleteInput
                              idx={"degree"}
                              classText={
                                "border border-[#DADAE4] md:h-[35px] h-[25px] mt-2 lg:h-[45px]  bg-[#FFF] rounded-[8px]  py-[5px] px-[10px] shadow"
                              }
                              list={[
                                "Associate",
                                "Bachelor",
                                "Master",
                                "Doctorate",
                                "Professional",
                              ]}
                              setvalue={setListdegrees}
                              initialArray={listdegrees}
                            />
                          </div>
                        </div>
                        <div className="flex flex-wrap gap-2">
                          {listdegrees?.map((item, i) => (
                            <label
                              className="flex items-center py-[5px] px-[8px] border bg-[#00AC85] rounded-[4px]"
                              key={i}
                            >
                              <span className="text-[#fff] text-[14px] font-normal not-italic">
                                {item}
                              </span>
                              <span
                                onClick={() => {
                                  setCurrentPage(1);
                                  setListdegrees([
                                    ...listdegrees.filter((i) => i !== item),
                                  ]);
                                }}
                              >
                                <svg
                                  className="ml-[4px] cursor-pointer"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path
                                    d="M4.66797 4.66669L11.3346 11.3334"
                                    stroke="white"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M4.66927 11.3334L11.3359 4.66669"
                                    stroke="white"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </span>
                            </label>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col mt-2 px-5 py-2 pb-2 mx-2 mb-2 bg-defaultBg justify-between ">
                      <div className="flex flex-row relative">
                        <h6 className="cursor-pointer text-base font-medium not-italic pt-5 leading-[22px] text-gray-800 mt-[2px]">
                          <span>Job Title</span>
                        </h6>
                      </div>

                      <div className="relative">
                        <AutocompleteInput
                          idx={"jobtitle"}
                          classText={
                            "border border-[#DADAE4] md:h-[35px] h-[25px] mt-2 lg:h-[45px]  bg-[#FFF] rounded-[8px]  py-[5px] px-[10px] shadow "
                          }
                          list={jobSearchList}
                          setvalue={(e) => {
                            setCurrentPage(1);
                            setlistjobtitle(e);
                          }}
                          initialArray={listjobtitle}
                          fetchJobSuggestions={fetchJobSuggestions}
                        />

                        <div className="flex flex-wrap mt-3 gap-2">
                          {listjobtitle?.map((item, i) => (
                            <label
                              className="flex items-center py-[5px] px-[8px] border bg-[#00AC85] rounded-[4px]"
                              key={i}
                            >
                              <span className="text-[#fff] text-[14px] font-normal not-italic">
                                {item}
                              </span>
                              <span
                                onClick={() => {
                                  setCurrentPage(1);
                                  setlistjobtitle([
                                    ...listjobtitle.filter((i) => i !== item),
                                  ]);
                                }}
                              >
                                <svg
                                  className="ml-[4px] cursor-pointer"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path
                                    d="M4.66797 4.66669L11.3346 11.3334"
                                    stroke="white"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M4.66927 11.3334L11.3359 4.66669"
                                    stroke="white"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </span>
                            </label>
                          ))}
                        </div>
                        <div className="mt-3 pt-[12px] pb-[14px]">
                          <div className="flex flex-col">
                            <span className="cursor-pointer text-base font-medium not-italic leading-[22px] text-gray-800 mb-[5px] pt-0 ">
                              Company Name
                            </span>
                            {/* <input
                              type="text"
                              className="border border-[#DADAE4] md:h-[35px] h-[25px] lg:h-[45px] mt-[2px] bg-[#FFF] py-1 px-[10px] rounded-md shadow-sm placeholder-gray-600 text-base"
                              value={companyName}
                              onChange={(e) => {
                                setCurrentPage(1);
                                setCompanyName(e.target.value)
                              }
                              }
                              onKeyPress={(e) => {
                                if (e.key === "Enter" && companyName.trim()) {
                                  setCurrentPage(1);
                                  setListCompanyNames((prevList) => [
                                    ...prevList,
                                    companyName.trim(),
                                  ]);
                                  setCompanyName("");
                                }
                              }}
                              placeholder="Enter the Company Name"
                            /> */}
                            <div className="relative">
                              <AutocompleteInput
                                idx={"companyName"}
                                classText={
                                  "border border-[#DADAE4] md:h-[35px] h-[25px] lg:h-[45px] mt-[2px] bg-[#FFF] py-1 px-[10px] rounded-md shadow-sm placeholder-gray-600 text-base"
                                }
                                list={listCompanyNames} // Assuming you have a list of company names
                                setvalue={(e) => {
                                  setCurrentPage(1);
                                  setListCompanyNames(e);
                                  setCompanyName(""); // Clear the input field
                                }}
                                initialArray={listCompanyNames} // Assuming this is the state holding the selected company names
                                placeholder="Enter the Company Name" // Assuming AutocompleteInput supports a placeholder prop
                              />
                            </div>
                          </div>

                          <div className="flex flex-wrap gap-2 mt-3">
                            {listCompanyNames?.map((item, i) => (
                              <label
                                className="flex items-center py-[5px] px-[8px] border bg-[#00AC85] rounded-[4px]"
                                key={i}
                              >
                                <span className="text-[#fff] text-[14px] font-normal not-italic">
                                  {item}
                                </span>
                                <span
                                  onClick={() =>
                                    setListCompanyNames([
                                      ...listCompanyNames.filter(
                                        (i) => i !== item,
                                      ),
                                    ])
                                  }
                                >
                                  <svg
                                    className="ml-[4px] cursor-pointer"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                  >
                                    <path
                                      d="M4.66797 4.66669L11.3346 11.3334"
                                      stroke="white"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M4.66927 11.3334L11.3359 4.66669"
                                      stroke="white"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </span>
                              </label>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* </main> */}

              {/* </div> */}
            </main>
          </div>

          <div className="bg-white bottom-0 h-2 "></div>
        </div>
      </div>
    </div>
  );
}

// const DashboardContent = ({
//   jobs,
//   sort,
//   filters,
//   getCandidateDashboard,
//   ...props
// }) => {
//   const [jobIndex, setJobIndex] = useState([]);

//   const timeDifference = (difference) => {
//     if (difference < 1000) {
//       return "Just now";
//     } else if (difference < 60000) {
//       return `${Math.floor(difference / 1000)} seconds ago`;
//     } else if (difference < 3600000) {
//       return `${Math.floor(difference / 60000)} minutes ago`;
//     } else if (difference < 86400000) {
//       return `${Math.floor(difference / 3600000)} hours ago`;
//     } else if (difference < 2592000000) {
//       return `${Math.floor(difference / 86400000)} days ago`;
//     } else if (difference < 31536000000) {
//       return `${Math.floor(difference / 2592000000)} months ago`;
//     } else {
//       return `${Math.floor(difference / 31536000000)} years ago`;
//     }
//   };

//   async function applyJob(e, job_id) {
//     try {
//       e.preventDefault();
//       let response = await core.API(
//         core.API_METHODS.POST,
//         core.CANDIDATE_API.POST_APPLY_JOB + String(job_id),
//         1,
//         {},
//       );
//       console.log(response);
//       toast.success(response.data.message);
//       setJobIndex([...jobIndex, job_id]);
//     } catch (err) {
//       console.log(err);
//     }
//   }

//   const ApplyBtn = ({ applied, job_id }) => {
//     return jobIndex.includes(job_id) ? (
//       <h4 style={{ color: "green" }}>Applied</h4>
//     ) : (
//       <button
//         className="flex font-semibold bg-blue-800 hover:bg-blue-900 hover:scale-105 transition-all duration-300 text-white px-4 py-2 rounded-md"
//         onClick={(e) => {
//           applyJob(e, job_id);
//         }}
//       >
//         Apply
//         <IoSend className="text-white ml-[6px] mt-[3px] text-[16px]" />
//       </button>
//     );
//   };

//   async function bookmarkJob(job_id) {
//     try {
//       let response = await core.API(
//         core.API_METHODS.POST,
//         core.CANDIDATE_API.POST_SAVE_JOB + String(job_id),
//         1,
//         {},
//       );
//       reload_function();
//       toast.success(response.data.message);
//     } catch (err) {
//       console.log(err);
//     }
//   }

//   function parseHtml(html) {
//     const parsedHtml = parse(html.split("&lt;").join("<"));
//     return parsedHtml;
//   }

//   const [filterLocation, setFilterLocation] = useState([]);
//   const queryParams = queryString.parse(location.search);

//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalPage, setTotalPage] = useState(1);
//   const [loading, setLoading] = useState(false);
//   const [minExp, setMinExp] = useState(null);
//   const [maxExp, setMaxExp] = useState(null);
//   const [isLocationDropdownOpen, setIsLocationDropdownOpen] = useState(false);
//   const [locationRange, setLocationRange] = useState("25km");
//   const locationOptions = ["50km", "100km", "250km", "500km"];
//   const [jobRange, setJobRange] = useState("Select Range");
//   const [requiredInfo, setRequiredInfo] = useState([]);
//   const [jobrequiredInfo, setJobRequiredInfo] = useState([]);
//   const [listCompanyNames, setListCompanyNames] = useState([]);
//   const [showDropdown, setShowDropdown] = useState(false);
//   const [jobtypeDropdown, setJobtypeDropdown] = useState(false);
//   const [prompt, setPrompt] = useState("");
//   const [scaleLoading, setScaleLoading] = useState(false);
//   const [triggerFilterSubmit, setTriggerFilterSubmit] = useState(false);
//   const [companyName, setCompanyName] = useState("");
//   const [selectedOptions, setSelectedOptions] = useState([]);
//   const [jobTypeOptions, setJobTypeOptions] = useState([]);
//   const [jobsperpage, setJobsperPage] = useState([]);

//   const { user } = useContext(core.UserContext);
//   console.log("USer is :", user);

//   const [listdegrees, setListdegrees] = useState(
//     queryParams?.degrees?.length > 0 ? queryParams?.degrees?.split(",") : [],
//   );
//   const [listschoolname, setListschoolname] = useState([]);
//   const [degree, setDegree] = useState("");

//   const [listjobtitle, setlistjobtitle] = useState(
//     queryParams?.title?.length > 0 ? queryParams?.title?.split(",") : [],
//   );
//   const [listskill, setListskill] = useState(
//     queryParams?.skills?.length > 0 ? queryParams?.skills?.split(",") : [],
//   );
//   // const [showInputEducation, setShowInputEducation] = useState(false);
//   const [showInputExperience, setShowInputExperience] = useState(false);

//   const [isSidebarOpen, setIsSidebarOpen] = useState(true);

//   const toggleSidebar = () => {
//     setIsSidebarOpen(!isSidebarOpen);
//   };

//   const candidatesPerPage = 20;
//   const [Locations, setLocations] = useState([]);
//   const dropdownRef = useRef(null);

//   const handlePageClick = (data) => {
//     // console.log(data?.selected);
//     setCurrentPage(data?.selected + 1);
//     setDetailsVisibility({});
//     // scrollToComponent();
//   };

//   // const scrollToComponent = () => {
//   //   dropdownRef.current.scrollIntoView({
//   //     behavior: "smooth",
//   //   });
//   // };

//   // useEffect(() => {
//   //   handleFilterSubmit(currentPage);
//   // }, [currentPage]);

//   useEffect(() => {
//     handleFilterSubmit();
//   }, []);

//   const [detailsVisibility, setDetailsVisibility] = useState({});

//   const timeoutId = useRef(null);
//   const [listlocation, setListlocation] = useState(
//     queryParams?.location?.length > 0 ? queryParams?.location?.split(",") : [],
//   );

//   const [listcordinates, setListcordinates] = useState(
//     queryParams?.lat?.length > 0 &&
//       queryParams?.lon?.length > 0 &&
//       queryParams?.location?.length > 0
//       ? queryParams?.lat?.split(",").map((lat, i) => ({
//         lat: lat,
//         lon: queryParams?.lon?.split(",")[i],
//         name: queryParams?.location?.split(",")[i],
//       }))
//       : [],
//   );

//   const getLocationSuggestions = async (val) => {
//     try {
//       clearTimeout(timeoutId.current);
//       setLoading((prev) => ({ ...prev, location: true }));

//       timeoutId.current = setTimeout(async () => {
//         let response = await core.API(
//           core.API_METHODS.GET,
//           core.SUGGESTION_API.GET_SUGGESTION + "?q=" + val + "&format=json",
//           1,
//         );
//         if (response) {
//           setLocations(response);
//         }
//         setLoading((prev) => ({ ...prev, location: false }));
//       }, 500);
//     } catch (err) {
//       console.log(err);
//     }
//   };

//   const requestData = {
//     location: listlocation,
//     experienceRange: [minExp, maxExp],
//     requiredContact: requiredInfo,
//     jobType: jobrequiredInfo,
//     skills: listskill,
//     education: listdegrees,
//     jobPositionNames: listjobtitle,
//     companies: listCompanyNames,
//     page: currentPage,
//     pageSize: 20,
//     sort: "Date posted",
//   };

//   console.log("Request Data:", requestData);

//   const handleFilterSubmit = async () => {
//     try {
//       setScaleLoading(true);
//       const result = await core.API(
//         core.API_METHODS.POST,
//         core.CANDIDATE_API.GET_CANDIDATE_DASHBOARD,
//         1,
//         {
//           location: listlocation,
//           experienceRange: [minExp, maxExp],
//           requiredContact: requiredInfo,
//           jobType: jobrequiredInfo,
//           skills: listskill,
//           education: listdegrees,
//           jobPositionNames: listjobtitle,
//           companies: listCompanyNames,
//           page: currentPage,
//           pageSize: 20,
//           sort: "Date posted",
//         },
//       );
//       console.log("Response is : ", result);
//       const message = result.message;
//       setTotalPage(result?.data?.pagination?.totalPages);
//       setJobsperPage(result.data.jobs);
//       // console.log("Jobs are : ", result?.data?.jobs);
//       // console.log("Jobs are  throught job post : ", jobsperpage);

//       setScaleLoading(false);
//     } catch (error) {
//       console.error("Error fetching filtered candidates:", error);
//       setScaleLoading(false);
//     }
//   };

//   // useEffect(() => {
//   //   console.log("Jobs are (after setJobpost):", jobpost);
//   // }, [jobpost]);

//   const addLocation = (location) => {
//     const newLocation = { ...location, id: new Date().getTime() };
//     setListcordinates((prev) => [...prev, newLocation]);
//   };

//   const removeLocation = (id) => {
//     setListcordinates((prev) => prev.filter((item) => item.id !== id));
//     setListlocation((prev) => {
//       const itemToRemove = listcordinates.find((item) => item.id === id);
//       if (itemToRemove) {
//         return prev.filter((loc) => loc !== itemToRemove.name);
//       }
//       return prev;
//     });

//     setTriggerFilterSubmit((prev) => !prev);
//   };

//   const [isValueSelected, setIsValueSelected] = useState(false);

//   const handleSelectOption = (option) => {
//     setLocationRange(option);
//     setIsLocationDropdownOpen(false);
//     setIsValueSelected(true);
//   };

//   const handleToggleDropdown = () => {
//     setIsLocationDropdownOpen(!isLocationDropdownOpen);
//     if (isLocationDropdownOpen && !isValueSelected) {
//       setIsValueSelected(false);
//     }
//   };

//   const handleClear = () => {
//     setListCompanyNames([]);
//     setListcordinates([]);
//     setIsValueSelected(false);
//     setLocationRange("25km");
//     setMinExp(null);
//     setMaxExp(null);
//     setSelectedOptions([]);
//     setJobTypeOptions([]);
//     setJobRequiredInfo([]);
//     setListskill([]);
//     setlistjobtitle([]);
//     setJobRange("Select Range");
//     setListlocation([]);
//     setRequiredInfo([]);
//     setListschoolname([]);
//     setListdegrees([]);
//     setPrompt("");
//     // setShowFilter(false);
//   };

//   const handleOptionClick = (option) => {
//     setSelectedOptions((prevOptions) =>
//       prevOptions.includes(option)
//         ? prevOptions.filter((item) => item !== option)
//         : [...prevOptions, option],
//     );
//     setRequiredInfo((prevInfo) =>
//       prevInfo.includes(option)
//         ? prevInfo.filter((item) => item !== option)
//         : [...prevInfo, option],
//     );
//     setShowDropdown(false);
//   };

//   const handlejobTypeClick = (option) => {
//     setJobTypeOptions((prevOptions) =>
//       prevOptions.includes(option)
//         ? prevOptions.filter((item) => item !== option)
//         : [...prevOptions, option],
//     );
//     setJobRequiredInfo((prevInfo) =>
//       prevInfo.includes(option)
//         ? prevInfo.filter((item) => item !== option)
//         : [...prevInfo, option],
//     );
//     setJobtypeDropdown(false);
//   };

//   const [bookmarkedJobs, setBookmarkedJobs] = useState(() => {
//     // Initialize state with saved jobs (if needed)
//     return jobs.filter(job => job?.saved).map(job => job?.id);
//   });

//   const toggleBookmark = (jobId) => {
//     setBookmarkedJobs((prev) =>
//       prev.includes(jobId)
//         ? prev.filter(id => id !== jobId)  // Remove from bookmarks
//         : [...prev, jobId]                 // Add to bookmarks
//     );
//   };

//   useEffect(() => {
//     handleFilterSubmit();
//   }, [
//     locationRange,
//     minExp,
//     maxExp,
//     listskill,
//     listCompanyNames,
//     listdegrees,
//     listjobtitle,
//     triggerFilterSubmit,
//     jobrequiredInfo,
//     requiredInfo,
//     currentPage,
//     jobRange,
//   ]);

//   useEffect(() => {
//     const updatedCoordinates = listlocation.map((loc) => ({
//       name: loc,
//       id: new Date().getTime() + Math.random(), // Ensure unique ID
//     }));
//     setListcordinates(updatedCoordinates);
//   }, [listlocation]);

//   // console.log("Jobposts are : ", jobsperpage);
//   const [jobSearchList, setJobSearchList] = useState([]);

//   const fetchJobSuggestions = async (query) => {
//     if (query.length <= 2) {
//       setJobSearchList([]);
//       return;
//     }
//     try {
//       const apiUrl = `${core.RECRUITER_API.GET_JOBS_SUGGESTIONS}?query=${query}`;
//       console.log("API URL is:", apiUrl);

//       const resultfromcore = await core.API(core.API_METHODS.GET, apiUrl, 1);

//       // Add the user's input to the suggestions list
//       setJobSearchList([...new Set([query, ...resultfromcore])]);
//       // console.log("Job Search List is : ", jobSearchList);
//       setJobSearchList([...new Set(resultfromcore)]);
//     } catch (error) {
//       console.error("Error fetching skill suggestions:", error);
//       setJobSearchList([]);
//     }
//   };

//   const [searchList, setSearchList] = useState([]);

//   const fetchSuggestions = async (query) => {
//     if (query.length <= 2) {
//       setSearchList([query]);
//       return;
//     }
//     try {
//       const apiUrl = `${core.RECRUITER_API.GET_SKILLS_SUGGESTIONS}?query=${query}`;
//       console.log("API URL is:", apiUrl);

//       const resultfromcore = await core.API(core.API_METHODS.GET, apiUrl, 1);

//       // Add the user's input to the suggestions list
//       setSearchList([...new Set([query, ...resultfromcore])]);
//     } catch (error) {
//       console.error("Error fetching skill suggestions:", error);
//       setSearchList([query]);
//     }
//   };

//   return (
//     <>

//       <main className="relative flex  w-full h-fit  pt-1 pl-2 ml-1 mb-4 pb-2 rounded-tl-2xl overflow-y-auto bg-[#F3F5F9] ">
//         {/* <div className="h-full mb-2 overflow-y-auto " id="dashboard"> */}
//         {/* <main className=" w-full flex flex-row mb-2"> */}

//         <div
//           id="job-list"
//           className="flex flex-col w-[69.5%] mx-2 mt-2  h-[calc(95vh-56px)] mb-4 overflow-y-auto scrollbar-hide "
//         >
//           {scaleLoading ? (
//             <div className="w-full min-h-full flex items-center justify-center">
//               <div className="auto-complete-list mt-0 absolute no-list-style w-[69%] rounded-lg shadow-menuOptionShadow z-10 border top-[44px] flex items-center justify-center py-5">
//                 <ScaleLoader height={14} color="#1369E9" loading={true} />
//               </div>
//             </div>
//           ) : Array.isArray(jobsperpage) && jobsperpage.length > 0 ? (
//             <>
//               {jobsperpage.map((job, i) => (
//                 <div className="w-full bg-white shadow-sm rounded-lg p-6 mb-4 transition duration-300 hover:shadow-md">
//                   <div className="no-underline hover:no-underline flex justify-between">
//                     <div className="flex w-[85%] ">
//                       <div className="w-1/10">
//                         <InitialsLogo
//                           className="w-16 h-16 rounded-md overflow-hidden"
//                           str={job?.company?.name}
//                           width={64}
//                           height={64}
//                           bgRounded="8px"
//                           fontSize={28}
//                         />
//                       </div>
//                       <div className="ml-4 w-full" id="main">
//                         <div className="flex justify-between">
//                           <h2 className="flex text-xl font-semibold text-gray-700">
//                             {job?.company?.name}
//                             {job?.position && job?.company?.name ? " - " : null}
//                             {job?.position}
//                             {bookmarkedJobs.includes(job.id) || job?.saved ? (
//                               <FaBookmark
//                                 className="ml-2 mt-1 text-lime-600 hover:cursor-pointer hover:scale-105 transition-all duration-300"
//                                 onClick={() => {
//                                   bookmarkJob(job?.id);
//                                   toggleBookmark(job?.id);
//                                 }}
//                               />
//                             ) : (
//                               <FaRegBookmark
//                                 className="ml-2 mt-1 text-lime-600 hover:cursor-pointer hover:scale-105 transition-all duration-300"
//                                 onClick={() => {
//                                   bookmarkJob(job?.id);
//                                   toggleBookmark(job?.id);
//                                 }}
//                               />
//                             )}
//                           </h2>
//                           {job?.hiringStatus == "Actively hiring" ? (
//                             <div className="flex text-gray-700 font-semibold">
//                               <FcApproval className="text-lg mr-1" /> Actively
//                               Hiring
//                             </div>
//                           ) : null}
//                         </div>
//                         {job?.location?.[0] && (
//                           <p className="flex text-gray-600">
//                             <FaLocationDot className="mt-[3px] mr-2 text-gray-600" />
//                             {typeof job.location[0] === "string"
//                               ? job.location[0]
//                               : job.location[0]?.name}
//                           </p>
//                         )}
//                         <div className="flex">
//                           {job?.experienceLevel && (
//                             <p className="flex text-gray-600">
//                               <FaBusinessTime className="mt-[3px] mr-2 text-gray-600" />
//                               Experience:{" "}
//                               {typeof job.experienceLevel === "string"
//                                 ? job.experienceLevel
//                                 : job.experienceLevel.min
//                                   ? `${job.experienceLevel.min}+ years`
//                                   : "Not specified"}
//                             </p>
//                           )}
//                           {job?.workplace && job?.workplace != "" && (

//                             <p className={`flex items-end ${job?.experienceLevel ? "ml-4 " : " "} justify-end text-gray-600`} ><MdLocationCity className="text-gray-600 mr-1 text-lg mb-[2px]" /> Workplace : {job?.workplace}</p>

//                           )}

//                         </div>
//                         {job?.description && (
//                           <div className="text-gray-700 my-2">
//                             {(() => {
//                               const sentences =
//                                 job.description.split(/(?<=\.)\s+/);
//                               const firstThreeSentences = sentences
//                                 .slice(0, 3)
//                                 .join(" ");
//                               if (firstThreeSentences.length <= 200) {
//                                 return parseHtml(firstThreeSentences);
//                               } else {
//                                 let truncated = job.description.slice(0, 200);
//                                 if (
//                                   truncated.charAt(truncated.length - 1) !== " "
//                                 ) {
//                                   const lastSpaceIndex =
//                                     truncated.lastIndexOf(" ");
//                                   truncated = job.description.slice(
//                                     0,
//                                     lastSpaceIndex,
//                                   );
//                                 }
//                                 return parseHtml(truncated + ".");
//                               }
//                             })()}
//                           </div>
//                         )}
//                         {/* <div className="flex flex-wrap items-center gap-2 mt-2">
//                           {job?.criterias
//                             ?.map((criteria) => criteria?.skills)
//                             .flat()
//                             .map((skill, index) => (
//                               <span
//                                 key={`${index}-${Date.now()}-${Math.random()}`}
//                           className="bg-[#00AC85] text-white text-sm font-semibold px-2 py-1 rounded"
//                               >
//                           {typeof skill === "string" ? skill : skill?.label}
//                         </span>
//                             ))}
//                       </div> */}
//                       </div>
//                     </div>
//                     <div
//                       className="w-[15%] flex flex-col justify-between "
//                       id="ending"
//                     >
//                       <div className="flex flex-col items-end justify-end">
//                         {job?.createdAt && (
//                           <p className="flex text-gray-600 mb-2 items-center text-right">
//                             <IoMdTime className="text-lg mr-1 text-gray-600" />
//                             {timeDifference(
//                               new Date() - new Date(job.createdAt),
//                             )}
//                           </p>
//                         )}
//                         {/* {job?.jobType?.length > 0 &&
//                           !(job?.jobType?.length === 1 && job?.jobType[0]?.trim() === "") && (
//                             <p className="flex items-end justify-end text-gray-600 mb-4 text-right">
//                               <MdOutlineWorkOutline className="text-lg mr-1 text-gray-600" />
//                               Job:{" "}
//                               {job.jobType
//                                 .filter((type) =>
//                                   typeof type === "string" ? true : type.status
//                                 )
//                                 .map((type) =>
//                                   typeof type === "string" ? type : type.type
//                                 )
//                                 .join(", ")}
//                             </p>
//                           )} */}
//                       </div>

//                       <div className="flex justify-end mt-auto">
//                         <ApplyBtn applied={false} job_id={job.id} />
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               ))}
//               <div className="flex justify-center relative bg-[#FFF] border-t border-t-[#EFF0F2] rounded-lg ">
//                 <ReactPaginate
//                   previousLabel={"<- Prev"}
//                   nextLabel={"Next ->"}
//                   breakLabel={"..."}
//                   pageCount={totalPage}
//                   marginPagesDisplayed={2}
//                   pageRangeDisplayed={3}
//                   onPageChange={handlePageClick}
//                   containerClassName={"pagination"}
//                   subContainerClassName={"pages pagination"}
//                   activeClassName={"active"}
//                   forcePage={currentPage - 1}
//                 />
//               </div>
//             </>
//           ) : (
//             <div className="w-full  min-h-full flex items-center justify-center flex-col ">
//               <span>
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   width="140"
//                   height="131"
//                   viewBox="0 0 140 131"
//                   fill="none"
//                 >
//                   <path
//                     d="M74.4084 129.006C109.686 129.006 138.286 100.406 138.286 65.0031C138.286 29.5998 109.686 1 74.4084 1C39.1311 1 10.5312 29.5998 10.5312 65.0031C10.5312 100.406 39.1311 129.006 74.4084 129.006Z"
//                     fill="#F1F3F9"
//                     stroke="#D6DCE8"
//                     strokeWidth="2"
//                     strokeMiterlimit="10"
//                   />
//                   <path
//                     d="M133.625 39.4324H24.3911C21.9973 39.4324 20.1074 37.5425 20.1074 35.1487V10.5805C20.1074 8.18673 21.9973 6.29688 24.3911 6.29688H133.499C135.893 6.29688 137.782 8.18673 137.782 10.5805V35.1487C137.908 37.5425 135.893 39.4324 133.625 39.4324Z"
//                     fill="white"
//                     stroke="#D6DCE8"
//                     strokeWidth="2"
//                     strokeMiterlimit="10"
//                   />
//                   <path
//                     d="M46.5665 22.8087C46.5665 25.5804 45.3066 28.1003 43.4168 29.8641C41.7789 31.376 39.5111 32.2579 37.1172 32.2579C34.7234 32.2579 32.4556 31.376 30.8177 29.8641C28.8019 28.1003 27.668 25.5804 27.668 22.8087C27.668 17.643 31.9516 13.3594 37.1172 13.3594C42.2829 13.3594 46.5665 17.643 46.5665 22.8087Z"
//                     fill="#D6DCE8"
//                   />
//                   <path
//                     d="M43.4174 29.8581C41.7795 31.37 39.5117 32.2519 37.1179 32.2519C34.7241 32.2519 32.4562 31.37 30.8184 29.8581C31.5743 29.6061 32.4562 29.2281 33.5901 28.7242C33.8421 28.5982 34.0941 28.3462 34.2201 28.0942C34.3461 27.9682 34.3461 27.7162 34.3461 27.5902V25.1964L34.2201 25.0704C33.9681 24.8184 33.8421 24.4405 33.8421 24.0625L33.5901 23.9365C32.9602 24.0625 33.0862 23.4326 32.8342 22.1726C32.8342 21.6687 32.8342 21.5427 33.0862 21.4167L33.3382 21.1647C32.2043 18.5189 32.7082 16.8811 33.9681 16.2511C33.5901 15.3692 33.5901 15.1172 33.5901 15.1172C33.5901 15.1172 36.11 15.4952 36.8659 15.3692C37.9998 15.1172 39.6377 15.3692 40.2676 16.8811C41.2756 17.259 41.6535 17.889 41.7795 18.6449C41.9055 19.7788 41.2756 21.0387 41.1496 21.4167C41.2756 21.5427 41.4016 21.6687 41.2756 22.0467C41.1496 23.1806 41.1496 23.9365 40.5196 23.8105L40.0157 24.9444C40.0157 25.0704 40.0157 25.0704 40.0157 25.1964C40.0157 25.3224 40.0157 25.7004 40.0157 27.7162C40.0157 27.9682 40.1416 28.2202 40.1416 28.3462C40.2676 28.5982 40.5196 28.7242 40.6456 28.8501C41.7795 29.2281 42.6615 29.6061 43.4174 29.8581Z"
//                     fill="white"
//                   />
//                   <path
//                     d="M41.1823 21.5427C41.1823 21.2804 41.0511 20.887 40.92 20.6248V20.4936C40.3955 19.4446 39.3464 19.0512 38.2973 19.0512C35.6747 18.92 35.4124 19.4446 34.4945 18.6578C34.7567 19.0512 34.7567 19.7068 34.3633 20.4936C34.1011 21.0182 33.5765 21.2804 33.052 21.4116C31.8718 18.6578 32.3963 16.9531 33.7077 16.2974C33.3143 15.3795 33.3143 15.1172 33.3143 15.1172C33.3143 15.1172 35.9369 15.5106 36.7237 15.3795C37.9039 15.1172 39.6087 15.3795 40.2643 16.9531C41.3134 17.3465 41.7068 18.0021 41.8379 18.7889C41.9691 19.7068 41.3134 21.0182 41.1823 21.5427Z"
//                     fill="#AAB2C5"
//                   />
//                   <path
//                     d="M40.1423 28.3461V28.85H34.2207V28.2201C34.3467 28.0941 34.3467 27.8421 34.3467 27.7161V25.3223L34.2207 25.1963V25.0703C34.3467 25.3223 34.4727 25.4483 34.7247 25.7003L36.6145 26.9602C36.9925 27.3381 37.6224 27.3381 38.1264 26.9602L39.8903 25.4483L40.0163 25.3223C40.0163 25.4483 40.0163 25.8263 40.0163 27.8421C39.8903 27.9681 40.0163 28.0941 40.1423 28.3461Z"
//                     fill="url(#paint0_linear_4307_81330)"
//                   />
//                   <path
//                     d="M77.5594 20.025H53.3693C52.7393 20.025 52.3613 19.521 52.3613 19.0171V15.8673C52.3613 15.2373 52.8653 14.8594 53.3693 14.8594H77.5594C78.1894 14.8594 78.5673 15.3633 78.5673 15.8673V19.0171C78.5673 19.647 78.1894 20.025 77.5594 20.025Z"
//                     fill="#1369E9"
//                   />
//                   <path
//                     d="M106.285 30.7359H53.3693C52.7393 30.7359 52.3613 30.232 52.3613 29.728V26.5782C52.3613 25.9483 52.8653 25.5703 53.3693 25.5703H106.285C106.915 25.5703 107.293 26.0743 107.293 26.5782V29.728C107.293 30.3579 106.789 30.7359 106.285 30.7359Z"
//                     fill="#D6DCE8"
//                   />
//                   <path
//                     d="M133.625 122.073H24.3911C21.9973 122.073 20.1074 120.183 20.1074 117.789V93.2212C20.1074 90.8273 21.9973 88.9375 24.3911 88.9375H133.499C135.893 88.9375 137.782 90.8273 137.782 93.2212V117.789C137.908 120.183 135.893 122.073 133.625 122.073Z"
//                     fill="white"
//                     stroke="#D6DCE8"
//                     strokeWidth="2"
//                     strokeMiterlimit="10"
//                   />
//                   <path
//                     d="M46.5665 105.449C46.5665 108.221 45.3066 110.741 43.4168 112.505C41.7789 114.017 39.5111 114.899 37.1172 114.899C34.7234 114.899 32.4556 114.017 30.8177 112.505C28.8019 110.741 27.668 108.221 27.668 105.449C27.668 100.284 31.9516 96 37.1172 96C42.2829 96 46.5665 100.284 46.5665 105.449Z"
//                     fill="#D6DCE8"
//                   />
//                   <path
//                     d="M43.4174 112.499C41.7795 114.011 39.5117 114.893 37.1179 114.893C34.7241 114.893 32.4562 114.011 30.8184 112.499C31.5743 112.247 32.4562 111.869 33.5901 111.365C33.8421 111.239 34.0941 110.987 34.2201 110.735C34.3461 110.609 34.3461 110.357 34.3461 110.231V107.837L34.2201 107.711C33.9681 107.459 33.8421 107.081 33.8421 106.703L33.5901 106.577C32.9602 106.703 33.0862 106.073 32.8342 104.813C32.8342 104.309 32.8342 104.183 33.0862 104.057L33.3382 103.805C32.2043 101.16 32.7082 99.5217 33.9681 98.8917C33.5901 98.0098 33.5901 97.7578 33.5901 97.7578C33.5901 97.7578 36.11 98.1358 36.8659 98.0098C37.9998 97.7578 39.6377 98.0098 40.2676 99.5217C41.2756 99.8996 41.6535 100.53 41.7795 101.286C41.9055 102.419 41.2756 103.679 41.1496 104.057C41.2756 104.183 41.4016 104.309 41.2756 104.687C41.1496 105.821 41.1496 106.577 40.5196 106.451L40.0157 107.459C40.0157 107.585 40.0157 107.585 40.0157 107.711C40.0157 107.837 40.0157 108.215 40.0157 110.231C40.0157 110.483 40.1416 110.735 40.1416 110.861C40.2676 111.113 40.5196 111.239 40.6456 111.365C41.7795 111.995 42.6615 112.247 43.4174 112.499Z"
//                     fill="white"
//                   />
//                   <path
//                     d="M41.1823 104.183C41.1823 103.921 41.0511 103.528 40.92 103.265V103.134C40.3955 102.085 39.3464 101.692 38.2973 101.692C35.6747 101.561 35.4124 102.085 34.4945 101.298C34.7567 101.692 34.7567 102.347 34.3633 103.134C34.1011 103.659 33.5765 103.921 33.052 104.052C31.8718 101.298 32.3963 99.5937 33.7077 98.938C33.3143 98.0201 33.3143 97.7578 33.3143 97.7578C33.3143 97.7578 35.9369 98.1512 36.7237 98.0201C37.9039 97.7578 39.6087 98.0201 40.2643 99.5937C41.3134 99.9871 41.7068 100.643 41.8379 101.43C41.9691 102.479 41.3134 103.659 41.1823 104.183Z"
//                     fill="#AAB2C5"
//                   />
//                   <path
//                     d="M40.1423 110.987V111.491H34.2207V110.861C34.3467 110.735 34.3467 110.483 34.3467 110.357V107.963L34.2207 107.837V107.711C34.3467 107.963 34.4727 108.089 34.7247 108.341L36.6145 109.601C36.9925 109.979 37.6224 109.979 38.1264 109.601L39.8903 108.089L40.0163 107.963C40.0163 108.089 40.0163 108.467 40.0163 110.483C39.8903 110.609 40.0163 110.861 40.1423 110.987Z"
//                     fill="url(#paint1_linear_4307_81330)"
//                   />
//                   <path
//                     d="M77.5594 102.798H53.3693C52.7393 102.798 52.3613 102.294 52.3613 101.79V98.6407C52.3613 98.0108 52.8653 97.6328 53.3693 97.6328H77.5594C78.1894 97.6328 78.5673 98.1368 78.5673 98.6407V101.79C78.5673 102.294 78.1894 102.798 77.5594 102.798Z"
//                     fill="#1369E9"
//                   />
//                   <path
//                     d="M106.285 113.509H53.3693C52.7393 113.509 52.3613 113.005 52.3613 112.501V109.352C52.3613 108.722 52.8653 108.344 53.3693 108.344H106.285C106.915 108.344 107.293 108.848 107.293 109.352V112.501C107.293 113.005 106.789 113.509 106.285 113.509Z"
//                     fill="#D6DCE8"
//                   />
//                   <path
//                     d="M115.104 80.7527H5.99656C3.60275 80.7527 1.71289 78.8628 1.71289 76.469V51.9009C1.71289 49.507 3.60275 47.6172 5.99656 47.6172H115.104C117.498 47.6172 119.388 49.507 119.388 51.9009V76.469C119.514 78.8628 117.498 80.7527 115.104 80.7527Z"
//                     fill="white"
//                     stroke="#D6DCE8"
//                     strokeWidth="2"
//                     strokeMiterlimit="10"
//                   />
//                   <path
//                     d="M59.1629 61.3453H34.9728C34.3428 61.3453 33.9648 60.8413 33.9648 60.3374V57.1876C33.9648 56.5577 34.4688 56.1797 34.9728 56.1797H59.1629C59.7929 56.1797 60.1708 56.6836 60.1708 57.1876V60.3374C60.1708 60.9673 59.7929 61.3453 59.1629 61.3453Z"
//                     fill="#1369E9"
//                   />
//                   <path
//                     d="M87.8887 72.1812H34.9728C34.3428 72.1812 33.9648 71.6773 33.9648 71.1733V68.0235C33.9648 67.3936 34.4688 67.0156 34.9728 67.0156H87.7628C88.3927 67.0156 88.7707 67.5196 88.7707 68.0235V71.1733C88.8967 71.6773 88.3927 72.1812 87.8887 72.1812Z"
//                     fill="#D6DCE8"
//                   />
//                   <path
//                     d="M28.296 64.129C28.296 66.7748 27.1621 69.1686 25.3983 70.9325C25.2723 71.0584 25.2723 71.0584 25.1463 71.1844C23.5084 72.6963 21.2406 73.5782 18.8468 73.5782C16.4529 73.5782 14.3111 72.6963 12.6732 71.3104C12.6732 71.3104 12.6732 71.3104 12.5472 71.3104C10.5314 69.5466 9.27148 67.0267 9.27148 64.129C9.27148 58.9634 13.5552 54.6797 18.7208 54.6797C24.0124 54.6797 28.296 58.9634 28.296 64.129Z"
//                     fill="#D6DCE8"
//                   />
//                   <path
//                     d="M25.3595 71.1269C24.9762 70.2326 24.3374 69.466 23.3152 68.9549C22.8042 68.6994 22.4209 68.5716 21.782 68.5716H21.3987C20.5044 68.5716 20.3766 68.4438 20.3766 68.4438V66.9106C21.3987 66.144 22.1653 65.1219 22.4209 63.972C23.0597 63.8442 23.443 63.3332 23.3152 62.6943C23.3152 62.4388 23.0597 62.1833 23.0597 61.9277C23.0597 61.8 23.0597 61.6722 23.0597 61.5444C23.0597 61.4167 23.0597 61.4167 23.0597 61.2889C23.0597 61.1611 23.0597 61.1611 23.0597 61.0334C23.0597 60.5223 22.9319 60.139 22.5486 59.6279C21.782 58.2225 20.2488 57.3281 18.5879 57.3281C18.2046 57.3281 17.949 57.3281 17.5657 57.4559C16.9269 57.5837 16.4158 57.8392 15.9048 58.2225C15.777 58.3503 15.777 58.3503 15.6492 58.478C15.6492 58.478 15.6492 58.478 15.5215 58.6058C15.0104 59.1169 14.6271 59.7557 14.3716 60.3945C14.116 61.0334 14.116 61.8 14.116 62.5666C14.116 62.5666 14.116 62.5666 14.116 62.6943C14.116 62.8221 14.116 62.8221 14.116 62.9499C14.116 62.9499 14.116 63.0776 13.9883 63.0776C14.2438 62.8221 14.116 63.2054 14.2438 63.4609C14.4993 64.0998 14.8826 63.972 15.3937 64.3553C15.3937 64.3553 15.3937 64.3553 15.2659 64.3553L14.7549 64.4831C11.944 65.3774 11.6885 68.3161 14.4993 68.9549C14.2438 69.0827 14.116 69.2104 13.9883 69.2104C13.2217 69.8493 12.7106 70.6159 12.4551 71.3825C14.116 72.7879 16.2881 73.6822 18.7156 73.6822C21.1432 73.6822 23.443 72.7879 25.104 71.2547C25.2317 71.3824 25.3595 71.2547 25.3595 71.1269C25.4873 71.1269 25.4873 71.1269 25.3595 71.1269ZM17.438 68.0605C17.3102 67.9327 17.1824 67.805 17.1824 67.805C17.0547 67.6772 16.9269 67.6772 16.7991 67.5495C16.7991 67.5495 16.6714 67.5494 16.6714 67.4217C16.2881 67.1662 15.9048 66.9106 15.777 66.5273C15.777 66.3996 15.9048 66.144 16.1603 65.8885C16.2881 66.0163 16.4158 66.144 16.5436 66.3996C16.6714 66.5273 16.7991 66.6551 16.9269 66.7829C17.0547 66.7829 17.1824 66.9106 17.1824 67.0384C17.1824 67.0384 17.1824 67.0384 17.3102 67.0384C17.3102 67.0384 17.3102 67.0384 17.438 67.0384V68.0605Z"
//                     fill="white"
//                   />
//                   <path
//                     d="M21.5523 68.4972C21.4263 69.0012 21.1744 69.3792 21.1744 69.3792L16.3867 68.6232L16.6387 67.3633C16.6387 67.3633 16.7647 67.3633 16.7647 67.4893C16.8907 67.6153 17.0167 67.6153 17.1427 67.7413C17.2687 67.7413 17.2687 67.8673 17.3946 67.9933V67.1114C17.3946 67.1114 17.3946 67.1114 17.2687 67.1114C18.0246 67.4893 19.0325 67.7413 20.4184 66.8594V68.3713C20.5444 68.3713 20.6704 68.4972 21.5523 68.4972Z"
//                     fill="url(#paint2_linear_4307_81330)"
//                   />
//                   <path
//                     d="M20.5601 61.4673C21.4589 61.4673 22.3577 61.3389 23.1281 61.0821C23.1281 61.0821 23.1281 60.9537 23.2565 60.8253C23.1281 60.44 22.9997 59.9264 22.7429 59.4128C21.9725 58.0004 20.4317 57.1016 18.7624 57.1016C18.3772 57.1016 18.0077 57.129 17.4925 57.2574C16.8069 57.467 16.4885 57.6512 16.3261 57.7496C16.3261 57.7496 15.9508 58.002 15.6808 58.2572C15.4107 58.5124 14.7819 59.4128 14.5251 60.0548C14.2683 60.8253 14.0611 61.4801 14.1895 62.2505C14.3179 62.2505 14.5251 62.1093 14.6535 62.1093C15.6808 61.5957 16.4512 60.9537 16.8364 59.9264C17.8636 60.8253 19.1476 61.3389 20.5601 61.4673Z"
//                     fill="#AAB2C5"
//                   />
//                   <path
//                     d="M16.2012 66.0156C16.3272 66.1416 16.4532 66.2676 16.5791 66.3936C16.4532 66.2676 16.3272 66.1416 16.2012 66.0156Z"
//                     fill="#3973F4"
//                   />
//                   <path
//                     d="M17.965 70.6745C17.5871 71.1785 16.9571 71.1785 16.3271 71.1785C16.9571 70.5485 16.5791 68.6587 14.5633 68.9107C11.6655 68.4067 12.0435 65.3829 14.8153 64.501L15.3192 64.375C15.3192 64.375 15.3192 64.375 15.4452 64.501C15.6972 65.1309 15.9492 65.5089 16.3271 66.0129C15.1932 67.0208 16.8311 67.2728 17.5871 68.0287C18.091 68.2807 18.595 69.9186 17.965 70.6745Z"
//                     fill="#AAB2C5"
//                   />
//                   <defs>
//                     <linearGradient
//                       id="paint0_linear_4307_81330"
//                       x1="37.1833"
//                       y1="28.873"
//                       x2="37.1833"
//                       y2="26.7908"
//                       gradientUnits="userSpaceOnUse"
//                     >
//                       <stop stopColor="white" />
//                       <stop offset="1" stopColor="#E2E5EC" />
//                     </linearGradient>
//                     <linearGradient
//                       id="paint1_linear_4307_81330"
//                       x1="37.1833"
//                       y1="111.514"
//                       x2="37.1833"
//                       y2="109.431"
//                       gradientUnits="userSpaceOnUse"
//                     >
//                       <stop stopColor="white" />
//                       <stop offset="1" stopColor="#E2E5EC" />
//                     </linearGradient>
//                     <linearGradient
//                       id="paint2_linear_4307_81330"
//                       x1="18.9711"
//                       y1="69.3945"
//                       x2="18.9711"
//                       y2="68.0064"
//                       gradientUnits="userSpaceOnUse"
//                     >
//                       <stop stopColor="white" />
//                       <stop offset="1" stopColor="#E2E5EC" />
//                     </linearGradient>
//                   </defs>
//                 </svg>
//               </span>
//               <span className="text-[#252E4B] font-medium text-[18px] leading-[17px] not-italic mt-[24px]">
//                 No Job Found
//               </span>
//             </div>
//           )}
//         </div>
//         <div className="w-[29%] mb-4 mx-2 mt-2 h-[calc(95vh-56px)]  overflow-y-scroll overflow-x-hidden scrollbar-hide">
//           {/* <Filter
//         jobs={jobs}
//         sort_option={sort}
//         filter_options={filters}
//         filter_function={getCandidateDashboard}
//         /> */}
//           <div
//             id="filter"
//             className="relative flex-grow-0 flex-shrink-0 bg-white  w-full flex-col sm:hidden md:flex overflow-y-auto"
//           >
//             <div className="flex flex-col h-full ">
//               <h6 className="flex flex-row items-center justify-between text-base font-medium not-italic pt-2 leading-[22px] text-gray-800 mt-[2px]">
//                 <div className="flex items-center">
//                   <BsFilterLeft className="font-extrabold text-gray-800 text-xl mr-2 ml-2 mt-[2px]" />
//                   Filters
//                 </div>
//                 <div className="flex items-center">
//                   <button
//                     onClick={handleClear}
//                     className="flex flex-row rounded-lg shadow-sm bg-blue-800 hover:bg-blue-900 text-white px-3 py-2 hover:cursor-pointer mr-2"
//                   >
//                     Clear <MdDelete className="ml-1 text-white text-lg" />
//                   </button>
//                 </div>
//               </h6>

//               <div className="flex flex-col  mx-2 mt-2 px-5 py-2 pb-4 bg-defaultBg justify-between ">
//                 <div className="flex flex-row ">
//                   <h6 className="cursor-pointer text-base font-medium not-italic pt-5 leading-[22px] text-gray-800 mt-1">
//                     <span>Location(s)</span>
//                   </h6>
//                 </div>
//                 <div className="relative ">
//                   <AutoCompleteInputLocation
//                     loading={loading.location}
//                     idx={"location"}
//                     list={Locations || []}
//                     classText={
//                       "border border-[#DADAE4] md:h-[35px] h-[25px] mt-2 lg:h-[45px] bg-[#FFF] rounded-[8px] py-[3px] px-[10px] shadow"
//                     }
//                     setvalue={(e) => {
//                       setCurrentPage(1);
//                       setListlocation(e);
//                       setFilterLocation(e[e.length - 1]);
//                     }}
//                     addLocation={addLocation}
//                     initialArray={listlocation}
//                     autoSuggestion={true}
//                     onInputChange={(e) => {
//                       getLocationSuggestions(e);
//                     }}
//                     handleFilterSubmit={handleFilterSubmit}
//                   />
//                 </div>
//                 <div className="flex flex-wrap mt-3 gap-2">
//                   {listcordinates?.map((item) => (
//                     <label
//                       className="flex items-center py-[5px] px-[8px] border bg-[#00AC85] rounded-[4px]"
//                       key={item.id} // Use unique id as key
//                     >
//                       <span className="text-[#fff] text-[14px] font-normal not-italic">
//                         {item.name}
//                       </span>
//                       <span onClick={() => removeLocation(item.id)}>
//                         <svg
//                           className="ml-[4px] cursor-pointer"
//                           xmlns="http://www.w3.org/2000/svg"
//                           width="16"
//                           height="16"
//                           viewBox="0 0 16 16"
//                           fill="none"
//                         >
//                           <path
//                             d="M4.66797 4.66669L11.3346 11.3334"
//                             stroke="white"
//                             strokeLinecap="round"
//                             strokeLinejoin="round"
//                           />
//                           <path
//                             d="M4.66927 11.3334L11.3359 4.66669"
//                             stroke="white"
//                             strokeLinecap="round"
//                             strokeLinejoin="round"
//                           />
//                         </svg>
//                       </span>
//                     </label>
//                   ))}
//                 </div>

//                 <div className="flex justify-between space-x-2 mt-3 py-[12px]">
//                   <h6 className="cursor-pointer text-base font-medium not-italic leading-[22px] text-gray-800 mb-[5px]">
//                     <span
//                       onClick={() => {

//                         setCurrentPage(1);
//                         setShowInputExperience(!showInputExperience)
//                       }
//                       }
//                     >
//                       Min Experience
//                     </span>
//                     <input
//                       className="rounded-md w-full shadow-sm border border-[#DADAE4] p-2 mt-2 md:h-[35px] h-[25px] lg:h-[45px] MuiInputBase-input MuiFilledInput-input css-2bxn45 placeholder-gray-500"
//                       aria-invalid="false"
//                       autoComplete="off"
//                       name="yearsOfExp"
//                       placeholder="Ex: 2 years"
//                       type="number"
//                       value={minExp !== null ? minExp : ""}
//                       onChange={(e) => setMinExp(e.target.value)}
//                     />
//                   </h6>
//                   <h6 className="cursor-pointer text-base font-medium not-italic leading-[22px] text-gray-800 mb-[5px]">
//                     <span
//                       onClick={() => {
//                         setCurrentPage(1);
//                         setShowInputExperience(!showInputExperience)
//                       }
//                       }
//                     >
//                       Max Experience
//                     </span>
//                     <input
//                       className="rounded-md w-full shadow-sm border border-[#DADAE4] p-2 mt-2 md:h-[35px] h-[25px] lg:h-[45px] MuiInputBase-input MuiFilledInput-input css-2bxn45 placeholder-gray-500"
//                       aria-invalid="false"
//                       autoComplete="off"
//                       name="yearsOfExp"
//                       placeholder="Ex: 10 years"
//                       type="number"
//                       value={maxExp !== null ? maxExp : ""}
//                       onChange={(e) => setMaxExp(e.target.value)}
//                     />
//                   </h6>
//                 </div>
//                 <div className="mt-3 pb-3 relative">
//                   <h6 className="cursor-pointer text-base font-medium not-italic leading-[22px] text-gray-800 mb-[3px]">
//                     <span>Workplace Type</span>
//                   </h6>
//                   <div
//                     className="flex w-full items-center shadow-sm border border-[#DADAE4] rounded-md p-2 mt-2 md:h-[35px] h-[25px] lg:h-[45px] bg-white cursor-pointer placeholder-gray-600"
//                     onClick={() => setShowDropdown(!showDropdown)}
//                   >
//                     {selectedOptions.length > 0 &&
//                       selectedOptions.map((option, index) => (
//                         <span
//                           key={index}
//                           className="bg-[#00AC85] text-white px-2 py-1 rounded-md mr-2"
//                         >
//                           {option}
//                         </span>
//                       ))}
//                     <span className="text-gray-600 text-base">
//                       {selectedOptions.length === 0 && "Select Workplace"}
//                     </span>
//                     <span className="ml-auto text-gray-700 text-md mr-2">
//                       <FaCaretDown
//                         className={showDropdown ? "rotate-180" : ""}
//                       />
//                     </span>
//                   </div>
//                   {showDropdown && (
//                     <div className="absolute z-10 bg-white border border-gray-300 rounded-md shadow-lg mt-1 w-full">
//                       <div
//                         className="block px-4 py-2 border border-gray-300 text-sm text-gray-700 cursor-pointer hover:bg-defaultBg"
//                         onClick={() => {
//                           setCurrentPage(1);
//                           handleOptionClick("On-Site")
//                         }
//                         }
//                       >
//                         On-Site
//                       </div>
//                       <div
//                         className="block px-4 py-2 border border-gray-300 text-sm text-gray-700 cursor-pointer hover:bg-defaultBg"
//                         onClick={() => {
//                           setCurrentPage(1);
//                           handleOptionClick("Remote")
//                         }
//                         }
//                       >
//                         Remote
//                       </div>
//                       <div
//                         className="block px-4 py-2 border border-gray-300 text-sm text-gray-700 cursor-pointer hover:bg-defaultBg"
//                         onClick={() => {
//                           setCurrentPage(1);
//                           handleOptionClick("Hybrid")
//                         }
//                         }
//                       >
//                         Hybrid
//                       </div>
//                     </div>
//                   )}
//                 </div>
//               </div>

//               <div className="flex flex-col px-5 py-2 pb-6 bg-defaultBg justify-between mx-2  mt-2">
//                 <div className="mt-3 pb-3 relative">
//                   <h6 className="cursor-pointer text-base font-medium not-italic leading-[22px] text-gray-800 mb-[3px]">
//                     <span>Job Type</span>
//                   </h6>
//                   <div
//                     className={`flex w-full items-center shadow-sm border border-[#DADAE4] rounded-md p-2 mt-2 bg-white cursor-pointer placeholder-gray-600 flex-wrap ${jobTypeOptions.length > 3
//                       ? "md:h-[70px] h-[50px] lg:h-[90px]"
//                       : "md:h-[35px] h-[25px] lg:h-[45px]"
//                       }`}
//                     onClick={() => setJobtypeDropdown(!jobtypeDropdown)}
//                   >
//                     {jobTypeOptions.length > 0 &&
//                       jobTypeOptions.map((option, index) => (
//                         <span
//                           key={index}
//                           className="bg-[#00AC85] text-white px-2 py-1 rounded-md mr-2"
//                         >
//                           {option}
//                         </span>
//                       ))}
//                     <span className="text-gray-600 text-base">
//                       {jobTypeOptions.length === 0 && "Select Job Type"}
//                     </span>
//                     <span className="ml-auto text-gray-700 text-md mr-2">
//                       <FaCaretDown
//                         className={jobtypeDropdown ? "rotate-180" : ""}
//                       />
//                     </span>
//                   </div>
//                   {jobtypeDropdown && (
//                     <div className="absolute z-10 bg-white border border-gray-300 rounded-md shadow-lg mt-1 w-full">
//                       <div
//                         className="block px-4 py-2 border border-gray-300 text-sm text-gray-700 cursor-pointer hover:bg-defaultBg"
//                         onClick={() => {
//                           setCurrentPage(1);
//                           handlejobTypeClick("Full Time")
//                         }
//                         }
//                       >
//                         Full Time
//                       </div>
//                       <div
//                         className="block px-4 py-2 border border-gray-300 text-sm text-gray-700 cursor-pointer hover:bg-defaultBg"
//                         onClick={() => {
//                           setCurrentPage(1);
//                           handlejobTypeClick("Part Time")
//                         }
//                         }
//                       >
//                         Part Time
//                       </div>
//                       <div
//                         className="block px-4 py-2 border border-gray-300 text-sm text-gray-700 cursor-pointer hover:bg-defaultBg"
//                         onClick={() => {
//                           setCurrentPage(1);
//                           handlejobTypeClick("Contract")
//                         }
//                         }
//                       >
//                         Contract
//                       </div>
//                       <div
//                         className="block px-4 py-2 border border-gray-300 text-sm text-gray-700 cursor-pointer hover:bg-defaultBg"
//                         onClick={() => {
//                           setCurrentPage(1);
//                           handlejobTypeClick("Internship")
//                         }
//                         }
//                       >
//                         Internship
//                       </div>
//                       <div
//                         className="block px-4 py-2 border border-gray-300 text-sm text-gray-700 cursor-pointer hover:bg-defaultBg"
//                         onClick={() => {
//                           setCurrentPage(1);
//                           handlejobTypeClick("Volunteer")
//                         }
//                         }
//                       >
//                         Volunteer
//                       </div>
//                     </div>
//                   )}
//                 </div>
//                 <div className="flex flex-row ">
//                   <h6 className="cursor-pointer text-base font-medium not-italic pt-5 leading-[22px] text-gray-800 mt-1">
//                     <span>Skills</span>
//                   </h6>
//                 </div>
//                 <div className="relative ">
//                   <AutocompleteInput
//                     idx={"skillsdata"}
//                     classText={
//                       "border border-[#DADAE4] md:h-[35px] h-[25px] lg:h-[45px] mt-2 bg-[#FFF] py-1 px-[10px] rounded-md"
//                     }
//                     list={searchList} // Pass the fetched suggestions
//                     setvalue={(e) => {
//                       setCurrentPage(1);
//                       setListskill(e);
//                     }}
//                     initialArray={listskill}
//                     fetchSuggestions={fetchSuggestions}
//                   />
//                 </div>
//                 <div className="flex flex-wrap mt-3 gap-2">
//                   {listskill?.map((item, i) => (
//                     <label
//                       className="flex items-center py-[5px] px-[8px] border bg-[#00AC85] rounded-[4px]"
//                       key={i}
//                     >
//                       <span className="text-[#fff] text-[14px] font-normal not-italic">
//                         {item}
//                       </span>
//                       <span
//                         onClick={() => {
//                           setCurrentPage(1);
//                           setListskill([...listskill.filter((i) => i !== item)])
//                         }
//                         }
//                       >
//                         <svg
//                           className="ml-[4px] cursor-pointer"
//                           xmlns="http://www.w3.org/2000/svg"
//                           width="16"
//                           height="16"
//                           viewBox="0 0 16 16"
//                           fill="none"
//                         >
//                           <path
//                             d="M4.66797 4.66669L11.3346 11.3334"
//                             stroke="white"
//                             strokeLinecap="round"
//                             strokeLinejoin="round"
//                           />
//                           <path
//                             d="M4.66927 11.3334L11.3359 4.66669"
//                             stroke="white"
//                             strokeLinecap="round"
//                             strokeLinejoin="round"
//                           />
//                         </svg>
//                       </span>
//                     </label>
//                   ))}
//                 </div>
//                 <div className="relative">
//                   <div className="pt-5 pb-3">
//                     <span className="cursor-pointer text-base font-medium not-italic leading-[22px] text-gray-800 mb-2">
//                       Degree Required
//                     </span>
//                     <div className="relative">
//                       <AutocompleteInput
//                         idx={"degree"}
//                         classText={
//                           "border border-[#DADAE4] md:h-[35px] h-[25px] mt-2 lg:h-[45px]  bg-[#FFF] rounded-[8px]  py-[5px] px-[10px] shadow"
//                         }
//                         list={[
//                           "Associate",
//                           "Bachelor",
//                           "Master",
//                           "Doctorate",
//                           "Professional",
//                         ]}
//                         setvalue={setListdegrees}
//                         initialArray={listdegrees}
//                       />
//                     </div>
//                   </div>
//                   <div className="flex flex-wrap gap-2">
//                     {listdegrees?.map((item, i) => (
//                       <label
//                         className="flex items-center py-[5px] px-[8px] border bg-[#00AC85] rounded-[4px]"
//                         key={i}
//                       >
//                         <span className="text-[#fff] text-[14px] font-normal not-italic">
//                           {item}
//                         </span>
//                         <span
//                           onClick={() => {
//                             setCurrentPage(1);
//                             setListdegrees([
//                               ...listdegrees.filter((i) => i !== item),
//                             ])
//                           }
//                           }
//                         >
//                           <svg
//                             className="ml-[4px] cursor-pointer"
//                             xmlns="http://www.w3.org/2000/svg"
//                             width="16"
//                             height="16"
//                             viewBox="0 0 16 16"
//                             fill="none"
//                           >
//                             <path
//                               d="M4.66797 4.66669L11.3346 11.3334"
//                               stroke="white"
//                               strokeLinecap="round"
//                               strokeLinejoin="round"
//                             />
//                             <path
//                               d="M4.66927 11.3334L11.3359 4.66669"
//                               stroke="white"
//                               strokeLinecap="round"
//                               strokeLinejoin="round"
//                             />
//                           </svg>
//                         </span>
//                       </label>
//                     ))}
//                   </div>
//                 </div>
//               </div>
//               <div className="flex flex-col mt-2 px-5 py-2 pb-2 mx-2 mb-2 bg-defaultBg justify-between ">
//                 <div className="flex flex-row relative">
//                   <h6 className="cursor-pointer text-base font-medium not-italic pt-5 leading-[22px] text-gray-800 mt-[2px]">
//                     <span>Job Title</span>
//                   </h6>
//                 </div>

//                 <div className="relative">
//                   <AutocompleteInput
//                     idx={"jobtitle"}
//                     classText={
//                       "border border-[#DADAE4] md:h-[35px] h-[25px] mt-2 lg:h-[45px]  bg-[#FFF] rounded-[8px]  py-[5px] px-[10px] shadow "
//                     }
//                     list={jobSearchList}

//                     setvalue={(e) => {
//                       setCurrentPage(1);
//                       setlistjobtitle(e);
//                     }}
//                     initialArray={listjobtitle}
//                     fetchJobSuggestions={fetchJobSuggestions}
//                   />

//                   <div className="flex flex-wrap mt-3 gap-2">
//                     {listjobtitle?.map((item, i) => (
//                       <label
//                         className="flex items-center py-[5px] px-[8px] border bg-[#00AC85] rounded-[4px]"
//                         key={i}
//                       >
//                         <span className="text-[#fff] text-[14px] font-normal not-italic">
//                           {item}
//                         </span>
//                         <span
//                           onClick={() => {
//                             setCurrentPage(1);
//                             setlistjobtitle([
//                               ...listjobtitle.filter((i) => i !== item),
//                             ])
//                           }
//                           }
//                         >
//                           <svg
//                             className="ml-[4px] cursor-pointer"
//                             xmlns="http://www.w3.org/2000/svg"
//                             width="16"
//                             height="16"
//                             viewBox="0 0 16 16"
//                             fill="none"
//                           >
//                             <path
//                               d="M4.66797 4.66669L11.3346 11.3334"
//                               stroke="white"
//                               strokeLinecap="round"
//                               strokeLinejoin="round"
//                             />
//                             <path
//                               d="M4.66927 11.3334L11.3359 4.66669"
//                               stroke="white"
//                               strokeLinecap="round"
//                               strokeLinejoin="round"
//                             />
//                           </svg>
//                         </span>
//                       </label>
//                     ))}
//                   </div>
//                   <div className="mt-3 pt-[12px] pb-[14px]">
//                     <div className="flex flex-col">
//                       <span className="cursor-pointer text-base font-medium not-italic leading-[22px] text-gray-800 mb-[5px] pt-0 ">
//                         Company Name
//                       </span>
//                       <input
//                         type="text"
//                         className="border border-[#DADAE4] md:h-[35px] h-[25px] lg:h-[45px] mt-[2px] bg-[#FFF] py-1 px-[10px] rounded-md shadow-sm placeholder-gray-600 text-base"
//                         value={companyName}
//                         onChange={(e) => {
//                           setCurrentPage(1);
//                           setCompanyName(e.target.value)
//                         }
//                         }
//                         onKeyPress={(e) => {
//                           if (e.key === "Enter" && companyName.trim()) {
//                             setCurrentPage(1);
//                             setListCompanyNames((prevList) => [
//                               ...prevList,
//                               companyName.trim(),
//                             ]);
//                             setCompanyName("");
//                           }
//                         }}
//                         placeholder="Enter the Company Name"
//                       />
//                     </div>

//                     <div className="flex flex-wrap gap-2 mt-3">
//                       {listCompanyNames?.map((item, i) => (
//                         <label
//                           className="flex items-center py-[5px] px-[8px] border bg-[#00AC85] rounded-[4px]"
//                           key={i}
//                         >
//                           <span className="text-[#fff] text-[14px] font-normal not-italic">
//                             {item}
//                           </span>
//                           <span
//                             onClick={() =>
//                               setListCompanyNames([
//                                 ...listCompanyNames.filter((i) => i !== item),
//                               ])
//                             }
//                           >
//                             <svg
//                               className="ml-[4px] cursor-pointer"
//                               xmlns="http://www.w3.org/2000/svg"
//                               width="16"
//                               height="16"
//                               viewBox="0 0 16 16"
//                               fill="none"
//                             >
//                               <path
//                                 d="M4.66797 4.66669L11.3346 11.3334"
//                                 stroke="white"
//                                 strokeLinecap="round"
//                                 strokeLinejoin="round"
//                               />
//                               <path
//                                 d="M4.66927 11.3334L11.3359 4.66669"
//                                 stroke="white"
//                                 strokeLinecap="round"
//                                 strokeLinejoin="round"
//                               />
//                             </svg>
//                           </span>
//                         </label>
//                       ))}
//                     </div>
//                   </div>
//                 </div>
//               </div>

//             </div>
//           </div>
//         </div>

//         {/* </main> */}

//         {/* </div> */}
//       </main >
//     </>
//   );
// };

// return (

//   <div className="page candidate-pages" id="dashboard">
//     {/* <Header showOnlyLogo={false} menu={<Menu />} /> */}
//     <HeaderNew/>
//     {/* <CandidateSidebar /> */}
//     <main className="main-content no-padding col-xs-12">
//       {Array.isArray(jobs) && jobs.length >= 0 && (
//         <div className="col-xs-12 list-length no-padding">
//           {jobs?.length} Results found
//         </div>
//       )}
//       {/* Left Section => Sort and Filter */}
//       <Filter
//         sort_option={sort}
//         filter_options={filters}
//         filter_function={getCandidateDashboard}
//       />
//       {/* Right Section => List of Jobs */}
//       <div id="job-list" className="col-xs-8 main-section no-padding">

//         {Array.isArray(jobs) && jobs.length > 0
//           ? jobs.map((job, i) => (
//               <JobPost
//                 key={i}
//                 job={job}
//                 reload_function={getCandidateDashboard}
//               />
//             ))
//           : (
//             <div className="col-xs-12 no-job-text">No job is matching</div>
//           )}
//       </div>
//     </main>
//   </div>
// );
