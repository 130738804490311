import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Autocomplete from "../Autocomplete";
const core = require("../../../lib/core");

export default function SearchBar(props) {
  const [locationList, setLocationList] = useState([]);
  const [positionList, setPositionList] = useState([]);
  const [location, setLocation] = useState("");
  const [position, setPosition] = useState("");
  const nav = useNavigate();
  useEffect(() => {
    getLists();
  }, []);

  async function getLists() {
    let resp_loc = await core.API(
      core.API_METHODS.GET,
      core.JOB_API.GET_JOB_LOCATIONS,
      1,
      {},
    );
    setLocationList(resp_loc?.data?.locations);
    let resp_pos = await core.API(
      core.API_METHODS.GET,
      core.JOB_API.GET_JOB_TITLES,
      1,
      {},
    );
    console.log("Locations are : ", resp_loc);
    console.log("Positions are : ", resp_pos);

    setPositionList(resp_pos?.data?.positions?.map((d) => d.name));
  }
  function search(e) {
    e.preventDefault();
    console.log(location);
    console.log(position);
    let searchStr = "?";
    if (location != "") {
      searchStr += "location=" + location + "&";
    }
    if (position != "") {
      searchStr += "position=" + position;
    } else {
      searchStr = searchStr.split("&")[0];
    }
    nav("/" + searchStr);
    console.log(searchStr);
  }
  return (
    <form className="col-xs-12 no-padding flex bg-[#F3F5F9] rounded-lg items-center px-[14px]">
      <span className="w-[18px]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <g id="Group 427319278">
            <path
              id="Star 8"
              d="M5.54474 2.67969C6.04291 5.38384 8.15878 7.49971 10.8629 7.99787C8.15878 8.49603 6.04291 10.6119 5.54474 13.3161C5.04658 10.6119 2.93071 8.49603 0.226562 7.99787C2.93071 7.49971 5.04658 5.38384 5.54474 2.67969Z"
              fill="#5F6989"
            />
            <path
              id="Star 9"
              d="M12.0904 0.632812C12.282 1.67287 13.0958 2.48667 14.1358 2.67827C13.0958 2.86987 12.282 3.68366 12.0904 4.72372C11.8988 3.68366 11.085 2.86987 10.0449 2.67827C11.085 2.48667 11.8988 1.67287 12.0904 0.632812Z"
              fill="#5F6989"
            />
            <path
              id="Star 10"
              d="M12.9086 9.63281C13.1768 11.0889 14.3161 12.2282 15.7722 12.4964C14.3161 12.7647 13.1768 13.904 12.9086 15.3601C12.6403 13.904 11.501 12.7647 10.0449 12.4964C11.501 12.2282 12.6403 11.0889 12.9086 9.63281Z"
              fill="#5F6989"
            />
          </g>
        </svg>
      </span>

      <Autocomplete
        type="text"
        className={
          "h-[48px] z-100 w-[100%] placeholder-[#5F6989] placeholder-[14px] bg-[#F3F5F9]  rounded-lg px-[12px] flex items-center focus-within:bg-[#FFF] focus-within:border-b   focus-within:border-b-[#E1E1EE]  focus-within:shadow-searchboxShadow auto-complete-input "
        }
        defaultValue="Search location"
        idName="search_location"
        data={locationList}
        APIattr="locations"
        searchOff={false}
        searchAPI={core.JOB_API.GET_JOB_LOCATIONS}
        selectionFunction={setLocation}
      />
      <span className="self-center">
        <svg
          className="z-1001"
          xmlns="http://www.w3.org/2000/svg"
          width="1"
          height="28"
          viewBox="0 0 1 28"
          fill="none"
        >
          <rect width="1" height="28" fill="#DADAE4" />
        </svg>
      </span>
      <span className="ml-[14px]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="18"
          viewBox="0 0 19 18"
          fill="none"
        >
          <path
            d="M11.3848 8.25C11.3848 9.49264 10.3774 10.5 9.13477 10.5C7.89212 10.5 6.88477 9.49264 6.88477 8.25C6.88477 7.00736 7.89212 6 9.13477 6C10.3774 6 11.3848 7.00736 11.3848 8.25Z"
            stroke="#5F6989"
            strokeWidth="1.2"
          />
          <path
            d="M15.8848 8.16667C15.8848 11.8486 11.666 16.5 9.13477 16.5C6.60352 16.5 2.38477 11.8486 2.38477 8.16667C2.38477 4.48477 5.40684 1.5 9.13477 1.5C12.8627 1.5 15.8848 4.48477 15.8848 8.16667Z"
            stroke="#5F6989"
            strokeWidth="1.2"
          />
        </svg>
      </span>
      <Autocomplete
        type="text"
        className={
          "h-[48px] z-100 w-[100%]  bg-[#F3F5F9]  placeholder-[#5F6989] placeholder-[14px]  px-[12px] flex items-center focus-within:bg-[#FFF] focus-within:border-b  focus-within:border-b-[#E1E1EE]  focus-within:shadow-searchboxShadow auto-complete-input "
        }
        defaultValue="Search Position"
        idName="search_position"
        data={positionList}
        APIattr="positions"
        searchOff={true}
        searchAPI={core.JOB_API.GET_JOB_TITLES}
        selectionFunction={setPosition}
      />

      {/* <button className="col-xs-2 w-[60px] searchButton flex candidate-search bg-[#1369E9] rounded-r-lg" type="submit" onClick={search}>
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
          <path d="M18.375 17.5L14.4497 13.755M16.625 8.74996C16.625 10.6286 15.8414 12.4303 14.4466 13.7586C13.0518 15.087 11.16 15.8333 9.1875 15.8333C7.21495 15.8333 5.3232 15.087 3.92839 13.7586C2.53359 12.4303 1.75 10.6286 1.75 8.74996C1.75 6.87134 2.53359 5.06967 3.92839 3.74129C5.3232 2.4129 7.21495 1.66663 9.1875 1.66663C11.16 1.66663 13.0518 2.4129 14.4466 3.74129C15.8414 5.06967 16.625 6.87134 16.625 8.74996V8.74996Z" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" />
        </svg>
      </button> */}
    </form>
  );
}
